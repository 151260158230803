// source: gateway/notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as image_image_pb from '../image/image_pb';
export namespace UpdateDeviceRequest {
  export interface AsObject {
    deviceId: string;
    firebaseRegistrationToken: string;
  }
}
export class UpdateDeviceRequest extends jspb.Message {
  static readonly displayName = "UpdateDeviceRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateDeviceRequest.AsObject {
    return UpdateDeviceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateDeviceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateDeviceRequest) {
    var f: any;
    var obj: any = {
      deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      firebaseRegistrationToken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateDeviceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateDeviceRequest;
    return UpdateDeviceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateDeviceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateDeviceRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateDeviceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFirebaseRegistrationToken(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateDeviceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateDeviceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDeviceId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFirebaseRegistrationToken();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string device_id = 1;
   * @return {string}
   */
  getDeviceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setDeviceId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string firebase_registration_token = 2;
   * @return {string}
   */
  getFirebaseRegistrationToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFirebaseRegistrationToken(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace UpdateDeviceResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class UpdateDeviceResponse extends jspb.Message {
  static readonly displayName = "UpdateDeviceResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateDeviceResponse.AsObject {
    return UpdateDeviceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateDeviceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateDeviceResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateDeviceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateDeviceResponse;
    return UpdateDeviceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateDeviceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateDeviceResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateDeviceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateDeviceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateDeviceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateDeviceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace NotificationTokenRequest {
  export interface AsObject {
    firebaseToken: string;
    userId: number;
  }
}
export class NotificationTokenRequest extends jspb.Message {
  static readonly displayName = "NotificationTokenRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NotificationTokenRequest.AsObject {
    return NotificationTokenRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NotificationTokenRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NotificationTokenRequest) {
    var f: any;
    var obj: any = {
      firebaseToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
      userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NotificationTokenRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NotificationTokenRequest;
    return NotificationTokenRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NotificationTokenRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NotificationTokenRequest}
   */
  static deserializeBinaryFromReader(msg: NotificationTokenRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setFirebaseToken(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setUserId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NotificationTokenRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NotificationTokenRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFirebaseToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getUserId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NotificationTokenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string firebase_token = 1;
   * @return {string}
   */
  getFirebaseToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setFirebaseToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 user_id = 2;
   * @return {number}
   */
  getUserId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setUserId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace NotificationTokenResponse {
  export interface AsObject {
  }
}
export class NotificationTokenResponse extends jspb.Message {
  static readonly displayName = "NotificationTokenResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NotificationTokenResponse.AsObject {
    return NotificationTokenResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NotificationTokenResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NotificationTokenResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NotificationTokenResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NotificationTokenResponse;
    return NotificationTokenResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NotificationTokenResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NotificationTokenResponse}
   */
  static deserializeBinaryFromReader(msg: NotificationTokenResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NotificationTokenResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NotificationTokenResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NotificationTokenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace BroadcastNotificationRequest {
  export interface AsObject {
    notificationTitle: string;
    notificationBody: string;
    allMingasBroadcast: boolean;
    sendEmail: boolean;
  }
}
export class BroadcastNotificationRequest extends jspb.Message {
  static readonly displayName = "BroadcastNotificationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BroadcastNotificationRequest.AsObject {
    return BroadcastNotificationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BroadcastNotificationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BroadcastNotificationRequest) {
    var f: any;
    var obj: any = {
      notificationTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
      notificationBody: jspb.Message.getFieldWithDefault(msg, 2, ""),
      allMingasBroadcast: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      sendEmail: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BroadcastNotificationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BroadcastNotificationRequest;
    return BroadcastNotificationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BroadcastNotificationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BroadcastNotificationRequest}
   */
  static deserializeBinaryFromReader(msg: BroadcastNotificationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setNotificationTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setNotificationBody(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setAllMingasBroadcast(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setSendEmail(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BroadcastNotificationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BroadcastNotificationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNotificationTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getNotificationBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAllMingasBroadcast();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getSendEmail();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BroadcastNotificationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string notification_title = 1;
   * @return {string}
   */
  getNotificationTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setNotificationTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string notification_body = 2;
   * @return {string}
   */
  getNotificationBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setNotificationBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional bool all_mingas_broadcast = 3;
   * @return {boolean}
   */
  getAllMingasBroadcast(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setAllMingasBroadcast(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional bool send_email = 4;
   * @return {boolean}
   */
  getSendEmail(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setSendEmail(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace BroadcastNotificationResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class BroadcastNotificationResponse extends jspb.Message {
  static readonly displayName = "BroadcastNotificationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BroadcastNotificationResponse.AsObject {
    return BroadcastNotificationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BroadcastNotificationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BroadcastNotificationResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BroadcastNotificationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BroadcastNotificationResponse;
    return BroadcastNotificationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BroadcastNotificationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BroadcastNotificationResponse}
   */
  static deserializeBinaryFromReader(msg: BroadcastNotificationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BroadcastNotificationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BroadcastNotificationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BroadcastNotificationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace TestPushNotificationRequest {
  export interface AsObject {
    firebaseToken: string;
    userId: number;
    notificationTitle: string;
    notificationBody: string;
    mingaHash: string;
    allMingasBroadcast: boolean;
  }
}
export class TestPushNotificationRequest extends jspb.Message {
  static readonly displayName = "TestPushNotificationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TestPushNotificationRequest.AsObject {
    return TestPushNotificationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TestPushNotificationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TestPushNotificationRequest) {
    var f: any;
    var obj: any = {
      firebaseToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
      userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      notificationTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
      notificationBody: jspb.Message.getFieldWithDefault(msg, 4, ""),
      mingaHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      allMingasBroadcast: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TestPushNotificationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TestPushNotificationRequest;
    return TestPushNotificationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TestPushNotificationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TestPushNotificationRequest}
   */
  static deserializeBinaryFromReader(msg: TestPushNotificationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setFirebaseToken(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setUserId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setNotificationTitle(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setNotificationBody(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setAllMingasBroadcast(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TestPushNotificationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TestPushNotificationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFirebaseToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getUserId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getNotificationTitle();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getNotificationBody();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getAllMingasBroadcast();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TestPushNotificationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string firebase_token = 1;
   * @return {string}
   */
  getFirebaseToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setFirebaseToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 user_id = 2;
   * @return {number}
   */
  getUserId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setUserId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string notification_title = 3;
   * @return {string}
   */
  getNotificationTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setNotificationTitle(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string notification_body = 4;
   * @return {string}
   */
  getNotificationBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setNotificationBody(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string minga_hash = 5;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional bool all_mingas_broadcast = 6;
   * @return {boolean}
   */
  getAllMingasBroadcast(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setAllMingasBroadcast(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


}
export namespace TestPushNotificationResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class TestPushNotificationResponse extends jspb.Message {
  static readonly displayName = "TestPushNotificationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TestPushNotificationResponse.AsObject {
    return TestPushNotificationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TestPushNotificationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TestPushNotificationResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TestPushNotificationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TestPushNotificationResponse;
    return TestPushNotificationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TestPushNotificationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TestPushNotificationResponse}
   */
  static deserializeBinaryFromReader(msg: TestPushNotificationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TestPushNotificationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TestPushNotificationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TestPushNotificationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace Notification {
  export interface AsObject {
    notificationId: string;
    title: string;
    body: string;
    subtitle: string;
    timestamp: number;
    image?: image_image_pb.ImageInfo.AsObject;
    iconUrl: string;
    iconColor: string;
    read: boolean;
    viewed: boolean;
    displayBasic?: Notification.DisplayBasic.AsObject;
    displayEvent?: Notification.DisplayEvent.AsObject;
    displayPerson?: Notification.DisplayPerson.AsObject;
    displayPersonTitle?: Notification.DisplayPersonTitle.AsObject;
    actionOpenContent?: Notification.ActionOpenContent.AsObject;
    actionAlertContent?: Notification.ActionAlertContent.AsObject;
    actionReportedContent?: Notification.ActionReportedContent.AsObject;
    actionGroupContent?: Notification.ActionGroupContent.AsObject;
    actionGalleryPhoto?: Notification.ActionGalleryPhoto.AsObject;
    actionOpenChallengeResponse?: Notification.ActionOpenChallengeResponse.AsObject;
    actionOpenStudentId?: Notification.ActionOpenStudentId.AsObject;
    actionOpenBehavior?: Notification.ActionOpenBehavior.AsObject;
    notificationType: string;
    metadata: string;
  }
}
export class Notification extends jspb.Message {
  static readonly displayName = "Notification";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[11,12,13,17],[14,15,16,18,19,20,21,23]];


  /**
   * @return {Notification.DisplayCase}
   */
  getDisplayCase() {
    return /** @type {Notification.DisplayCase} */(jspb.Message.computeOneofCase(this, Notification.oneofGroups_[0]));
  }


  /**
   * @return {Notification.ActionCase}
   */
  getActionCase() {
    return /** @type {Notification.ActionCase} */(jspb.Message.computeOneofCase(this, Notification.oneofGroups_[1]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, Notification.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Notification.AsObject {
    return Notification.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Notification} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Notification) {
    var f: any;
    var obj: any = {
      notificationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      iconUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
      iconColor: jspb.Message.getFieldWithDefault(msg, 8, ""),
      read: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      viewed: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      displayBasic: (f = msg.getDisplayBasic()) && Notification.DisplayBasic.toObject(includeInstance, f),
      displayEvent: (f = msg.getDisplayEvent()) && Notification.DisplayEvent.toObject(includeInstance, f),
      displayPerson: (f = msg.getDisplayPerson()) && Notification.DisplayPerson.toObject(includeInstance, f),
      displayPersonTitle: (f = msg.getDisplayPersonTitle()) && Notification.DisplayPersonTitle.toObject(includeInstance, f),
      actionOpenContent: (f = msg.getActionOpenContent()) && Notification.ActionOpenContent.toObject(includeInstance, f),
      actionAlertContent: (f = msg.getActionAlertContent()) && Notification.ActionAlertContent.toObject(includeInstance, f),
      actionReportedContent: (f = msg.getActionReportedContent()) && Notification.ActionReportedContent.toObject(includeInstance, f),
      actionGroupContent: (f = msg.getActionGroupContent()) && Notification.ActionGroupContent.toObject(includeInstance, f),
      actionGalleryPhoto: (f = msg.getActionGalleryPhoto()) && Notification.ActionGalleryPhoto.toObject(includeInstance, f),
      actionOpenChallengeResponse: (f = msg.getActionOpenChallengeResponse()) && Notification.ActionOpenChallengeResponse.toObject(includeInstance, f),
      actionOpenStudentId: (f = msg.getActionOpenStudentId()) && Notification.ActionOpenStudentId.toObject(includeInstance, f),
      actionOpenBehavior: (f = msg.getActionOpenBehavior()) && Notification.ActionOpenBehavior.toObject(includeInstance, f),
      notificationType: jspb.Message.getFieldWithDefault(msg, 22, ""),
      metadata: jspb.Message.getFieldWithDefault(msg, 24, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Notification}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Notification;
    return Notification.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification}
   */
  static deserializeBinaryFromReader(msg: Notification, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setNotificationId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setSubtitle(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value5);
        break;
      case 6:
        var value6 = new image_image_pb.ImageInfo;
        reader.readMessage(value6,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setIconUrl(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setIconColor(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setRead(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setViewed(value10);
        break;
      case 11:
        var value11 = new Notification.DisplayBasic;
        reader.readMessage(value11,Notification.DisplayBasic.deserializeBinaryFromReader);
        msg.setDisplayBasic(value11);
        break;
      case 12:
        var value12 = new Notification.DisplayEvent;
        reader.readMessage(value12,Notification.DisplayEvent.deserializeBinaryFromReader);
        msg.setDisplayEvent(value12);
        break;
      case 13:
        var value13 = new Notification.DisplayPerson;
        reader.readMessage(value13,Notification.DisplayPerson.deserializeBinaryFromReader);
        msg.setDisplayPerson(value13);
        break;
      case 17:
        var value17 = new Notification.DisplayPersonTitle;
        reader.readMessage(value17,Notification.DisplayPersonTitle.deserializeBinaryFromReader);
        msg.setDisplayPersonTitle(value17);
        break;
      case 14:
        var value14 = new Notification.ActionOpenContent;
        reader.readMessage(value14,Notification.ActionOpenContent.deserializeBinaryFromReader);
        msg.setActionOpenContent(value14);
        break;
      case 15:
        var value15 = new Notification.ActionAlertContent;
        reader.readMessage(value15,Notification.ActionAlertContent.deserializeBinaryFromReader);
        msg.setActionAlertContent(value15);
        break;
      case 16:
        var value16 = new Notification.ActionReportedContent;
        reader.readMessage(value16,Notification.ActionReportedContent.deserializeBinaryFromReader);
        msg.setActionReportedContent(value16);
        break;
      case 18:
        var value18 = new Notification.ActionGroupContent;
        reader.readMessage(value18,Notification.ActionGroupContent.deserializeBinaryFromReader);
        msg.setActionGroupContent(value18);
        break;
      case 19:
        var value19 = new Notification.ActionGalleryPhoto;
        reader.readMessage(value19,Notification.ActionGalleryPhoto.deserializeBinaryFromReader);
        msg.setActionGalleryPhoto(value19);
        break;
      case 20:
        var value20 = new Notification.ActionOpenChallengeResponse;
        reader.readMessage(value20,Notification.ActionOpenChallengeResponse.deserializeBinaryFromReader);
        msg.setActionOpenChallengeResponse(value20);
        break;
      case 21:
        var value21 = new Notification.ActionOpenStudentId;
        reader.readMessage(value21,Notification.ActionOpenStudentId.deserializeBinaryFromReader);
        msg.setActionOpenStudentId(value21);
        break;
      case 23:
        var value23 = new Notification.ActionOpenBehavior;
        reader.readMessage(value23,Notification.ActionOpenBehavior.deserializeBinaryFromReader);
        msg.setActionOpenBehavior(value23);
        break;
      case 22:
        var value22 = /** @type {string} */ (reader.readString());
        msg.setNotificationType(value22);
        break;
      case 24:
        var value24 = /** @type {string} */ (reader.readString());
        msg.setMetadata(value24);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNotificationId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getSubtitle();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        5,
        f
      );
    }
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getIconUrl();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getIconColor();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getRead();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getViewed();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getDisplayBasic();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        Notification.DisplayBasic.serializeBinaryToWriter
      );
    }
    f = message.getDisplayEvent();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        Notification.DisplayEvent.serializeBinaryToWriter
      );
    }
    f = message.getDisplayPerson();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        Notification.DisplayPerson.serializeBinaryToWriter
      );
    }
    f = message.getDisplayPersonTitle();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        Notification.DisplayPersonTitle.serializeBinaryToWriter
      );
    }
    f = message.getActionOpenContent();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        Notification.ActionOpenContent.serializeBinaryToWriter
      );
    }
    f = message.getActionAlertContent();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        Notification.ActionAlertContent.serializeBinaryToWriter
      );
    }
    f = message.getActionReportedContent();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        Notification.ActionReportedContent.serializeBinaryToWriter
      );
    }
    f = message.getActionGroupContent();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        Notification.ActionGroupContent.serializeBinaryToWriter
      );
    }
    f = message.getActionGalleryPhoto();
    if (f != null) {
      writer.writeMessage(
        19,
        f,
        Notification.ActionGalleryPhoto.serializeBinaryToWriter
      );
    }
    f = message.getActionOpenChallengeResponse();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        Notification.ActionOpenChallengeResponse.serializeBinaryToWriter
      );
    }
    f = message.getActionOpenStudentId();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        Notification.ActionOpenStudentId.serializeBinaryToWriter
      );
    }
    f = message.getActionOpenBehavior();
    if (f != null) {
      writer.writeMessage(
        23,
        f,
        Notification.ActionOpenBehavior.serializeBinaryToWriter
      );
    }
    f = message.getNotificationType();
    if (f.length > 0) {
      writer.writeString(
        22,
        f
      );
    }
    f = message.getMetadata();
    if (f.length > 0) {
      writer.writeString(
        24,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string notification_id = 1;
   * @return {string}
   */
  getNotificationId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setNotificationId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string subtitle = 4;
   * @return {string}
   */
  getSubtitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setSubtitle(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional uint64 timestamp = 5;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional ImageInfo image = 6;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 6));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional string icon_url = 7;
   * @return {string}
   */
  getIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string icon_color = 8;
   * @return {string}
   */
  getIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional bool read = 9;
   * @return {boolean}
   */
  getRead(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setRead(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional bool viewed = 10;
   * @return {boolean}
   */
  getViewed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setViewed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional DisplayBasic display_basic = 11;
   * @return {?Notification.DisplayBasic}
   */
  getDisplayBasic(): Notification.DisplayBasic {
    return /** @type{?Notification.DisplayBasic} */ (
      jspb.Message.getWrapperField(this, Notification.DisplayBasic, 11));
  }


  /** @param {?Notification.DisplayBasic|undefined} value */
  setDisplayBasic(value?: Notification.DisplayBasic) {
    jspb.Message.setOneofWrapperField(this, 11, Notification.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDisplayBasic() {
    this.setDisplayBasic(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDisplayBasic(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional DisplayEvent display_event = 12;
   * @return {?Notification.DisplayEvent}
   */
  getDisplayEvent(): Notification.DisplayEvent {
    return /** @type{?Notification.DisplayEvent} */ (
      jspb.Message.getWrapperField(this, Notification.DisplayEvent, 12));
  }


  /** @param {?Notification.DisplayEvent|undefined} value */
  setDisplayEvent(value?: Notification.DisplayEvent) {
    jspb.Message.setOneofWrapperField(this, 12, Notification.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDisplayEvent() {
    this.setDisplayEvent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDisplayEvent(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional DisplayPerson display_person = 13;
   * @return {?Notification.DisplayPerson}
   */
  getDisplayPerson(): Notification.DisplayPerson {
    return /** @type{?Notification.DisplayPerson} */ (
      jspb.Message.getWrapperField(this, Notification.DisplayPerson, 13));
  }


  /** @param {?Notification.DisplayPerson|undefined} value */
  setDisplayPerson(value?: Notification.DisplayPerson) {
    jspb.Message.setOneofWrapperField(this, 13, Notification.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDisplayPerson() {
    this.setDisplayPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDisplayPerson(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * optional DisplayPersonTitle display_person_title = 17;
   * @return {?Notification.DisplayPersonTitle}
   */
  getDisplayPersonTitle(): Notification.DisplayPersonTitle {
    return /** @type{?Notification.DisplayPersonTitle} */ (
      jspb.Message.getWrapperField(this, Notification.DisplayPersonTitle, 17));
  }


  /** @param {?Notification.DisplayPersonTitle|undefined} value */
  setDisplayPersonTitle(value?: Notification.DisplayPersonTitle) {
    jspb.Message.setOneofWrapperField(this, 17, Notification.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDisplayPersonTitle() {
    this.setDisplayPersonTitle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDisplayPersonTitle(): boolean {
    return jspb.Message.getField(this, 17) != null;
  }


  /**
   * optional ActionOpenContent action_open_content = 14;
   * @return {?Notification.ActionOpenContent}
   */
  getActionOpenContent(): Notification.ActionOpenContent {
    return /** @type{?Notification.ActionOpenContent} */ (
      jspb.Message.getWrapperField(this, Notification.ActionOpenContent, 14));
  }


  /** @param {?Notification.ActionOpenContent|undefined} value */
  setActionOpenContent(value?: Notification.ActionOpenContent) {
    jspb.Message.setOneofWrapperField(this, 14, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionOpenContent() {
    this.setActionOpenContent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionOpenContent(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional ActionAlertContent action_alert_content = 15;
   * @return {?Notification.ActionAlertContent}
   */
  getActionAlertContent(): Notification.ActionAlertContent {
    return /** @type{?Notification.ActionAlertContent} */ (
      jspb.Message.getWrapperField(this, Notification.ActionAlertContent, 15));
  }


  /** @param {?Notification.ActionAlertContent|undefined} value */
  setActionAlertContent(value?: Notification.ActionAlertContent) {
    jspb.Message.setOneofWrapperField(this, 15, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionAlertContent() {
    this.setActionAlertContent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionAlertContent(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional ActionReportedContent action_reported_content = 16;
   * @return {?Notification.ActionReportedContent}
   */
  getActionReportedContent(): Notification.ActionReportedContent {
    return /** @type{?Notification.ActionReportedContent} */ (
      jspb.Message.getWrapperField(this, Notification.ActionReportedContent, 16));
  }


  /** @param {?Notification.ActionReportedContent|undefined} value */
  setActionReportedContent(value?: Notification.ActionReportedContent) {
    jspb.Message.setOneofWrapperField(this, 16, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionReportedContent() {
    this.setActionReportedContent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionReportedContent(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional ActionGroupContent action_group_content = 18;
   * @return {?Notification.ActionGroupContent}
   */
  getActionGroupContent(): Notification.ActionGroupContent {
    return /** @type{?Notification.ActionGroupContent} */ (
      jspb.Message.getWrapperField(this, Notification.ActionGroupContent, 18));
  }


  /** @param {?Notification.ActionGroupContent|undefined} value */
  setActionGroupContent(value?: Notification.ActionGroupContent) {
    jspb.Message.setOneofWrapperField(this, 18, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionGroupContent() {
    this.setActionGroupContent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionGroupContent(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional ActionGalleryPhoto action_gallery_photo = 19;
   * @return {?Notification.ActionGalleryPhoto}
   */
  getActionGalleryPhoto(): Notification.ActionGalleryPhoto {
    return /** @type{?Notification.ActionGalleryPhoto} */ (
      jspb.Message.getWrapperField(this, Notification.ActionGalleryPhoto, 19));
  }


  /** @param {?Notification.ActionGalleryPhoto|undefined} value */
  setActionGalleryPhoto(value?: Notification.ActionGalleryPhoto) {
    jspb.Message.setOneofWrapperField(this, 19, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionGalleryPhoto() {
    this.setActionGalleryPhoto(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionGalleryPhoto(): boolean {
    return jspb.Message.getField(this, 19) != null;
  }


  /**
   * optional ActionOpenChallengeResponse action_open_challenge_response = 20;
   * @return {?Notification.ActionOpenChallengeResponse}
   */
  getActionOpenChallengeResponse(): Notification.ActionOpenChallengeResponse {
    return /** @type{?Notification.ActionOpenChallengeResponse} */ (
      jspb.Message.getWrapperField(this, Notification.ActionOpenChallengeResponse, 20));
  }


  /** @param {?Notification.ActionOpenChallengeResponse|undefined} value */
  setActionOpenChallengeResponse(value?: Notification.ActionOpenChallengeResponse) {
    jspb.Message.setOneofWrapperField(this, 20, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionOpenChallengeResponse() {
    this.setActionOpenChallengeResponse(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionOpenChallengeResponse(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


  /**
   * optional ActionOpenStudentId action_open_student_id = 21;
   * @return {?Notification.ActionOpenStudentId}
   */
  getActionOpenStudentId(): Notification.ActionOpenStudentId {
    return /** @type{?Notification.ActionOpenStudentId} */ (
      jspb.Message.getWrapperField(this, Notification.ActionOpenStudentId, 21));
  }


  /** @param {?Notification.ActionOpenStudentId|undefined} value */
  setActionOpenStudentId(value?: Notification.ActionOpenStudentId) {
    jspb.Message.setOneofWrapperField(this, 21, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionOpenStudentId() {
    this.setActionOpenStudentId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionOpenStudentId(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional ActionOpenBehavior action_open_behavior = 23;
   * @return {?Notification.ActionOpenBehavior}
   */
  getActionOpenBehavior(): Notification.ActionOpenBehavior {
    return /** @type{?Notification.ActionOpenBehavior} */ (
      jspb.Message.getWrapperField(this, Notification.ActionOpenBehavior, 23));
  }


  /** @param {?Notification.ActionOpenBehavior|undefined} value */
  setActionOpenBehavior(value?: Notification.ActionOpenBehavior) {
    jspb.Message.setOneofWrapperField(this, 23, Notification.oneofGroups_[1], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActionOpenBehavior() {
    this.setActionOpenBehavior(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActionOpenBehavior(): boolean {
    return jspb.Message.getField(this, 23) != null;
  }


  /**
   * optional string notification_type = 22;
   * @return {string}
   */
  getNotificationType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
  };


  /** @param {string} value */
  setNotificationType(value: string) {
    jspb.Message.setProto3StringField(this, 22, value);
  }


  /**
   * optional string metadata = 24;
   * @return {string}
   */
  getMetadata(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
  };


  /** @param {string} value */
  setMetadata(value: string) {
    jspb.Message.setProto3StringField(this, 24, value);
  }


}
export namespace Notification {
export enum DisplayCase {
  DISPLAY_NOT_SET = 0,
  DISPLAY_BASIC = 11,
  DISPLAY_EVENT = 12,
  DISPLAY_PERSON = 13,
  DISPLAY_PERSON_TITLE = 17,
}
} // namespace Notification
export namespace Notification {
export enum ActionCase {
  ACTION_NOT_SET = 0,
  ACTION_OPEN_CONTENT = 14,
  ACTION_ALERT_CONTENT = 15,
  ACTION_REPORTED_CONTENT = 16,
  ACTION_GROUP_CONTENT = 18,
  ACTION_GALLERY_PHOTO = 19,
  ACTION_OPEN_CHALLENGE_RESPONSE = 20,
  ACTION_OPEN_STUDENT_ID = 21,
  ACTION_OPEN_BEHAVIOR = 23,
}
} // namespace Notification
export namespace Notification {
export namespace DisplayBasic {
  export interface AsObject {
  }
}
export class DisplayBasic extends jspb.Message {
  static readonly displayName = "Notification.DisplayBasic";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DisplayBasic.AsObject {
    return DisplayBasic.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DisplayBasic} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DisplayBasic) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DisplayBasic}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DisplayBasic;
    return DisplayBasic.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.DisplayBasic} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.DisplayBasic}
   */
  static deserializeBinaryFromReader(msg: DisplayBasic, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.DisplayBasic} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.DisplayBasic, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.DisplayBasic.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace Notification
export namespace Notification {
export namespace DisplayEvent {
  export interface AsObject {
    eventStart: number;
    eventEnd: number;
  }
}
export class DisplayEvent extends jspb.Message {
  static readonly displayName = "Notification.DisplayEvent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DisplayEvent.AsObject {
    return DisplayEvent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DisplayEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DisplayEvent) {
    var f: any;
    var obj: any = {
      eventStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
      eventEnd: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DisplayEvent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DisplayEvent;
    return DisplayEvent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.DisplayEvent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.DisplayEvent}
   */
  static deserializeBinaryFromReader(msg: DisplayEvent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint64());
        msg.setEventStart(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint64());
        msg.setEventEnd(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.DisplayEvent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.DisplayEvent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEventStart();
    if (f !== 0) {
      writer.writeUint64(
        1,
        f
      );
    }
    f = message.getEventEnd();
    if (f !== 0) {
      writer.writeUint64(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.DisplayEvent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint64 event_start = 1;
   * @return {number}
   */
  getEventStart(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setEventStart(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional uint64 event_end = 2;
   * @return {number}
   */
  getEventEnd(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setEventEnd(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace DisplayPersonTitle {
  export interface AsObject {
    displayName: string;
    titlePrefix: string;
  }
}
export class DisplayPersonTitle extends jspb.Message {
  static readonly displayName = "Notification.DisplayPersonTitle";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DisplayPersonTitle.AsObject {
    return DisplayPersonTitle.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DisplayPersonTitle} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DisplayPersonTitle) {
    var f: any;
    var obj: any = {
      displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      titlePrefix: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DisplayPersonTitle}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DisplayPersonTitle;
    return DisplayPersonTitle.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.DisplayPersonTitle} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.DisplayPersonTitle}
   */
  static deserializeBinaryFromReader(msg: DisplayPersonTitle, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitlePrefix(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.DisplayPersonTitle} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.DisplayPersonTitle, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDisplayName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitlePrefix();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.DisplayPersonTitle.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string display_name = 1;
   * @return {string}
   */
  getDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string title_prefix = 2;
   * @return {string}
   */
  getTitlePrefix(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitlePrefix(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace DisplayPerson {
  export interface AsObject {
    person?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    otherPerson?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    othersList: string[];
    verb: string;
  }
}
export class DisplayPerson extends jspb.Message {
  static readonly displayName = "Notification.DisplayPerson";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Notification.DisplayPerson.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DisplayPerson.AsObject {
    return DisplayPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DisplayPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DisplayPerson) {
    var f: any;
    var obj: any = {
      person: (f = msg.getPerson()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      otherPerson: (f = msg.getOtherPerson()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      othersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      verb: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DisplayPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DisplayPerson;
    return DisplayPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.DisplayPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.DisplayPerson}
   */
  static deserializeBinaryFromReader(msg: DisplayPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value1,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setPerson(value1);
        break;
      case 2:
        var value2 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value2,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setOtherPerson(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addOthers(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setVerb(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.DisplayPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.DisplayPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPerson();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOtherPerson();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOthersList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
    f = message.getVerb();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.DisplayPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PersonViewMinimal person = 1;
   * @return {?PersonViewMinimal}
   */
  getPerson(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 1));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setPerson(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPerson() {
    this.setPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPerson(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PersonViewMinimal other_person = 2;
   * @return {?PersonViewMinimal}
   */
  getOtherPerson(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 2));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setOtherPerson(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearOtherPerson() {
    this.setOtherPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasOtherPerson(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * repeated string others = 3;
   * @return {!Array<string>}
   */
  getOthersList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setOthersList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addOthers(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearOthersList() {
    this.setOthersList([]);
  }


  /**
   * optional string verb = 4;
   * @return {string}
   */
  getVerb(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setVerb(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace ActionOpenContent {
  export interface AsObject {
    contentContextHash: string;
  }
}
export class ActionOpenContent extends jspb.Message {
  static readonly displayName = "Notification.ActionOpenContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionOpenContent.AsObject {
    return ActionOpenContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionOpenContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionOpenContent) {
    var f: any;
    var obj: any = {
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionOpenContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionOpenContent;
    return ActionOpenContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionOpenContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionOpenContent}
   */
  static deserializeBinaryFromReader(msg: ActionOpenContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionOpenContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionOpenContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionOpenContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_context_hash = 1;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace ActionAlertContent {
  export interface AsObject {
    title: string;
    body: string;
  }
}
export class ActionAlertContent extends jspb.Message {
  static readonly displayName = "Notification.ActionAlertContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionAlertContent.AsObject {
    return ActionAlertContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionAlertContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionAlertContent) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionAlertContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionAlertContent;
    return ActionAlertContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionAlertContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionAlertContent}
   */
  static deserializeBinaryFromReader(msg: ActionAlertContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionAlertContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionAlertContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionAlertContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace ActionReportedContent {
  export interface AsObject {
    contentContextHash: string;
    galleryPhotoUuid: string;
  }
}
export class ActionReportedContent extends jspb.Message {
  static readonly displayName = "Notification.ActionReportedContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionReportedContent.AsObject {
    return ActionReportedContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionReportedContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionReportedContent) {
    var f: any;
    var obj: any = {
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      galleryPhotoUuid: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionReportedContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionReportedContent;
    return ActionReportedContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionReportedContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionReportedContent}
   */
  static deserializeBinaryFromReader(msg: ActionReportedContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setGalleryPhotoUuid(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionReportedContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionReportedContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getGalleryPhotoUuid();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionReportedContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_context_hash = 1;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string gallery_photo_uuid = 2;
   * @return {string}
   */
  getGalleryPhotoUuid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setGalleryPhotoUuid(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace ActionGroupContent {
  export interface AsObject {
    groupHash: string;
    action?: Notification.ActionGroupContent.GroupAction;
  }
}
export class ActionGroupContent extends jspb.Message {
  static readonly displayName = "Notification.ActionGroupContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionGroupContent.AsObject {
    return ActionGroupContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionGroupContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionGroupContent) {
    var f: any;
    var obj: any = {
      groupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      action: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionGroupContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionGroupContent;
    return ActionGroupContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionGroupContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionGroupContent}
   */
  static deserializeBinaryFromReader(msg: ActionGroupContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setGroupHash(value1);
        break;
      case 2:
        var value2 = /** @type {!Notification.ActionGroupContent.GroupAction} */ (reader.readEnum());
        msg.setAction(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionGroupContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionGroupContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAction();
    if (f !== 0.0) {
      writer.writeEnum(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionGroupContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string group_hash = 1;
   * @return {string}
   */
  getGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional GroupAction action = 2;
   * @return {!Notification.ActionGroupContent.GroupAction}
   */
  getAction(): Notification.ActionGroupContent.GroupAction {
    return /** @type {!Notification.ActionGroupContent.GroupAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {!Notification.ActionGroupContent.GroupAction} value */
  setAction(value: Notification.ActionGroupContent.GroupAction) {
    jspb.Message.setProto3EnumField(this, 2, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace ActionGroupContent {
export enum GroupAction {
  GROUP_CONTENT = 0,
  GROUP_MEMBERLIST = 1,
}
} // namespace Notification
} // namespace ActionGroupContent
export namespace Notification {
export namespace ActionGalleryPhoto {
  export interface AsObject {
    galleryPhotoHash: string;
  }
}
export class ActionGalleryPhoto extends jspb.Message {
  static readonly displayName = "Notification.ActionGalleryPhoto";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionGalleryPhoto.AsObject {
    return ActionGalleryPhoto.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionGalleryPhoto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionGalleryPhoto) {
    var f: any;
    var obj: any = {
      galleryPhotoHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionGalleryPhoto}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionGalleryPhoto;
    return ActionGalleryPhoto.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionGalleryPhoto} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionGalleryPhoto}
   */
  static deserializeBinaryFromReader(msg: ActionGalleryPhoto, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setGalleryPhotoHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionGalleryPhoto} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionGalleryPhoto, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGalleryPhotoHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionGalleryPhoto.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string gallery_photo_hash = 1;
   * @return {string}
   */
  getGalleryPhotoHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setGalleryPhotoHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace ActionOpenChallengeResponse {
  export interface AsObject {
    challengeContextHash: string;
    responseContextHash: string;
  }
}
export class ActionOpenChallengeResponse extends jspb.Message {
  static readonly displayName = "Notification.ActionOpenChallengeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionOpenChallengeResponse.AsObject {
    return ActionOpenChallengeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionOpenChallengeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionOpenChallengeResponse) {
    var f: any;
    var obj: any = {
      challengeContextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      responseContextHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionOpenChallengeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionOpenChallengeResponse;
    return ActionOpenChallengeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionOpenChallengeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionOpenChallengeResponse}
   */
  static deserializeBinaryFromReader(msg: ActionOpenChallengeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setChallengeContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setResponseContextHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionOpenChallengeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionOpenChallengeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getChallengeContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getResponseContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionOpenChallengeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string challenge_context_hash = 1;
   * @return {string}
   */
  getChallengeContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setChallengeContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string response_context_hash = 2;
   * @return {string}
   */
  getResponseContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setResponseContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace Notification
export namespace Notification {
export namespace ActionOpenStudentId {
  export interface AsObject {
  }
}
export class ActionOpenStudentId extends jspb.Message {
  static readonly displayName = "Notification.ActionOpenStudentId";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionOpenStudentId.AsObject {
    return ActionOpenStudentId.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionOpenStudentId} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionOpenStudentId) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionOpenStudentId}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionOpenStudentId;
    return ActionOpenStudentId.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionOpenStudentId} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionOpenStudentId}
   */
  static deserializeBinaryFromReader(msg: ActionOpenStudentId, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionOpenStudentId} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionOpenStudentId, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionOpenStudentId.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace Notification
export namespace Notification {
export namespace ActionOpenBehavior {
  export interface AsObject {
    id: number;
  }
}
export class ActionOpenBehavior extends jspb.Message {
  static readonly displayName = "Notification.ActionOpenBehavior";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActionOpenBehavior.AsObject {
    return ActionOpenBehavior.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActionOpenBehavior} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActionOpenBehavior) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActionOpenBehavior}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActionOpenBehavior;
    return ActionOpenBehavior.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Notification.ActionOpenBehavior} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Notification.ActionOpenBehavior}
   */
  static deserializeBinaryFromReader(msg: ActionOpenBehavior, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Notification.ActionOpenBehavior} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Notification.ActionOpenBehavior, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Notification.ActionOpenBehavior.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
} // namespace Notification
export namespace NotificationListenRequest {
  export interface AsObject {
    listenToken: string;
  }
}
export class NotificationListenRequest extends jspb.Message {
  static readonly displayName = "NotificationListenRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NotificationListenRequest.AsObject {
    return NotificationListenRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NotificationListenRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NotificationListenRequest) {
    var f: any;
    var obj: any = {
      listenToken: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NotificationListenRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NotificationListenRequest;
    return NotificationListenRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NotificationListenRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NotificationListenRequest}
   */
  static deserializeBinaryFromReader(msg: NotificationListenRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setListenToken(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NotificationListenRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NotificationListenRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getListenToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NotificationListenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string listen_token = 1;
   * @return {string}
   */
  getListenToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setListenToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace NotificationListenResponse {
  export interface AsObject {
    listenToken: string;
    notification?: Notification.AsObject;
  }
}
export class NotificationListenResponse extends jspb.Message {
  static readonly displayName = "NotificationListenResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NotificationListenResponse.AsObject {
    return NotificationListenResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NotificationListenResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NotificationListenResponse) {
    var f: any;
    var obj: any = {
      listenToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
      notification: (f = msg.getNotification()) && Notification.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NotificationListenResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NotificationListenResponse;
    return NotificationListenResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NotificationListenResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NotificationListenResponse}
   */
  static deserializeBinaryFromReader(msg: NotificationListenResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setListenToken(value1);
        break;
      case 2:
        var value2 = new Notification;
        reader.readMessage(value2,Notification.deserializeBinaryFromReader);
        msg.setNotification(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NotificationListenResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NotificationListenResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getListenToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getNotification();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        Notification.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NotificationListenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string listen_token = 1;
   * @return {string}
   */
  getListenToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setListenToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional Notification notification = 2;
   * @return {?Notification}
   */
  getNotification(): Notification {
    return /** @type{?Notification} */ (
      jspb.Message.getWrapperField(this, Notification, 2));
  }


  /** @param {?Notification|undefined} value */
  setNotification(value?: Notification) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearNotification() {
    this.setNotification(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasNotification(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace StreamNotificationsResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: Notification.AsObject;
  }
}
export class StreamNotificationsResponse extends jspb.Message {
  static readonly displayName = "StreamNotificationsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamNotificationsResponse.AsObject {
    return StreamNotificationsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamNotificationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamNotificationsResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && Notification.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamNotificationsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamNotificationsResponse;
    return StreamNotificationsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamNotificationsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamNotificationsResponse}
   */
  static deserializeBinaryFromReader(msg: StreamNotificationsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new Notification;
        reader.readMessage(value2,Notification.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamNotificationsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamNotificationsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        Notification.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamNotificationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional Notification item = 2;
   * @return {?Notification}
   */
  getItem(): Notification {
    return /** @type{?Notification} */ (
      jspb.Message.getWrapperField(this, Notification, 2));
  }


  /** @param {?Notification|undefined} value */
  setItem(value?: Notification) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace GetNotificationsRequest {
  export interface AsObject {
    offset: number;
    limit: number;
  }
}
export class GetNotificationsRequest extends jspb.Message {
  static readonly displayName = "GetNotificationsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetNotificationsRequest.AsObject {
    return GetNotificationsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetNotificationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetNotificationsRequest) {
    var f: any;
    var obj: any = {
      offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetNotificationsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetNotificationsRequest;
    return GetNotificationsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetNotificationsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetNotificationsRequest}
   */
  static deserializeBinaryFromReader(msg: GetNotificationsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetNotificationsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetNotificationsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetNotificationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 offset = 1;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 limit = 2;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace GetNotificationsResponse {
  export interface AsObject {
    notificationsList: StreamNotificationsResponse.AsObject[];
  }
}
export class GetNotificationsResponse extends jspb.Message {
  static readonly displayName = "GetNotificationsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetNotificationsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetNotificationsResponse.AsObject {
    return GetNotificationsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetNotificationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetNotificationsResponse) {
    var f: any;
    var obj: any = {
      notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
      StreamNotificationsResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetNotificationsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetNotificationsResponse;
    return GetNotificationsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetNotificationsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetNotificationsResponse}
   */
  static deserializeBinaryFromReader(msg: GetNotificationsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamNotificationsResponse;
        reader.readMessage(value1,StreamNotificationsResponse.deserializeBinaryFromReader);
        msg.addNotifications(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetNotificationsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetNotificationsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNotificationsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        StreamNotificationsResponse.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetNotificationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated StreamNotificationsResponse notifications = 1;
   * @return {!Array<!StreamNotificationsResponse>}
   */
  getNotificationsList(): StreamNotificationsResponse[] {
    return /** @type{!Array<!StreamNotificationsResponse>} */ (
      jspb.Message.getRepeatedWrapperField(this, StreamNotificationsResponse, 1));
  }


  /** @param {!Array<!StreamNotificationsResponse>} value */
  setNotificationsList(value?: StreamNotificationsResponse[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!StreamNotificationsResponse=} opt_value
   * @param {number=} opt_index
   * @return {!StreamNotificationsResponse}
   */
  addNotifications(opt_value?: StreamNotificationsResponse, opt_index?: number): StreamNotificationsResponse {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, StreamNotificationsResponse, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearNotificationsList() {
    this.setNotificationsList([]);
  }


}
export namespace MarkNotificationsViewedRequest {
  export interface AsObject {
    notificationIdList: string[];
  }
}
export class MarkNotificationsViewedRequest extends jspb.Message {
  static readonly displayName = "MarkNotificationsViewedRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MarkNotificationsViewedRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MarkNotificationsViewedRequest.AsObject {
    return MarkNotificationsViewedRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MarkNotificationsViewedRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MarkNotificationsViewedRequest) {
    var f: any;
    var obj: any = {
      notificationIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MarkNotificationsViewedRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MarkNotificationsViewedRequest;
    return MarkNotificationsViewedRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MarkNotificationsViewedRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MarkNotificationsViewedRequest}
   */
  static deserializeBinaryFromReader(msg: MarkNotificationsViewedRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addNotificationId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MarkNotificationsViewedRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MarkNotificationsViewedRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNotificationIdList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MarkNotificationsViewedRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string notification_id = 1;
   * @return {!Array<string>}
   */
  getNotificationIdList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setNotificationIdList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addNotificationId(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearNotificationIdList() {
    this.setNotificationIdList([]);
  }


}
export namespace MarkNotificationsViewedResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class MarkNotificationsViewedResponse extends jspb.Message {
  static readonly displayName = "MarkNotificationsViewedResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MarkNotificationsViewedResponse.AsObject {
    return MarkNotificationsViewedResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MarkNotificationsViewedResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MarkNotificationsViewedResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MarkNotificationsViewedResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MarkNotificationsViewedResponse;
    return MarkNotificationsViewedResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MarkNotificationsViewedResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MarkNotificationsViewedResponse}
   */
  static deserializeBinaryFromReader(msg: MarkNotificationsViewedResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MarkNotificationsViewedResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MarkNotificationsViewedResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MarkNotificationsViewedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace MarkNotificationsReadRequest {
  export interface AsObject {
    notificationIdList: string[];
  }
}
export class MarkNotificationsReadRequest extends jspb.Message {
  static readonly displayName = "MarkNotificationsReadRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MarkNotificationsReadRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MarkNotificationsReadRequest.AsObject {
    return MarkNotificationsReadRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MarkNotificationsReadRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MarkNotificationsReadRequest) {
    var f: any;
    var obj: any = {
      notificationIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MarkNotificationsReadRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MarkNotificationsReadRequest;
    return MarkNotificationsReadRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MarkNotificationsReadRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MarkNotificationsReadRequest}
   */
  static deserializeBinaryFromReader(msg: MarkNotificationsReadRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addNotificationId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MarkNotificationsReadRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MarkNotificationsReadRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNotificationIdList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MarkNotificationsReadRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string notification_id = 1;
   * @return {!Array<string>}
   */
  getNotificationIdList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setNotificationIdList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addNotificationId(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearNotificationIdList() {
    this.setNotificationIdList([]);
  }


}
export namespace MarkNotificationsReadResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class MarkNotificationsReadResponse extends jspb.Message {
  static readonly displayName = "MarkNotificationsReadResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MarkNotificationsReadResponse.AsObject {
    return MarkNotificationsReadResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MarkNotificationsReadResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MarkNotificationsReadResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MarkNotificationsReadResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MarkNotificationsReadResponse;
    return MarkNotificationsReadResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MarkNotificationsReadResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MarkNotificationsReadResponse}
   */
  static deserializeBinaryFromReader(msg: MarkNotificationsReadResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MarkNotificationsReadResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MarkNotificationsReadResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MarkNotificationsReadResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace LoadUnviewedNotificationsRequest {
  export interface AsObject {
  }
}
export class LoadUnviewedNotificationsRequest extends jspb.Message {
  static readonly displayName = "LoadUnviewedNotificationsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoadUnviewedNotificationsRequest.AsObject {
    return LoadUnviewedNotificationsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoadUnviewedNotificationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoadUnviewedNotificationsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoadUnviewedNotificationsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoadUnviewedNotificationsRequest;
    return LoadUnviewedNotificationsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoadUnviewedNotificationsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoadUnviewedNotificationsRequest}
   */
  static deserializeBinaryFromReader(msg: LoadUnviewedNotificationsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoadUnviewedNotificationsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoadUnviewedNotificationsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoadUnviewedNotificationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace LoadUnviewedNotificationsResponse {
  export interface AsObject {
    notificationsList: Notification.AsObject[];
  }
}
export class LoadUnviewedNotificationsResponse extends jspb.Message {
  static readonly displayName = "LoadUnviewedNotificationsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LoadUnviewedNotificationsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoadUnviewedNotificationsResponse.AsObject {
    return LoadUnviewedNotificationsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoadUnviewedNotificationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoadUnviewedNotificationsResponse) {
    var f: any;
    var obj: any = {
      notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
      Notification.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoadUnviewedNotificationsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoadUnviewedNotificationsResponse;
    return LoadUnviewedNotificationsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoadUnviewedNotificationsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoadUnviewedNotificationsResponse}
   */
  static deserializeBinaryFromReader(msg: LoadUnviewedNotificationsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Notification;
        reader.readMessage(value1,Notification.deserializeBinaryFromReader);
        msg.addNotifications(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoadUnviewedNotificationsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoadUnviewedNotificationsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNotificationsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Notification.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoadUnviewedNotificationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Notification notifications = 1;
   * @return {!Array<!Notification>}
   */
  getNotificationsList(): Notification[] {
    return /** @type{!Array<!Notification>} */ (
      jspb.Message.getRepeatedWrapperField(this, Notification, 1));
  }


  /** @param {!Array<!Notification>} value */
  setNotificationsList(value?: Notification[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Notification=} opt_value
   * @param {number=} opt_index
   * @return {!Notification}
   */
  addNotifications(opt_value?: Notification, opt_index?: number): Notification {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Notification, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearNotificationsList() {
    this.setNotificationsList([]);
  }


}
