<mg-overlay-primary
  size="auto"
  overlayTitle="Change Photo"
  (closeModal)="close()">
  <div class="container">
    <div
      *ngIf="this.files.length == 0 && !this.existingImageUrl"
      class="file-uploader-container">
      <mg-file-input
        name="uploader-input"
        icon=""
        compactIcon=""
        displayStyle="CAMERA_IMAGE"
        [showFileDetails]="false"
        [customTitleText]="' '"
        [multiple]="false"
        [accept]="accept"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="files"
        (ngModelChange)="onFileSelected()">
      </mg-file-input>
      <mg-text
        variant="header-lg-secondary"
        class="image-uploader-title-text"
        >{{ DIALOG.NO_PHOTO }}</mg-text
      >
    </div>
    <ng-container *ngIf="this.files.length > 0 || this.existingImageUrl">
      <div class="image-cropper-wrapper">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageURL]="existingImageUrl"
          [maintainAspectRatio]="false"
          [aspectRatio]="1"
          format="jpeg"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          [transform]="transform"
          [canvasRotation]="canvasRotation"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>

      <div class="photo-controls">
        <mg-btn
          variant="outlined"
          size="small"
          iconSet="minga"
          icon="mg-rotate"
          (pressed)="rotateRight()"
          >Rotate photo</mg-btn
        >
        <mg-btn
          variant="outlined"
          size="small"
          iconSet="minga"
          icon="mg-delete"
          (pressed)="resetImage()"
          >Remove photo</mg-btn
        >
      </div>
    </ng-container>

    <ng-template #overlayFooter>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </ng-template>

    <div
      class="spinner-container"
      *ngIf="loading">
      <mg-spinner></mg-spinner>
    </div>
  </div>
</mg-overlay-primary>

<ng-template #footerTemplate>
  <div
    class="footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      variant="text"
      (click)="close()"
      >Cancel</mg-btn
    >
    <mg-btn
      variant="filled"
      (click)="onSubmit()">
      Save</mg-btn
    >
  </div></ng-template
>
