// TS2742
import * as $ngrx_store_store from '@ngrx/store';
import * as $ngrx_store_src_models from '@ngrx/store/src/models';
import { createAction, props } from '@ngrx/store';
// TS2742
import { IPeopleCollectionPersonDetails } from 'libs/domain';

export namespace PeopleCollectionActions {
  export const addPersonToCollection = createAction(
    '[People] Add Person',
    props<{ person: IPeopleCollectionPersonDetails }>(),
  );
  export const addPeopleToCollection = createAction(
    '[People] Add People',
    props<{ people: IPeopleCollectionPersonDetails[] }>(),
  );

  export type Union =
    | typeof addPersonToCollection
    | typeof addPeopleToCollection;
}
