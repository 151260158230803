// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_content_pb from '../content/content_pb';
import * as content_common_pb from '../content/common_pb';

export class Content {
  static readonly serviceName = "minga.content.Content";

  static readonly GetContentMetadata = {
    methodName: "GetContentMetadata",
    service: Content,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_content_pb.ContentMetadata,
  };

}

export class ContentClient {
  getContentMetadata() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
