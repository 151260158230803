import { day } from 'libs/day';

export enum GroupType {
  BY_MONTH = 'month',
  BY_DAY = 'date',
  BY_WEEK = 'week',
  BY_HOUR = 'hour',
}

export const getGroupRangeByDate = (
  startDate: day.Dayjs,
  endDate: day.Dayjs,
): GroupType => {
  if (!startDate || !endDate) {
    return GroupType.BY_DAY;
  }
  const days = endDate.diff(startDate, 'days');
  if (days < 1) {
    return GroupType.BY_HOUR;
  }
  if (days > 31 && days <= 182) {
    return GroupType.BY_WEEK;
  }
  if (days <= 31) {
    return GroupType.BY_DAY;
  }
  return GroupType.BY_MONTH;
};

export const formatMinutesIntoHours = (
  totalMinutes: number,
  showAbbr = true,
) => {
  if (!totalMinutes) return '0';
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  return `${hours}:${minutes.toString().padStart(2, '0')}${
    showAbbr ? ' hrs' : ''
  }`;
};

export const formatDateFull = (date: Date) => {
  if (!date) return 'N/A';
  return day(date).format('MMM D, YYYY HH:mm');
};
