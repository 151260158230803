import { $enum } from 'ts-enum-util';

import { SchoolTypeValue } from './MingaEnum';

export enum Grade {
  K = 'K',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  TEN = '10',
  ELEVEN = '11',
  TWELVE = '12',
  THIRTEEN = '13',
}

export const toString = (grade: string): string => {
  if (grade === Grade.K) return 'Kindergarten';
  else return `Grade ${grade}`;
};

export const getGrades = (): string[] => {
  return [
    Grade.K,
    Grade.ONE,
    Grade.TWO,
    Grade.THREE,
    Grade.FOUR,
    Grade.FIVE,
    Grade.SIX,
    Grade.SEVEN,
    Grade.EIGHT,
    Grade.NINE,
    Grade.TEN,
    Grade.ELEVEN,
    Grade.TWELVE,
    Grade.THIRTEEN,
  ];
};

export const getAllowedGrades = (schoolType: SchoolTypeValue): string[] => {
  switch (schoolType) {
    case SchoolTypeValue.ELEMENTARY:
      return getElementarySchoolGrades();
    case SchoolTypeValue.MIDDLE_SCHOOL:
      return getMiddleSchoolGrades();
    case SchoolTypeValue.HIGH_SCHOOL:
      return getHighSchoolGrades();
    default:
      return getGrades();
  }
};

export const isGrade = (grade: string): boolean => {
  return getGrades().includes(grade);
};

export const getElementarySchoolGrades = (): string[] => {
  return [
    Grade.K,
    Grade.ONE,
    Grade.TWO,
    Grade.THREE,
    Grade.FOUR,
    Grade.FIVE,
    Grade.SIX,
    Grade.SEVEN,
    Grade.EIGHT,
  ];
};

export const getMiddleSchoolGrades = (): string[] => {
  return [Grade.FIVE, Grade.SIX, Grade.SEVEN, Grade.EIGHT, Grade.NINE];
};

export const getHighSchoolGrades = (): string[] => {
  return [
    Grade.SIX,
    Grade.SEVEN,
    Grade.EIGHT,
    Grade.NINE,
    Grade.TEN,
    Grade.ELEVEN,
    Grade.TWELVE,
    Grade.THIRTEEN,
  ];
};

export const mapOneRosterGradesToMingaGrades = (grades: string[]): Grade[] => {
  return grades.map(grade => mapOneRosterGradeToMingaGrade(grade));
};

export const mapOneRosterGradeToMingaGrade = (grade: string): Grade => {
  if (grade === 'KG') {
    return Grade.K;
  }
  // single digit grade's are prefixed with 0
  if (grade.indexOf('0') === 0) {
    grade = grade.substr(1);
  }

  return $enum(Grade).asValueOrThrow(grade);
};

export const mapCleverGradesToMingaGrades = (grades: string[]): Grade[] => {
  return grades.map(grade => mapCleverRosterGradeToMingaGrade(grade));
};

export const mapCleverRosterGradeToMingaGrade = (grade: string): Grade => {
  if (grade === 'Kindergarten') {
    return Grade.K;
  }
  return $enum(Grade).asValueOrThrow(grade);
};
