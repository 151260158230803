import { ReportsService } from '@app/src/app/components/manager-report/services/report-service.service';

export class RtModalShellReportService extends ReportsService<any> {
  constructor() {
    super(null);
  }

  protected _handleSetFilter(_filter: any, _value: any): void {}
  protected _mapFiltersToFilterMessage(_reportType: any): any {}
}
