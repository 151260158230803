// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as mailer_mailer_pb from '../mailer/mailer_pb';
import * as common_legacy_pb from '../common/legacy_pb';

export class Mailer {
  static readonly serviceName = "Mailer";

  static readonly addMail = {
    methodName: "addMail",
    service: Mailer,
    requestStream: false,
    responseStream: false,
    requestType: mailer_mailer_pb.AddMailRequest,
    responseType: mailer_mailer_pb.AddMailResponse,
  };

  static readonly flushMail = {
    methodName: "flushMail",
    service: Mailer,
    requestStream: false,
    responseStream: false,
    requestType: mailer_mailer_pb.FlushMailRequest,
    responseType: mailer_mailer_pb.FlushMailResponse,
  };

}

export class MailerClient {
  addMail() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  flushMail() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
