import { Injectable } from '@angular/core';

import { ICheckinReason } from 'libs/domain';
import { checkin_pb, checkin_ng_grpc_pb } from 'libs/generated-grpc-web';
import { CheckinReasonMapper } from 'libs/shared-grpc';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';
import { RootService } from '@app/src/app/minimal/services/RootService';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import QrCodeService from '@shared/services/qrcode/qrcode.service';

import { StCheckinComponent } from '../components/st-checkin/st-checkin.component';
import { StudentToolsCheckinData, StudentToolsCheckinResponse } from '../types';

@Injectable()
export class StudentToolsCheckinService {
  /** Service Constructor */
  constructor(
    private _barcodeScanner: BarcodeScanner,
    private _checkInManager: checkin_ng_grpc_pb.CheckinManager,
    private _qrCodeService: QrCodeService,
    private _modalOverlay: ModalOverlayService<
      StudentToolsCheckinResponse,
      StudentToolsCheckinData
    >,
    private _systemAlertModal: SystemAlertModalService,
    private _rootService: RootService,
  ) {}

  public async selfCheck(reasonId: number): Promise<void> {
    try {
      await this._rootService.addLoadingPromise(this._selfCheckIn(reasonId));
      this._openSuccessModal();
    } catch (error) {
      this._openErrorModal(error.message);
    }
  }

  public async selfCheckOut(reasonId: number): Promise<void> {
    try {
      await this._rootService.addLoadingPromise(this._selfCheckOut(reasonId));
      this._openSuccessModal(true);
    } catch (error) {
      this._openErrorModal(error.message);
    }
  }

  public async openScanner(
    checkingOut: boolean,
    isQRCode?: boolean,
  ): Promise<void> {
    const result = await this._barcodeScanner.scan(
      isQRCode ? ['qrcode'] : ['barcode'],
    );
    if (result.cancelled) return;
    const reason = await this._fetchReasonFromQrData(result.text);
    if (!reason) return;
    if (!reason.active) {
      this._openErrorModal(`"${reason.name}" is currently inactive`);
    } else if (checkingOut && !reason.allowCheckout) {
      this._openErrorModal(`"${reason.name}" does not allow checkouts`);
    } else {
      this.openModal({
        reason,
        isCheckingOut: checkingOut,
      });
    }
  }

  public openModal(options: StudentToolsCheckinData) {
    return this._modalOverlay.open(StCheckinComponent, {
      data: options,
      hasBackdrop: true,
    });
  }

  private _openSuccessModal(checkingOut?: boolean) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.SUCCESS,
      heading: 'Success',
      message: `Successfully checked ${checkingOut ? 'out' : 'in'}`,
      hangTime: SystemAlertModalHangTime.SHORT,
    });
  }

  private _openErrorModal(
    errorMessage = 'Oops something went wrong while processing your request',
  ) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.ERROR,
      heading: 'Error',
      message: errorMessage,
      hangTime: SystemAlertModalHangTime.LONG,
    });
  }

  private async _fetchReasonFromQrData(
    qrDataString: string,
  ): Promise<ICheckinReason> {
    try {
      const { reasonId } =
        this._qrCodeService.readCheckinReasonQrCode(qrDataString);
      const reason = await this._getMingaCheckinReason(reasonId);
      return reason;
    } catch (error) {
      this._openErrorModal(error.message);
    }
  }

  private async _getMingaCheckinReason(id: number): Promise<ICheckinReason> {
    const request = new checkin_pb.GetMingaCheckinReasonRequest();
    request.setId(id);
    const res = await this._checkInManager.getMingaCheckinReason(request);
    const reason = res.getCheckinReason();
    return CheckinReasonMapper.fromProto(reason);
  }

  private async _selfCheckIn(id: number): Promise<void> {
    const request = new checkin_pb.SelfCheckInRequest();
    request.setCheckinReasonId(id);
    request.setBypassCode(true);
    await this._checkInManager.selfCheckIn(request);
  }

  private async _selfCheckOut(id: number): Promise<void> {
    const request = new checkin_pb.SelfCheckOutRequest();
    request.setCheckinReasonId(id);
    await this._checkInManager.selfCheckOut(request);
  }
}
