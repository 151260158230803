// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as sms_sms_pb from '../sms/sms_pb';

export class SmsMinga {
  static readonly serviceName = "minga.sms.SmsMinga";

  static readonly GetDetails = {
    methodName: "GetDetails",
    service: SmsMinga,
    requestStream: false,
    responseStream: false,
    requestType: sms_sms_pb.SmsMingaGetDetailsRequest,
    responseType: sms_sms_pb.MingaSmsDetails,
  };

}

export class SmsAdmin {
  static readonly serviceName = "minga.sms.SmsAdmin";

  static readonly GetDetails = {
    methodName: "GetDetails",
    service: SmsAdmin,
    requestStream: false,
    responseStream: false,
    requestType: sms_sms_pb.SmsAdminGetDetailsRequest,
    responseType: sms_sms_pb.MingaSmsDetails,
  };

  static readonly SetMaxSendCount = {
    methodName: "SetMaxSendCount",
    service: SmsAdmin,
    requestStream: false,
    responseStream: false,
    requestType: sms_sms_pb.SmsAdminSetMaxSendCountRequest,
    responseType: sms_sms_pb.MingaSmsDetails,
  };

  static readonly AddMessageBlock = {
    methodName: "AddMessageBlock",
    service: SmsAdmin,
    requestStream: false,
    responseStream: false,
    requestType: sms_sms_pb.SmsAdminAddMessageBlockRequest,
    responseType: sms_sms_pb.MingaSmsDetails,
  };

}

export class SmsMingaClient {
  getDetails() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class SmsAdminClient {
  getDetails() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setMaxSendCount() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addMessageBlock() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
