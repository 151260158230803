export enum IMingaFeatureToggleKeys {
  TRACKING_ENABLED = 'trackingEnabled',
  CHECKIN_ENABLED = 'checkinEnabled',
  HALLPASS_ENABLED = 'hallPassEnabled',
  STUDENT_ID_ENABLED = 'studentIdEnabled',
  COMMUNITY_ENABLED = 'communityEnabled',
  PHOTO_GALLERY_ENABLED = 'photoGalleryEnabled',
  DM_ENABLED = 'directMessagingEnabled',
  FLEX_TIME_ENABLED = 'flexTimeEnabled',
}

export type IMingaFeatureToggle = Record<IMingaFeatureToggleKeys, boolean>;

export const superAdminModules = [
  'trackingEnabled',
  'hallPassEnabled',
  'studentIdEnabled',
  'communityEnabled',
  'checkinEnabled',
  'flexTimeEnabled',
];
