import { MingaTypeValue } from 'libs/util';

import { IMingaType } from './interface';
import { MingaConferenceMingaType } from './internal/MingaConferenceMingaType';
import { MingaSchoolMingaType } from './internal/MingaSchoolMingaType';

const mingaTypeMap = {
  [MingaTypeValue.CONFERENCE]: new MingaConferenceMingaType(),
  [MingaTypeValue.MINGA]: new MingaSchoolMingaType(),
};

export function getMingaType(mingaType: MingaTypeValue): IMingaType {
  if (mingaTypeMap[mingaType]) {
    return mingaTypeMap[mingaType];
  }

  throw new Error(`Minga type '${mingaType}' does not exist`);
}

export * from './interface';
export * from './internal';
