import {
  autoCompleteTypes,
  ConsequenceTypesEnum,
  IConsequence,
  OverdueConsequenceActionType,
} from 'libs/domain';
import {
  IConsequenceStatsPeopleResult,
  IPbisBehavior,
  IPbisStatsPeopleResult,
} from 'libs/domain';
import { $enum } from 'ts-enum-util';

import { PbisCategoriesObj, PbisCategory } from '../../';
import { ColumnInfo, ExportNameType } from '../types';
import { gradeFinder, nameFinder, roleFinder, studentIdFinder } from '../utils';
import {
  firstName,
  grade,
  lastName,
  note,
  role,
  studentId,
  currentClass,
  sectionId,
  periodId,
  termId,
  sectionNumber,
  staffId,
  schoolId,
} from './columns.constants';
import {
  DateColumnKeys,
  NameColumnKeys,
  TimeColumnKeys,
} from './common.constants';

const category: ColumnInfo = {
  header: 'Category',
  key: 'behaviorCategory',
  accessorFn: (item: any, color?: boolean) => {
    const categoryId = item.type ? item.type.categoryId : item.typeCategory;
    const categoryVal = $enum(PbisCategory).asValueOrThrow(categoryId);
    const behaviorCategory = PbisCategoriesObj[categoryVal];
    return color ? behaviorCategory.color : behaviorCategory.name;
  },
  type: 'text',
  width: 'medium',
};

const issuedBy: ColumnInfo = {
  header: 'Assigned by',
  key: NameColumnKeys.TEACHER_NAME,
  tooltip: true,
  accessorFn: (item: any, value?: ExportNameType) => {
    if (item.imported) return 'Imported';
    if (item.issuedByPerson) return nameFinder(item.issuedByPerson, value);
    if (item.issuedBy) return nameFinder(item.issuedBy, value);
    return '';
  },
  defaultValue: 'Automation',
  type: 'text',
  width: 'flex-medium',
};

const consequenceName: ColumnInfo = {
  header: 'Consequence name',
  key: 'consequenceName',
  tooltip: true,
  type: 'text',
  width: 'flex-large',
};

const praiseCount: ColumnInfo = {
  key: 'praiseCount',
  header: 'Praise',
  defaultValue: '0',
  sort: true,
  type: 'number',
  width: 'small',
  accessorFn: (item: IPbisStatsPeopleResult) => item.stats?.totalPraise,
};

const guidanceCount: ColumnInfo = {
  key: 'guidanceCount',
  header: 'Guidance',
  defaultValue: '0',
  sort: true,
  type: 'number',
  width: 'medium',
  accessorFn: (item: IPbisStatsPeopleResult) => item.stats?.totalGuidance,
};

const consequenceCount: ColumnInfo = {
  key: 'consequenceCount',
  header: 'Consequence',
  defaultValue: '0',
  sort: true,
  type: 'number',
  width: 'large',
  accessorFn: (item: IPbisStatsPeopleResult) => item.stats?.totalConsequence,
};

const consequenceEscalatedCount: ColumnInfo = {
  key: 'consequenceOverdueAction',
  header: 'Additional / Escalated',
  type: 'number',
  width: 'large',
  accessorFn: (item: IPbisStatsPeopleResult) =>
    item.stats?.totalEscalatedConsequence,
};

export const STUDENTS: ColumnInfo[] = [
  firstName,
  lastName,
  studentId,
  { ...grade, width: 'small' },
  role,
  praiseCount,
  guidanceCount,
  consequenceCount,
  {
    key: 'consequenceOverdueCount',
    header: 'Overdue',
    sort: true,
    type: 'number',
    width: 'medium',
    defaultValue: '0',
    accessorFn: (item: IPbisStatsPeopleResult) =>
      item.stats?.totalOverdueConsequence,
  },
  consequenceEscalatedCount,
  {
    key: 'consequenceActiveCount',
    header: 'Not completed',
    sort: true,
    type: 'number',
    width: 'large',
    defaultValue: '0',
    accessorFn: (item: IPbisStatsPeopleResult) =>
      item.stats?.totalActiveConsequence,
  },
  {
    key: 'consequenceCompleteCount',
    header: 'Completed',
    sort: true,
    type: 'number',
    width: 'medium',
    defaultValue: '0',
    accessorFn: (item: IPbisStatsPeopleResult) =>
      item.stats?.totalCompleteConsequence,
  },
];

export const STAFF: ColumnInfo[] = [
  firstName,
  lastName,
  role,
  praiseCount,
  guidanceCount,
  consequenceCount,
];

export const TYPES: ColumnInfo[] = [
  {
    header: 'Behavior',
    key: 'typeName',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
  },
  category,
  {
    key: 'total',
    header: 'Assigned',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'medium',
  },
];

export const HISTORY: ColumnInfo[] = [
  {
    ...firstName,
    accessorFn: (item: IPbisBehavior) =>
      item.recipient ? nameFinder(item.recipient, 'first') : '',
  },
  {
    ...lastName,
    accessorFn: (item: IPbisBehavior) =>
      item.recipient ? nameFinder(item.recipient, 'last') : '',
  },
  {
    ...studentId,
    accessorFn: (item: IPbisBehavior) => studentIdFinder(item.recipient),
  },
  {
    ...grade,
    accessorFn: (item: IPbisBehavior) => gradeFinder(item.recipient),
  },
  {
    ...role,
    accessorFn: (item: IPbisBehavior, value?: boolean) =>
      roleFinder(item.recipient, value),
  },
  {
    header: 'Note',
    key: 'note',
    tooltip: true,
    type: 'text',
    width: 'small',
    accessorFn: (item: IPbisBehavior) => item.message || '',
  },
  currentClass,
  sectionId,
  periodId,
  termId,
  sectionNumber,
  schoolId,
  {
    header: 'Behavior',
    key: 'behaviorName',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
    accessorFn: (item: IPbisBehavior) => item.type?.name || '',
  },
  category,
  {
    ...issuedBy,
    accessorFn: (item: IPbisBehavior, value?: ExportNameType) => {
      if (item.creator) return nameFinder(item.creator, value);
      if (item.imported) return 'Imported';
      return '';
    },
  },
  {
    ...staffId,
    accessorFn: (item: IPbisBehavior) => studentIdFinder(item.creator),
  },
  {
    header: 'Date',
    key: DateColumnKeys.BEHAVIOR_DATE,
    sort: true,
    type: 'date',
    width: 'small',
    accessorFn: (item: IPbisBehavior) => item.createdAt,
  },
  {
    header: 'Time',
    key: TimeColumnKeys.BEHAVIOR_TIME,
    type: 'date',
    width: 'small',
    accessorFn: (item: IPbisBehavior) => item.createdAt,
  },
  {
    header: 'Last Modified',
    key: DateColumnKeys.MANUALLY_UPDATED_AT,
    type: 'date',
    width: 'medium',
  },
];

export const CONSEQUENCE_TYPES: ColumnInfo[] = [
  { ...consequenceName, key: 'typeName' },
  category,
  {
    key: 'total',
    header: 'Assigned',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'medium',
  },
  {
    key: 'totalComplete',
    header: 'Completed',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'medium',
  },
  {
    key: 'totalActive',
    header: 'Not completed',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'large',
  },
  {
    key: 'totalOverdue',
    header: 'Overdue',
    defaultValue: '0',
    sort: true,
    type: 'number',
    width: 'small',
  },
];

export const CONSEQUENCE_HISTORY: ColumnInfo[] = [
  { header: 'Status', key: 'status', type: 'tag', width: 'medium' },
  firstName,
  lastName,
  studentId,
  grade,
  role,
  note,
  {
    ...consequenceName,
    width: 'flex-large',
    tooltip: true,
    accessorFn: (item: IConsequence) => item.type?.name || '',
  },
  {
    header: 'Consequence ID',
    key: 'consequenceId',
    type: 'number',
    width: 'large',
    accessorFn: (item: IConsequence) => item.id || undefined,
  },
  {
    ...category,
    width: 'large',
  },
  {
    header: 'Type',
    key: 'categoryType',
    type: 'text',
    width: 'large',
    accessorFn: (item: IConsequence) => {
      if (item.type?.type) {
        const categoryType = ConsequenceTypesEnum[item.type.type];
        return categoryType.charAt(0) + categoryType.slice(1).toLowerCase();
      }
      return '';
    },
  },
  {
    ...issuedBy,
    accessorFn: (item: IConsequence, value?: ExportNameType) => {
      if (item.imported) return 'Imported';
      if (item?.sourceOverdueActionType) {
        const overdueActionType = item.sourceOverdueActionType;
        return overdueActionType === OverdueConsequenceActionType.ESCALATE
          ? 'Escalated'
          : 'Additional';
      }
      if (item.automationName) {
        return value === 'last' ? '' : item.automationName;
      }
      if (item.issuedByPerson) return nameFinder(item.issuedByPerson, value);
      return '';
    },
  },
  {
    header: 'Source ID',
    key: 'sourceId',
    type: 'number',
    width: 'medium',
    linkableFn: (item: IConsequence) => {
      if (!item?.sourceOverdueActionType) return undefined;
      return {
        href: `/behaviors/reports/pbis_cons_history?consequenceId=${item.sourceId}`,
        target: '_blank',
      };
    },
    accessorFn: (item: IConsequence) => item.sourceId || undefined,
  },
  {
    header: 'Automation Group',
    key: 'automationGroup',
    type: 'text',
    width: 'flex-large',
    tooltip: true,
    accessorFn: (item: IConsequence) => item.automationName || '',
  },
  {
    header: 'Automation',
    key: 'automationName',
    type: 'text',
    width: 'large',
    tooltip: true,
    accessorFn: (item: IConsequence) => item.actionName || '',
  },
  {
    header: 'Assigned Date',
    key: DateColumnKeys.ISSUED_DATE,
    sort: true,
    type: 'date',
    width: 'medium',
    accessorFn: (item: IConsequence) => item.issuedAt,
  },
  {
    header: 'Assigned Time',
    key: TimeColumnKeys.ISSUED_TIME,
    type: 'date',
    width: 'medium',
    accessorFn: (item: IConsequence) => item.issuedAt,
  },
  {
    header: 'Due Day',
    key: DateColumnKeys.DUE_DATE,
    type: 'date',
    sort: true,
    width: 'medium',
    accessorFn: (item: IConsequence) => item.completeBy,
  },
  {
    header: 'Completed Date',
    key: DateColumnKeys.COMPLETE_DATE,
    type: 'date',
    sort: true,
    width: 'medium',
    accessorFn: (item: IConsequence) => {
      return !autoCompleteTypes.includes(item?.type?.type ?? 0)
        ? item.completedAt
        : '';
    },
  },
  {
    header: 'Completed Time',
    key: TimeColumnKeys.COMPLETE_TIME,
    type: 'date',
    width: 'medium',
    accessorFn: (item: IConsequence) => {
      return !autoCompleteTypes.includes(item?.type?.type ?? 0)
        ? item.completedAt
        : '';
    },
  },
  {
    header: 'Completed by',
    key: 'completedBy',
    type: 'text',
    width: 'flex-medium',
    tooltip: true,
    accessorFn: (item: IConsequence) => {
      if (
        !item.completedAt ||
        autoCompleteTypes.includes(item?.type?.type ?? 0)
      ) {
        return '';
      }
      if (item.completedBy && item.completedByPerson) {
        const person = item.completedByPerson;
        return `${person.firstName} ${person.lastName}`;
      } else if (
        item.handledByOverdueAction &&
        item.handledByOverdueAction === OverdueConsequenceActionType.ESCALATE
      ) {
        return 'Escalated';
      } else {
        return 'Check in automation';
      }
    },
  },
  {
    header: 'Last Modified',
    key: DateColumnKeys.MANUALLY_UPDATED_AT,
    type: 'date',
    width: 'medium',
  },
];

export const AUTOMATION_COUNTER: ColumnInfo[] = [
  firstName,
  lastName,
  studentId,
  grade,
  role,
  {
    header: 'Automation Group',
    key: 'automationGroup',
    tooltip: true,
    width: 'flex-medium',
    type: 'text',
  },
  {
    header: 'Counter',
    key: 'counter',
    defaultValue: '0',
    sort: true,
    width: 'small',
    type: 'number',
  },
  // @todo add these columns when our data is more accurate
  // {
  //   header: 'Consequence',
  //   key: 'consequence',
  //   defaultValue: '0',
  //   sort: true,
  // },
  // {
  //   header: 'Overdue',
  //   key: 'overdue',
  //   defaultValue: '0',
  //   sort: true,
  // },
  // {
  //   header: 'Additional / Escalated',
  //   key: 'additional',
  //   defaultValue: '0',
  //   sort: true,
  // },
  // {
  //   header: 'Not Completed',
  //   key: 'incomplete',
  //   defaultValue: '0',
  //   sort: true,
  // },
  // {
  //   header: 'Completed',
  //   key: 'completed',
  //   defaultValue: '0',
  //   sort: true,
  //   accessorFn: (item: any) => item.complete,
  // },
];

export const CONSEQUENCE_OVERDUE: ColumnInfo[] = [
  firstName,
  lastName,
  studentId,
  {
    key: 'outstanding',
    header: 'Not completed',
    defaultValue: '0',
    accessorFn: (item: IConsequenceStatsPeopleResult) =>
      item.stats.totalOutstandingConsequence,
    width: 'small',
  },
  {
    key: 'overdue',
    header: 'Overdue',
    defaultValue: '0',
    accessorFn: (item: IConsequenceStatsPeopleResult) =>
      item.stats.totalOverdueConsequence,
    width: 'small',
  },
  {
    key: 'assigned',
    header: 'Assigned',
    defaultValue: '0',
    accessorFn: (item: IConsequenceStatsPeopleResult) =>
      item.stats.totalConsequence,
    width: 'small',
  },
  {
    key: 'served',
    header: 'Completed',
    defaultValue: '0',
    accessorFn: (item: IConsequenceStatsPeopleResult) =>
      item.stats.totalServedConsequence,
    width: 'small',
  },
];
