// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as pbis_stats_internal_pbis_stats_internal_pb from '../pbis_stats_internal/pbis_stats_internal_pb';

export class PbisStatsInternalService {
  static readonly serviceName = "minga.pbis_stats_internal.PbisStatsInternalService";

  static readonly GetPbisSummaryReportByType = {
    methodName: "GetPbisSummaryReportByType",
    service: PbisStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisSummaryReportByTypeRequest,
    responseType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisSummaryReportByTypeResponse,
  };

  static readonly GetPbisTypeReport = {
    methodName: "GetPbisTypeReport",
    service: PbisStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisTypeReportRequest,
    responseType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisTypeReportResponse,
  };

  static readonly GetPbisTopUsers = {
    methodName: "GetPbisTopUsers",
    service: PbisStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisTopUsersRequest,
    responseType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisTopUsersResponse,
  };

  static readonly GetPbisTopCreators = {
    methodName: "GetPbisTopCreators",
    service: PbisStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisTopCreatorsRequest,
    responseType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetPbisTopCreatorsResponse,
  };

  static readonly GetConsequenceTopTypes = {
    methodName: "GetConsequenceTopTypes",
    service: PbisStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetConsequenceTopTypesRequest,
    responseType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetConsequenceTopTypesResponse,
  };

  static readonly GetConsequenceTopUsers = {
    methodName: "GetConsequenceTopUsers",
    service: PbisStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetConsequenceTopUsersRequest,
    responseType: pbis_stats_internal_pbis_stats_internal_pb.InternalGetConsequenceTopUsersResponse,
  };

}

export class PbisStatsInternalServiceClient {
  getPbisSummaryReportByType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPbisTypeReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPbisTopUsers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPbisTopCreators() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getConsequenceTopTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getConsequenceTopUsers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
