import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SectionType } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from '@app/src/app/permissions';
import { HallPassService } from '@app/src/app/services/HallPass';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { BellSchedulePermissionsService } from '@modules/minga-manager/components/mm-bell-schedule/services/bell-schedule-permissions.service';
import { PeopleViewProfilePerson } from '@modules/people/components/people-view-profile/types';
import { ToolsActionsService } from '@modules/tools/services/tools.actions.service';
import { countActiveStatViews } from '@modules/tools/shared-tools/components/tools-stats-placeholder/tools-stats-placeholder.component';
import {
  ACTION_CATEGORY,
  ACTION_KEY,
} from '@modules/tools/shared-tools/types/shared-tools.types';
import { StudentDashboardAbstract } from '@modules/tools/shared-tools/utils/student-dashboard-abstract.utils';
import { ToolsRoutes } from '@modules/tools/tools.types';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { getAppColor } from '@shared/constants';
import { StatsService } from '@shared/services/stats/stats.service';
import { UserStats } from '@shared/services/stats/stats.types';
import { StudentScheduleService } from '@shared/services/student-schedule';
import { getToolsScheduleRoute } from '@shared/services/student-schedule/utils/student-schedule.utils';

import { StMessages } from '../../constants';
import { StudentToolsFlexTimeService } from '../../services';

@Component({
  selector: 'mg-st-my-tools',
  templateUrl: './st-my-tools.component.html',
  styleUrls: ['./st-my-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ToolsActionsService],
})
export class StMyToolsComponent extends StudentDashboardAbstract {
  /** Constants */
  public readonly MESSAGES = StMessages;
  public readonly TOOLS_ROUTES = ToolsRoutes;

  public hallPassCreateAction = this._toolsActions.actions.find(
    a => a.key === ACTION_KEY.CREATE_HALL_PASS,
  );

  public selfCheckInAction = this._toolsActions.actions.find(
    a => a.key === ACTION_KEY.SELF_CHECK_IN,
  );

  public assignActions = this._toolsActions.actions
    .filter(a => a.usedIn.includes('student'))
    .filter(a =>
      [ACTION_CATEGORY.PBIS, ACTION_CATEGORY.POINTS].includes(a.category),
    );

  public hasAssignActions$ = combineLatest(
    this.assignActions.map(action => action.hasPermission$),
  ).pipe(map(permissions => permissions.some(hasPermission => hasPermission)));

  public authHash$ = this._authInfo.authPersonHash$;
  public person$: Observable<PeopleViewProfilePerson> =
    this._authInfo.authPerson$.pipe(
      map(p => {
        if (!p) return null;

        return {
          hash: p.hash,
          badgeRoleName: p.badgeRoleName,
          firstName: p.firstName,
          lastName: p.lastName,
          profileImageUrl: p.avatarUrl,
          grade: p.grade,
          studentId: p.studentId,
          filename: p.filename,
        };
      }),
    );

  public isGroupsEnabled$ = this.mingaSettings.isCommunityModuleEnabled();
  public readonly isBehaviorEnabled$ = this.mingaSettings.isPbisModuleEnabled();
  public readonly isFlexEnabled$ = this.mingaSettings.isFlexTimeModuleEnabled();
  public readonly isConsequencesEnabled$ = combineLatest([
    this.isBehaviorEnabled$,
    this.mingaSettings.getSettingValueObs(
      mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
    ),
  ]).pipe(
    map(
      ([behaviorEnabled, consequenceEnabled]) =>
        behaviorEnabled && consequenceEnabled,
    ),
  );

  /**
   * Get the quantity of stats that will be displayed in the view. Used for determining
   * what layout should be shown to the user. The stats in this list should match the
   * stats found in the view template #metricsCards.
   *
   * Ideally the individual stats cards would emit events for their loading status,
   * and the placeholders would be part of those components.
   */
  public readonly totalStatsCount$: Observable<number> = combineLatest([
    this.isBehaviorEnabled$,
    this.isFlexEnabled$,
    this.isConsequencesEnabled$,
  ]).pipe(
    map(stats => {
      const statViewCounts = {
        0: 1, // Behavior
        1: 1, // Flex
        2: 2, // Consequence
      };

      return countActiveStatViews(stats, statViewCounts);
    }),
  );

  protected _loadingStatsSubject = new BehaviorSubject<boolean>(true);
  public loadingStats$ = this._loadingStatsSubject.asObservable();

  private _userStatsSubject = new BehaviorSubject<UserStats>(null);
  public userStats$ = this._userStatsSubject.asObservable();

  public ICON_COLORS = {
    hallPass: getAppColor('hall-pass'),
    checkIn: getAppColor('check-in'),
    assign: getAppColor('secondary-600'),
  };

  /** Component Constructor */
  constructor(
    public _activatedRoute: ActivatedRoute,
    private _authInfo: AuthInfoService,
    private _toolsActions: ToolsActionsService,
    public router: Router,
    public appConfig: AppConfigService,
    private _statsService: StatsService,
    // ... inject services from the abstract class ...
    public hallPassService: HallPassService,
    public studentScheduleService: StudentScheduleService,
    public studentFlexTime: StudentToolsFlexTimeService,
    public mingaSettings: MingaSettingsService,
    public snackBarService: SystemAlertSnackBarService,
    public permissions: PermissionsService,
    private _router: Router,
    public bellSchedulePermissions: BellSchedulePermissionsService,
  ) {
    super(
      hallPassService,
      studentScheduleService,
      studentFlexTime,
      mingaSettings,
      snackBarService,
      appConfig,
      permissions,
      bellSchedulePermissions,
    );
  }

  protected onInit(): void {
    const authHash = this._authInfo.authPersonHash;
    this._fetchStudentData(authHash);
    this._getUserStats();
  }

  public goToSchedule({ isFlexActivity }) {
    const type = isFlexActivity ? SectionType.FLEX : SectionType.BELL;
    const route = getToolsScheduleRoute(type);
    this.router.navigate(route);
  }

  public handleStatNavigate(route: string[]) {
    this._router.navigate(route);
  }

  private async _getUserStats() {
    try {
      this._loadingStatsSubject.next(true);
      const authHash = this._authInfo.authPersonHash;

      const stats = await this._statsService.getStatsForUser(authHash);
      this._userStatsSubject.next(stats);
    } catch (e) {
      this.snackBarService.error(StMessages.STATS_LOAD_ERROR);
    } finally {
      this._loadingStatsSubject.next(false);
    }
  }
}
