// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_minga_pb from '../gateway/minga_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_file_pb from '../gateway/file_pb';
import * as common_date_pb from '../common/date_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as common_graph_pb from '../common/graph_pb';

export class MingaManager {
  static readonly serviceName = "MingaManager";

  static readonly resetGroups = {
    methodName: "resetGroups",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ResetGroupsRequest,
    responseType: gateway_minga_pb.ResetGroupsResponse,
  };

  static readonly resetPoints = {
    methodName: "resetPoints",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ResetPointsRequest,
    responseType: gateway_minga_pb.ResetPointsResponse,
  };

  static readonly ResetAutomationCounters = {
    methodName: "ResetAutomationCounters",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ResetAutomationCountersRequest,
    responseType: gateway_minga_pb.ResetAutomationCountersResponse,
  };

  static readonly resetGroupsMembers = {
    methodName: "resetGroupsMembers",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ResetGroupsMembersRequest,
    responseType: gateway_minga_pb.ResetGroupsMembersResponse,
  };

  static readonly resetUserLists = {
    methodName: "resetUserLists",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ResetUserListsRequest,
    responseType: gateway_minga_pb.ResetUserListsResponse,
  };

  static readonly updateSIS = {
    methodName: "updateSIS",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateSISRequest,
    responseType: gateway_minga_pb.UpdateSISResponse,
  };

  static readonly deleteSIS = {
    methodName: "deleteSIS",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.DeleteSISRequest,
    responseType: gateway_minga_pb.DeleteSISResponse,
  };

  static readonly activateRosteringMethod = {
    methodName: "activateRosteringMethod",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ActivateRosteringMethodRequest,
    responseType: gateway_minga_pb.ActivateRosteringMethodResponse,
  };

  static readonly deactivateAllRosteringMethods = {
    methodName: "deactivateAllRosteringMethods",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.DeactivateAllRosteringMethodsRequest,
    responseType: gateway_minga_pb.DeactivateAllRosteringMethodsResponse,
  };

  static readonly getSIS = {
    methodName: "getSIS",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetSISRequest,
    responseType: gateway_minga_pb.GetSISResponse,
  };

  static readonly getSISErrorDetails = {
    methodName: "getSISErrorDetails",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetSISErrorDetailsRequest,
    responseType: gateway_minga_pb.GetSISErrorDetailsResponse,
  };

  static readonly TestSISIntegration = {
    methodName: "TestSISIntegration",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.TestSISRequest,
    responseType: gateway_minga_pb.TestSISResponse,
  };

  static readonly getSISSync = {
    methodName: "getSISSync",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetSISSyncRequest,
    responseType: gateway_minga_pb.GetSISSyncResponse,
  };

  static readonly getSISUnifiedSync = {
    methodName: "getSISUnifiedSync",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetSISUnifiedSyncRequest,
    responseType: gateway_minga_pb.GetSISUnifiedSyncResponse,
  };

  static readonly triggerSISSync = {
    methodName: "triggerSISSync",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.TriggerSISSyncRequest,
    responseType: gateway_minga_pb.TriggerSISSyncResponse,
  };

  static readonly createMingas = {
    methodName: "createMingas",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.CreateMingasRequest,
    responseType: gateway_minga_pb.CreateMingasResponse,
  };

  static readonly readMingas = {
    methodName: "readMingas",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ReadMingasRequest,
    responseType: gateway_minga_pb.ReadMingasResponse,
  };

  static readonly updateMingas = {
    methodName: "updateMingas",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateMingasRequest,
    responseType: gateway_minga_pb.UpdateMingasResponse,
  };

  static readonly deleteMingas = {
    methodName: "deleteMingas",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.DeleteMingasRequest,
    responseType: gateway_minga_pb.DeleteMingasResponse,
  };

  static readonly readUserMinga = {
    methodName: "readUserMinga",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ReadUserMingaRequest,
    responseType: gateway_minga_pb.ReadUserMingaResponse,
  };

  static readonly updateMingaProfile = {
    methodName: "updateMingaProfile",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateMingaProfileRequest,
    responseType: gateway_minga_pb.UpdateMingaProfileResponse,
  };

  static readonly updateMingaImage = {
    methodName: "updateMingaImage",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateMingaImageRequest,
    responseType: gateway_minga_pb.UpdateMingaImageResponse,
  };

  static readonly addMingaLink = {
    methodName: "addMingaLink",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.AddMingaLinkRequest,
    responseType: gateway_minga_pb.AddMingaLinkResponse,
  };

  static readonly updateMingaLink = {
    methodName: "updateMingaLink",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateMingaLinkRequest,
    responseType: gateway_minga_pb.UpdateMingaLinkResponse,
  };

  static readonly removeMingaLink = {
    methodName: "removeMingaLink",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.RemoveMingaLinkRequest,
    responseType: gateway_minga_pb.RemoveMingaLinkResponse,
  };

  static readonly PauseMinga = {
    methodName: "PauseMinga",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.PauseMingaRequest,
    responseType: gateway_minga_pb.PauseMingaResponse,
  };

  static readonly GetPublicMingaCampInfo = {
    methodName: "GetPublicMingaCampInfo",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetPublicMingaCampInfoRequest,
    responseType: gateway_minga_pb.GetPublicMingaCampInfoResponse,
  };

  static readonly UpdateUserMingaFeatureToggles = {
    methodName: "UpdateUserMingaFeatureToggles",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateUserMingaFeatureTogglesRequest,
    responseType: gateway_minga_pb.UpdateUserMingaFeatureTogglesResponse,
  };

  static readonly GetMingaFeatureToggles = {
    methodName: "GetMingaFeatureToggles",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaFeatureTogglesRequest,
    responseType: gateway_minga_pb.GetMingaFeatureTogglesResponse,
  };

  static readonly UpdateMingaFeatureToggles = {
    methodName: "UpdateMingaFeatureToggles",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateMingaFeatureTogglesRequest,
    responseType: gateway_minga_pb.UpdateMingaFeatureTogglesResponse,
  };

  static readonly UpdateMingaSetting = {
    methodName: "UpdateMingaSetting",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateMingaSettingRequest,
    responseType: gateway_minga_pb.UpdateMingaSettingResponse,
  };

  static readonly GetMingaSettings = {
    methodName: "GetMingaSettings",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaSettingsRequest,
    responseType: gateway_minga_pb.GetMingaSettingsResponse,
  };

  static readonly UpdateMingaStudentIdImage = {
    methodName: "UpdateMingaStudentIdImage",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateMingaStudentIdImageRequest,
    responseType: gateway_minga_pb.UpdateMingaStudentIdImageResponse,
  };

  static readonly RemoveMingaStudentIdImage = {
    methodName: "RemoveMingaStudentIdImage",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.RemoveMingaStudentIdImageRequest,
    responseType: gateway_minga_pb.RemoveMingaStudentIdImageResponse,
  };

  static readonly AddFiles = {
    methodName: "AddFiles",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.CreateFilesRequest,
    responseType: gateway_minga_pb.MingaFilesResponse,
  };

  static readonly GetFiles = {
    methodName: "GetFiles",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.MingaFilesRequest,
    responseType: gateway_minga_pb.MingaFilesResponse,
  };

  static readonly RemoveFile = {
    methodName: "RemoveFile",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.RemoveFileRequest,
    responseType: gateway_minga_pb.MingaFilesResponse,
  };

  static readonly GetMingaDashboard2 = {
    methodName: "GetMingaDashboard2",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.MingaDashboardRequest2,
    responseType: gateway_minga_pb.MingaDashboardResponse2,
  };

  static readonly GetMingaReport = {
    methodName: "GetMingaReport",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaReportRequest,
    responseType: gateway_minga_pb.GetMingaReportResponse,
  };

  static readonly GetMingaReportSummary = {
    methodName: "GetMingaReportSummary",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaReportSummaryRequest,
    responseType: gateway_minga_pb.GetMingaReportSummaryResponse,
  };

  static readonly GetMingaDashboard = {
    methodName: "GetMingaDashboard",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.MingaDashboardRequest,
    responseType: gateway_minga_pb.MingaDashboardResponse,
  };

  static readonly GetUserMingaAccountInfo = {
    methodName: "GetUserMingaAccountInfo",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UserMingaAccountInfoRequest,
    responseType: gateway_minga_pb.UserMingaAccountInfoResponse,
  };

  static readonly SearchForMinga = {
    methodName: "SearchForMinga",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.SearchForMingaRequest,
    responseType: gateway_minga_pb.SearchForMingaResponse,
  };

  static readonly UpdateUserMingaSettings = {
    methodName: "UpdateUserMingaSettings",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpdateUserMingaSettingsRequest,
    responseType: gateway_minga_pb.UpdateUserMingaSettingsResponse,
  };

  static readonly ReadUserMingaJoinCode = {
    methodName: "ReadUserMingaJoinCode",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ReadUserMingaJoinCodeRequest,
    responseType: gateway_minga_pb.ReadUserMingaJoinCodeResponse,
  };

  static readonly ReadUserMingaGrades = {
    methodName: "ReadUserMingaGrades",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ReadUserMingaGradesRequest,
    responseType: gateway_minga_pb.ReadUserMingaGradesResponse,
  };

  static readonly CreateUserMingaJoinCode = {
    methodName: "CreateUserMingaJoinCode",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.CreateUserMingaJoinCodeRequest,
    responseType: gateway_minga_pb.CreateUserMingaJoinCodeResponse,
  };

  static readonly ChangeCustomJoinCode = {
    methodName: "ChangeCustomJoinCode",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ChangeCustomJoinCodeRequest,
    responseType: gateway_minga_pb.ChangeCustomJoinCodeResponse,
  };

  static readonly GetMingaSubscriptions = {
    methodName: "GetMingaSubscriptions",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.ReadMingaSubscriptionsRequest,
    responseType: gateway_minga_pb.ReadMingaSubscriptionsResponse,
  };

  static readonly GetActiveMingaSubscription = {
    methodName: "GetActiveMingaSubscription",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetActiveMingaSubscriptionRequest,
    responseType: gateway_minga_pb.GetActiveMingaSubscriptionResponse,
  };

  static readonly GetMingaPartners = {
    methodName: "GetMingaPartners",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaPartnersRequest,
    responseType: gateway_minga_pb.GetMingaPartnersResponse,
  };

  static readonly UpsertMingaPartner = {
    methodName: "UpsertMingaPartner",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpsertMingaPartnerRequest,
    responseType: gateway_minga_pb.UpsertMingaPartnerResponse,
  };

  static readonly GetMingaDistricts = {
    methodName: "GetMingaDistricts",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaDistrictsRequest,
    responseType: gateway_minga_pb.GetMingaDistrictsResponse,
  };

  static readonly DeleteMingaDistrict = {
    methodName: "DeleteMingaDistrict",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.DeleteMingaDistrictRequest,
    responseType: gateway_minga_pb.DeleteMingaDistrictResponse,
  };

  static readonly GetMingaDistrict = {
    methodName: "GetMingaDistrict",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaDistrictRequest,
    responseType: gateway_minga_pb.GetMingaDistrictResponse,
  };

  static readonly ExportDistricts = {
    methodName: "ExportDistricts",
    service: MingaManager,
    requestStream: false,
    responseStream: true,
    requestType: gateway_minga_pb.ExportDistrictsRequest,
    responseType: gateway_minga_pb.ExportDistrictsChunk,
  };

  static readonly UpsertMingaDistrict = {
    methodName: "UpsertMingaDistrict",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.UpsertMingaDistrictRequest,
    responseType: gateway_minga_pb.UpsertMingaDistrictResponse,
  };

  static readonly GenerateMingaSFTPGoUser = {
    methodName: "GenerateMingaSFTPGoUser",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GenerateMingaSFTPGoUserRequest,
    responseType: gateway_minga_pb.GenerateMingaSFTPGoUserResponse,
  };

  static readonly GetMingaStatsForPerson = {
    methodName: "GetMingaStatsForPerson",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetMingaStatsForPersonRequest,
    responseType: gateway_minga_pb.GetMingaStatsForPersonResponse,
  };

  static readonly GetStatsForStudent = {
    methodName: "GetStatsForStudent",
    service: MingaManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_pb.GetStatsForStudentRequest,
    responseType: gateway_minga_pb.GetStatsForStudentResponse,
  };

}

export class MingaManagerClient {
  resetGroups() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetPoints() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetAutomationCounters() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetGroupsMembers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetUserLists() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateSIS() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteSIS() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  activateRosteringMethod() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deactivateAllRosteringMethods() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getSIS() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getSISErrorDetails() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  testSISIntegration() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getSISSync() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getSISUnifiedSync() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  triggerSISSync() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createMingas() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  readMingas() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMingas() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteMingas() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  readUserMinga() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMingaProfile() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMingaImage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addMingaLink() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMingaLink() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeMingaLink() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  pauseMinga() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPublicMingaCampInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateUserMingaFeatureToggles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaFeatureToggles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMingaFeatureToggles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMingaSetting() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaSettings() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMingaStudentIdImage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeMingaStudentIdImage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addFiles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getFiles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeFile() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaDashboard2() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaReportSummary() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaDashboard() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getUserMingaAccountInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchForMinga() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateUserMingaSettings() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  readUserMingaJoinCode() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  readUserMingaGrades() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createUserMingaJoinCode() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  changeCustomJoinCode() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaSubscriptions() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getActiveMingaSubscription() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaPartners() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertMingaPartner() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaDistricts() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteMingaDistrict() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaDistrict() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportDistricts() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertMingaDistrict() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  generateMingaSFTPGoUser() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaStatsForPerson() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getStatsForStudent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
