import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import * as day from 'dayjs';
import { ReportTypes } from 'libs/domain';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { getDateRangeFromQueryParams } from '@shared/utils';

import { ReportsService } from './report-service.service';

@Injectable()
export abstract class ReportBaseAbstract implements OnDestroy {
  public range: UntypedFormGroup;

  protected _reportTypeSubject: BehaviorSubject<ReportTypes>;
  protected _destroyed = new ReplaySubject<void>(1);

  constructor(protected reportService?: ReportsService<any>) {}

  public ngOnDestroy(): void {
    this._reportTypeSubject.complete();
    this._destroyed.next();
    this._destroyed.complete();
  }

  public isReportType(type: ReportTypes): boolean {
    return this._reportTypeSubject.value === type;
  }

  public getReportType(): ReportTypes {
    return this._reportTypeSubject.value;
  }

  public exportReport() {
    if (this.reportService) {
      this.reportService.exportReport(this.getReportType());
    } else {
      throw new Error('Report service not found');
    }
  }

  protected _initializeDates(
    initialState,
    queryParams,
    destroyed$: ReplaySubject<void>,
    setRange: (
      range: { start: day.Dayjs; end: day.Dayjs },
      fromChangeEvent: boolean,
    ) => void,
  ) {
    const initialDates = getDateRangeFromQueryParams(queryParams, initialState);

    setRange(
      {
        start: initialDates.startDate,
        end: initialDates.endDate,
      },
      false,
    );

    this.range = initializeRange({
      start: {
        value: initialDates.startDate,
      },
      end: {
        value: initialDates.endDate,
      },
    });

    this.range.valueChanges.pipe(takeUntil(destroyed$)).subscribe(range => {
      setRange(range, true);
    });
  }
}
