export enum PointHistoryType {
  EVENT_CHECKIN = 'EVENT_CHECKIN',
  CUSTOM = 'CUSTOM',
  TEXT_CHALLENGE_RESPONSE = 'TEXT_CHALLENGE_RESPONSE',
  POLL_RESPONSE = 'POLL_RESPONSE',
  IMAGE_CHALLENGE_RESPONSE = 'IMAGE_CHALLENGE_RESPONSE',
  PRAISE = 'PRAISE',
  GUIDANCE = 'GUIDANCE',
  CHECKIN = 'CHECKIN',
  CONSEQUENCE = 'CONSEQUENCE',
}
