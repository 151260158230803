import { formatMinutesIntoHours } from 'libs/domain';

import { ExportNameAccessorFn, ExportNameType } from '../types';
import { nameFinder } from './common.utils';

export const assignedToAccessorFn: ExportNameAccessorFn = (
  item: any,
  name?: ExportNameType,
) => {
  if (item?.person) return nameFinder(item.person, name);
  if (item?.recipientPersonViewMinimal)
    return nameFinder(item.recipientPersonViewMinimal, name);
  return '';
};

export const totalTimeAccessorFn = (item: any) => {
  return (
    formatMinutesIntoHours(
      item?.totalTime ||
        item?.stats?.totalTime ||
        item?.expiredStats?.totalExpiredTime,
    ) || '0'
  );
};
