import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { INotificationType, SisSyncLogErrorType } from 'libs/domain';

import { PeopleRosteringService } from '../../modules/people/components/people-rostering/services/people-rostering.service';

@Injectable({ providedIn: 'root' })
export class RosteringNotificationHandlerService {
  constructor(
    public store: Store<any>,
    private _rosterService: PeopleRosteringService,
  ) {}

  public async handleNotification() {
    this._rosterService.handleViewError(SisSyncLogErrorType.MASS_DELETE);
  }

  public isRosteringNotification(type: string): boolean {
    return type === INotificationType.SIS_MASS_DELETE;
  }
}
