import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { BaseShortCardClass } from '../BaseShortCardClass';

export interface IMgInspirationShortCardElementProperties {
  pinned: boolean;
  content?: gateway.content_views_pb.ShortInspirationCardView.AsObject;
  context: string;
}

@Component({
  selector: 'mg-inspiration-short-card',
  templateUrl: './MgInspirationShortCard.element.html',
  styleUrls: ['./MgInspirationShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgInspirationShortCardElement extends BaseShortCardClass {
  @Input()
  pinned: boolean = false;

  @Input()
  content?: gateway.content_views_pb.ShortInspirationCardView.AsObject;

  @Input()
  markKeywords: string = '';

  @Input()
  context: string = '';

  @Input()
  commentRoles: string[] | null = null;

  constructor() {
    super();
  }

  protected calcBannerSize(): number {
    if (this.content && this.content.featuredImage) {
      const sizeMap = this.content.featuredImage.sizeMap;
      if (sizeMap && sizeMap[0]) {
        const size = sizeMap[0][1];
        if (size && size.height && size.width) {
          return (size.height / size.width) * 100;
        }
      }
    }

    return 0;
  }
}
