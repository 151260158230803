import { FlexStaffStats } from 'libs/domain';
import { flex_time_stats_pb as flex_stats_pb } from 'libs/generated-grpc-ts';

import { IReportPersonMapper } from '../person';

export const toProto = (item: FlexStaffStats) => {
  const msg = new flex_stats_pb.FlexStaffStats();
  const staff = IReportPersonMapper.toProto(item.person);
  msg.setStaff(staff);
  msg.setActivities(item.activities);
  msg.setTotalTime(item.totalTime);
  msg.setAssigned(item.assigned);
  msg.setRegistered(item.registered);
  msg.setCheckIns(item.checkIns);
  return msg;
};

export const fromProto = (
  msg: flex_stats_pb.FlexStaffStats,
): FlexStaffStats => {
  const staff = msg.getStaff();
  const person = IReportPersonMapper.fromProto(staff);
  return {
    person,
    activities: msg.getActivities(),
    totalTime: msg.getTotalTime(),
    assigned: msg.getAssigned(),
    registered: msg.getRegistered(),
    checkIns: msg.getCheckIns(),
  };
};
