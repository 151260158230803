import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, Subject } from 'rxjs';

import { FiltersFormPopoverComponent } from '../components/filters-form-popover/filters-form-popover.component';
import { FiltersFormData } from '../types';

export class FilterFormOverlay {
  // Ref

  private _ref: OverlayRef;

  // State

  private readonly _isOpenSubject = new BehaviorSubject<boolean>(false);
  public readonly isOpen$ = this._isOpenSubject.asObservable();

  /** Utility constructor */
  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  public open(options: {
    triggerElement: ElementRef;
    fields: string[];
    data: FiltersFormData;
    formGroup: UntypedFormGroup;
    resetSubject: Subject<void>;
    focusFieldOnInit?: string;
  }) {
    this._ref = this._overlay.create({
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    this._updatePositionStrategy(options.triggerElement);

    const componentRef = this._ref.attach(
      new ComponentPortal<FiltersFormPopoverComponent>(
        FiltersFormPopoverComponent,
        this._viewContainerRef,
      ),
    );

    // Component inputs

    componentRef.instance.data = options.data;
    componentRef.instance.formGroup = options.formGroup;
    componentRef.instance.fields = options.fields;
    if (options?.focusFieldOnInit) {
      componentRef.instance.focusFieldOnInit = options.focusFieldOnInit;
    }

    // Component outputs

    componentRef.instance.closePopover.subscribe(() => this._close());
    componentRef.instance.resetForm.subscribe(() =>
      options.resetSubject.next(),
    );

    this._isOpenSubject.next(true);

    this._ref.backdropClick().subscribe(() => this._close());
  }

  private _close() {
    this._ref?.dispose();
    this._isOpenSubject.next(false);
  }

  private _updatePositionStrategy(triggerElement: ElementRef) {
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(triggerElement)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);
    this._ref.updatePositionStrategy(positionStrategy);
  }
}
