import { Injectable } from '@angular/core';

import { hall_pass_pb } from 'libs/generated-grpc-web';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HallPassEvents {
  private _onHallPassCreateSuccess: Subject<
    hall_pass_pb.HallPassWithType.AsObject[]
  >;

  constructor() {
    this._onHallPassCreateSuccess = new Subject();
  }

  get onHallPassCreateSuccess(): Observable<
    hall_pass_pb.HallPassWithType.AsObject[]
  > {
    return this._onHallPassCreateSuccess.asObservable();
  }

  emitHallPassCreateSuccess(passes: hall_pass_pb.HallPassWithType.AsObject[]) {
    this._onHallPassCreateSuccess.next(passes);
  }
}
