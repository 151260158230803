import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injectable,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlValueAccessor,
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

import { gateway } from 'libs/generated-grpc-web';
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const MG_PIN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PinComponent),
  multi: true,
};

const MG_PIN_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PinComponent),
  multi: true,
};

@Component({
  selector: 'mg-pin',
  providers: [MG_PIN_VALUE_ACCESSOR, MG_PIN_VALIDATOR],
  templateUrl: './Pin.component.html',
  styleUrls: ['./Pin.component.scss'],
})
export class PinComponent
  implements ControlValueAccessor, Validator, OnChanges, OnInit
{
  _formGroup: UntypedFormGroup;
  innerValue: number;
  _valid: boolean;
  @Input()
  disabled = false;
  @Input()
  required = false;
  onChange;
  onTouched;
  inputControl: UntypedFormControl;
  outerControl: UntypedFormControl;
  outerErrors: ValidationErrors;
  @ViewChild('pinInput', { static: true })
  pinInput: ElementRef;
  @Input()
  unique: boolean | string = false;
  @Input()
  error: string = '';
  @Input()
  hint: string = '';
  @Input()
  placeholder?: string;
  @Input()
  hiddenRequired: boolean = false;
  @Input()
  readonly: boolean = true;
  @Input()
  mgNoHintMargin: boolean = false;

  private _uniqueValidator: AsyncValidatorFn;

  constructor(
    private personValidation: gateway.people_ng_grpc_pb.PersonValidation,
    private inj: Injector,
  ) {
    this.outerControl = new UntypedFormControl();
    this._setupUniqueValidator();
  }

  // Pass through our internal
  validate(control: AbstractControl): ValidationErrors | null {
    return this.inputControl.errors;
  }

  ngOnInit() {
    this.inputControl = new UntypedFormControl(
      { value: this.innerValue, disabled: this.disabled },
      [Validators.pattern(/^[0-9]*$/), Validators.max(2147483647)],
      // lookup for uniqueness
      this._getAsyncValidators(),
    );

    this._formGroup = new UntypedFormGroup({ inputControl: this.inputControl });

    this._initOuterControl();

    this.inputControl.statusChanges.subscribe(status => {
      // Angular does not know that the value has changed
      // from our component, so we need to update her with the new value.
      if (typeof this.onChange == 'function') {
        this.onChange(this.innerValue);
        this.onTouched();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.unique && this._formGroup) {
      let { currentValue } = changes.unique.currentValue;

      let validators = this._getAsyncValidators();

      this.inputControl.setAsyncValidators(validators);
    }
    if (changes.error) {
      this._showCustomError();
    }
  }

  private _showCustomError() {
    if (this.error && this.inputControl) {
      let error = {};
      error[this.error] = true;
      this.inputControl.setErrors(error);
    }
  }

  private _initOuterControl() {
    // Init outercontrol
    let outerControl = this.inj.get(NgControl);
    if (outerControl && outerControl.control) {
      this.outerControl = outerControl.control as UntypedFormControl;
    }
    // Check if the the outer formcontrol has errors and save them if we do
    this.outerControl.statusChanges.subscribe(val => {
      if (!!this.outerControl.errors && !!this.inputControl.errors == false) {
        this.outerErrors = this.outerControl.errors;
        // clear the errors if this status update has none
      } else if (!this.outerControl.errors && this.outerErrors) {
        this.outerErrors = null;
      }
    });
  }

  get inputRequired(): boolean {
    return this.required;
  }

  get inputControlErrorKey() {
    let innerErrors = this.inputControl.errors
      ? Object.keys(this.inputControl.errors)[0]
      : '';

    let outerErrors = this.outerControl.errors
      ? Object.keys(this.outerControl.errors)[0]
      : '';

    return innerErrors ? innerErrors : outerErrors;
  }

  get statusDone() {
    return (
      this.inputControl.dirty &&
      this.inputControl.valid &&
      (!this.outerControl ||
        (this.outerControl.dirty && this.outerControl.valid))
    );
  }

  get statusInvalid() {
    return (
      (this.inputControl.dirty && this.inputControl.invalid) ||
      !this.outerControl ||
      (this.outerControl.dirty && this.outerControl.invalid)
    );
  }

  get statusTouched() {
    return (
      (this.inputControl.invalid &&
        (this.inputControl.dirty || this.inputControl.touched)) ||
      (this.outerControl.invalid &&
        (this.outerControl.dirty || this.outerControl.touched))
    );
  }

  get statusPending() {
    return this.inputControl.pending || this.outerControl.pending;
  }

  wantsUnique() {
    return this.unique || typeof this.unique == 'number';
  }

  private _getAsyncValidators(unique = this.wantsUnique()): AsyncValidatorFn[] {
    let validators: AsyncValidatorFn[] = [];
    if (unique) {
      validators.push(this._uniqueValidator);
    }

    return validators;
  }

  private _setupUniqueValidator() {
    this._uniqueValidator = (c: AbstractControl) => {
      const value = c.value;
      let req = new gateway.people_pb.PinUniqueRequest();

      req.setPin(value);

      if (typeof this.unique == 'number') {
        console.log('gonna return as value is: ' + value);
        if (this.unique == value || value >= 2147483648) {
          return Promise.resolve(null);
        }
      }

      return timer(1000).pipe(
        switchMap(() => {
          return this.personValidation.pinUnique(req).then(res => {
            let isUnique = res.getUnique();
            if (!isUnique) {
              return {
                pinTaken: true,
              };
            } else {
              return null;
            }
          });
        }),
      );
    };
  }

  writeValue(value: any): void {
    if (typeof value == 'number') {
      this.innerValue = value;

      // @NOTE: Explicitly not setting the outer control value because value is
      // coming from that control.
      this.inputControl.setValue(this.innerValue);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(value) {
    // convert value to numbers only
    value = this.ensureNumeric(value);
    // update internal value
    this.innerValue = value;

    if (this.onChange) {
      this.onChange(this.innerValue);
    }
  }

  // Strips non numeric chars to fix Safari allowing non numeric chars in number
  // input
  ensureNumeric(value) {
    return String(value).replace(/\D/g, '');
  }
}
