import {
  EditableHallPass,
  HallPassStatusEnum,
  IHallPass,
  IHallPassErrorType,
} from 'libs/domain';
import { PersonViewMinimal } from 'libs/generated-grpc-ts';
import { hall_pass_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { StudentScheduleMapper, HallPassTypeMapper } from '../';
import {
  asObjectToMessage,
  dateObjectToDateTimeMessage,
  dateTimeMessageToDateObject,
} from '../';

export const toProto = (pass: IHallPass): hall_pass_pb.HallPass => {
  const msg = new hall_pass_pb.HallPass();
  msg.setId(pass.id || 0);
  msg.setStartDateTime(dateObjectToDateTimeMessage(pass.startDate));
  msg.setTypeId(pass.hallPassTypeId);

  if (pass.recipientPersonViewMinimal) {
    msg.setRecipientPersonView(
      asObjectToMessage(
        pass.recipientPersonViewMinimal,
        new PersonViewMinimal(),
      ),
    );
  }
  if (pass.authorPersonViewMinimal) {
    msg.setAuthorPersonView(
      asObjectToMessage(pass.authorPersonViewMinimal, new PersonViewMinimal()),
    );
  }
  if (pass.approvedByPersonViewMinimal) {
    msg.setApprovedByPersonView(
      asObjectToMessage(
        pass.approvedByPersonViewMinimal,
        new PersonViewMinimal(),
      ),
    );
  }
  if (pass.endedByPersonViewMinimal) {
    msg.setEndedByPersonView(
      asObjectToMessage(pass.endedByPersonViewMinimal, new PersonViewMinimal()),
    );
  }
  if (pass.requestedByPersonViewMinimal) {
    msg.setRequestedByPersonView(
      asObjectToMessage(
        pass.requestedByPersonViewMinimal,
        new PersonViewMinimal(),
      ),
    );
  }

  if (pass.endDate) msg.setEndedTime(dateObjectToDateTimeMessage(pass.endDate));
  if (pass.hallPassType) {
    msg.setTypeInfo(HallPassTypeMapper.toProto(pass.hallPassType));
  }
  if (pass.expiredDate) {
    msg.setExpiredDateTime(dateObjectToDateTimeMessage(pass.expiredDate));
  }

  if (pass.note) {
    msg.setNote(pass.note);
  }
  if (pass.noteToRequester) {
    msg.setNoteToRequester(pass.noteToRequester);
  }

  if (pass.approvalDate) {
    msg.setApprovalDate(dateObjectToDateTimeMessage(pass.approvalDate));
  }
  if (pass.startDateString) msg.setStartDateString(pass.startDateString);
  if (pass.endDateString) msg.setEndDateString(pass.endDateString);
  if (pass.studentCreated) msg.setStudentCreated(pass.studentCreated);
  if (pass.deniedAt) {
    msg.setDeniedTime(dateObjectToDateTimeMessage(pass.deniedAt));
  }
  if (pass.deniedByPerson) msg.setDeniedByPerson(pass.deniedByPerson);
  if (pass.deniedByReason) msg.setDeniedByReason(pass.deniedByReason);
  if (pass.deniedByError) msg.setDeniedByError(pass.deniedByError);
  if (pass.isKiosk) msg.setIsKiosk(pass.isKiosk);
  if (pass.status) msg.setStatus(pass.status);
  if (pass.createdBy) msg.setCreatedBy(pass.createdBy);
  if (pass.currentClass?.length) {
    const sections = pass.currentClass.map(StudentScheduleMapper.toProto);
    msg.setCurrentClassesList(sections);
    // deprecated but for b/c
    msg.setCurrentClass(sections[0]);
  }

  return msg;
};

export const toProtoEditable = (
  pass: EditableHallPass,
): hall_pass_pb.EditableHallPass => {
  const msg = toProto(pass);
  const editable = new hall_pass_pb.EditableHallPass();
  editable.setHallPass(msg);
  if (pass.manuallyUpdatedAt) {
    editable.setManuallyUpdatedAt(
      dateObjectToDateTimeMessage(pass.manuallyUpdatedAt),
    );
  }
  return editable;
};

export const toHallPassWithTypeMessage = (
  hallPass: IHallPass,
): hall_pass_pb.HallPassWithType => {
  const item = new hall_pass_pb.HallPassWithType();
  const hallPassMessage = toProto(hallPass);
  item.setHallPass(hallPassMessage);

  if (hallPass.hallPassType) {
    const hallPassTypeMessage = HallPassTypeMapper.toProto(
      hallPass.hallPassType,
    );
    item.setHallPassType(hallPassTypeMessage);
  }

  // add student id info to specific fields for this message
  if (hallPass.recipientPersonId) {
    item.setRecipientStudentId(
      hallPassMessage.getRecipientPersonView()?.getStudentId(),
    );

    item.setRecipientStudentIdFilename(
      hallPassMessage.getRecipientPersonView()?.getProfileImageUrl(),
    );
  }
  return item;
};

export const fromProto = (msg: hall_pass_pb.HallPass): IHallPass => {
  const id = msg.getId();
  const hallPassTypeId = msg.getTypeId();
  const startDate = dateTimeMessageToDateObject(msg.getStartDateTime());

  const hallPass: IHallPass = {
    id,
    hallPassTypeId,
    startDate,
    studentCreated: msg.getStudentCreated(),
    startDateString: msg.getStartDateString(),
    endDateString: msg.getEndDateString(),
    isKiosk: msg.getIsKiosk(),
  };
  const currentClassList = msg.getCurrentClassesList();
  if (currentClassList?.length) {
    hallPass.currentClass = currentClassList.map(
      StudentScheduleMapper.fromProto,
    );
  }

  const recipientPerson = msg.getRecipientPersonView();
  if (recipientPerson) {
    hallPass.recipientPersonViewMinimal = recipientPerson.toObject();
  }

  const authorPerson = msg.getAuthorPersonView();
  if (authorPerson) {
    hallPass.authorPersonViewMinimal = authorPerson.toObject();
  }

  const approvedByPerson = msg.getApprovedByPersonView();
  if (approvedByPerson) {
    hallPass.approvedByPersonViewMinimal = approvedByPerson.toObject();
  }

  const endedByPerson = msg.getEndedByPersonView();
  if (endedByPerson) {
    hallPass.endedByPersonViewMinimal = endedByPerson.toObject();
  }
  const requestedByPerson = msg.getRequestedByPersonView();
  if (requestedByPerson) {
    hallPass.requestedByPersonViewMinimal = requestedByPerson.toObject();
  }

  const endDate = msg.getEndedTime();
  if (endDate) {
    hallPass.endDate = dateTimeMessageToDateObject(endDate);
  }

  const expDate = msg.getExpiredDateTime();
  if (expDate) {
    hallPass.expiredDate = dateTimeMessageToDateObject(expDate);
  }

  const deniedAt = msg.getDeniedTime();
  if (deniedAt) {
    hallPass.deniedAt = dateTimeMessageToDateObject(deniedAt);
  }

  const deniedByPerson = msg.getDeniedByPerson();
  if (deniedByPerson) {
    hallPass.deniedByPerson = deniedByPerson;
  }

  const deniedByReason = msg.getDeniedByReason();
  if (deniedByReason) {
    hallPass.deniedByReason = deniedByReason;
  }

  const deniedByError = msg.getDeniedByError();
  if (deniedByError) {
    hallPass.deniedByError =
      $enum(IHallPassErrorType).asValueOrThrow(deniedByError);
  }

  const passType = msg.getTypeInfo();
  if (passType) {
    hallPass.hallPassType = HallPassTypeMapper.fromProto(passType);
  }

  const note = msg.getNote();
  if (note) {
    hallPass.note = note;
  }

  const noteToRequester = msg.getNoteToRequester();
  if (noteToRequester) {
    hallPass.noteToRequester = noteToRequester;
  }

  const approvalDate = msg.getApprovalDate();
  if (approvalDate) {
    hallPass.approvalDate = dateTimeMessageToDateObject(approvalDate);
  }

  const status = msg.getStatus();
  if (status) {
    hallPass.status = $enum(HallPassStatusEnum).asValueOrThrow(status);
  }

  const createdBy = msg.getCreatedBy();
  if (createdBy) hallPass.createdBy = createdBy;

  return hallPass;
};

export const fromProtoEditable = (
  msg: hall_pass_pb.EditableHallPass,
): EditableHallPass => {
  const hallPass = fromProto(msg.getHallPass());
  const updatedAtProto = msg.getManuallyUpdatedAt();
  return {
    ...hallPass,
    manuallyUpdatedAt: updatedAtProto
      ? dateTimeMessageToDateObject(updatedAtProto)
      : undefined,
  };
};
