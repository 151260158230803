import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  ChallengeInfo,
  ChallengeInvitePeople,
  InviteChallengePeople,
} from '../../content/challenge/challenge_pb';
import {
  ContentInfoQuery,
  ContentSaveResult,
} from '../../content/common_pb';

import {
  Challenge as __Challenge,
} from '../../content/challenge/challenge_pb_service';

@Injectable({providedIn: 'root'})
export class Challenge {

  constructor(private _ngZone: NgZone) {
  }

  new(request: ChallengeInfo): Promise<ContentSaveResult>;
  new(request: ChallengeInfo, metadata: grpc.Metadata): Promise<ContentSaveResult>;
  new(request: ChallengeInfo, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;
  new(request: ChallengeInfo, metadata: grpc.Metadata, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;

  new(request: ChallengeInfo, arg1?: grpc.Metadata|((err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): Promise<ContentSaveResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ContentSaveResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Challenge.New, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  update(request: ChallengeInfo): Promise<ContentSaveResult>;
  update(request: ChallengeInfo, metadata: grpc.Metadata): Promise<ContentSaveResult>;
  update(request: ChallengeInfo, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;
  update(request: ChallengeInfo, metadata: grpc.Metadata, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;

  update(request: ChallengeInfo, arg1?: grpc.Metadata|((err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): Promise<ContentSaveResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ContentSaveResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Challenge.Update, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  invitePeople(request: InviteChallengePeople): Promise<ChallengeInfo>;
  invitePeople(request: InviteChallengePeople, metadata: grpc.Metadata): Promise<ChallengeInfo>;
  invitePeople(request: InviteChallengePeople, callback: (err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void): void;
  invitePeople(request: InviteChallengePeople, metadata: grpc.Metadata, callback: (err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void): void;

  invitePeople(request: InviteChallengePeople, arg1?: grpc.Metadata|((err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void): Promise<ChallengeInfo>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ChallengeInfo>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Challenge.InvitePeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getInvitedPeople(request: ContentInfoQuery): Promise<ChallengeInvitePeople>;
  getInvitedPeople(request: ContentInfoQuery, metadata: grpc.Metadata): Promise<ChallengeInvitePeople>;
  getInvitedPeople(request: ContentInfoQuery, callback: (err: any|null, response: ChallengeInvitePeople, metadata: grpc.Metadata) => void): void;
  getInvitedPeople(request: ContentInfoQuery, metadata: grpc.Metadata, callback: (err: any|null, response: ChallengeInvitePeople, metadata: grpc.Metadata) => void): void;

  getInvitedPeople(request: ContentInfoQuery, arg1?: grpc.Metadata|((err: any|null, response: ChallengeInvitePeople, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ChallengeInvitePeople, metadata: grpc.Metadata) => void): Promise<ChallengeInvitePeople>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ChallengeInvitePeople>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Challenge.GetInvitedPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getInfo(request: ContentInfoQuery): Promise<ChallengeInfo>;
  getInfo(request: ContentInfoQuery, metadata: grpc.Metadata): Promise<ChallengeInfo>;
  getInfo(request: ContentInfoQuery, callback: (err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void): void;
  getInfo(request: ContentInfoQuery, metadata: grpc.Metadata, callback: (err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void): void;

  getInfo(request: ContentInfoQuery, arg1?: grpc.Metadata|((err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ChallengeInfo, metadata: grpc.Metadata) => void): Promise<ChallengeInfo>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ChallengeInfo>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Challenge.GetInfo, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

