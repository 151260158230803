import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { SuccessDialog } from '@app/src/app/dialog';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';
import {
  ModerationOverrideSuccess,
  TypeEnum,
  TypeUnion,
} from '@app/src/app/store/root/rootActions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  GroupCollectionActions,
  GroupDetailsActions,
  GroupMemberActions,
} from '../actions';
import { Group, MingaGroupMemberRank } from '../models/Group';
import { GroupComposite } from '../models/GroupComposite';
import { GetGroupsResponse, GroupsService } from '../services/Groups.service';
import { GroupsFacadeService } from '../services/GroupsFacade';

const getRankFromGroup = (group: Group): MingaGroupMemberRank => {
  if (group.isPrivate) {
    return MingaGroupMemberRank.PENDING;
  } else {
    return MingaGroupMemberRank.MEMBER;
  }
};

@Injectable()
export class GroupCollectionEffects {
  constructor(
    private _actions$: Actions<
      | TypeUnion
      | GroupCollectionActions.TypeUnion
      | GroupDetailsActions.TypeUnion
    >,
    private _groupsService: GroupsService,
    private _groupsFacade: GroupsFacadeService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _groupsFacadeService: GroupsFacadeService,
    private _dialog: MatDialog,
    private _router: Router,
    private _store: Store<any>,
    private _authInfoService: AuthInfoService,
    private _settingService: MingaSettingsService,
  ) {}

  moderationOverrideSuccessInvalidate$: Observable<Action> =
    this._actions$.pipe(
      ofType(TypeEnum.ModerationOverrideSuccess),
      map((action: ModerationOverrideSuccess) => {
        return new GroupCollectionActions.InvalidateGroupsCollection();
      }),
    );

  /**
   * Send request to backend to join a group. Update details and group locally
   * and show success dialog if successful.
   */
  joinGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.JoinGroup),
      map((action: GroupMemberActions.JoinGroup) => {
        action.payload.rank = getRankFromGroup(action.payload);
        return action.payload;
      }),
      switchMap(group =>
        this._groupsService.addGroupToMyGroups(group).pipe(
          switchMap((groupComposite: GroupComposite) => {
            const actions: Action[] = [
              new GroupMemberActions.JoinGroupSuccess(groupComposite.group),
              new GroupDetailsActions.UpdateGroupDetailsLocally(
                groupComposite.groupDetails,
              ),
              new GroupCollectionActions.UpdateGroupLocally(
                groupComposite.group,
              ),
            ];
            // if they just joined a new school, lets update the whole groups
            // list.
            if (groupComposite.group.isParent) {
              actions.push(
                new GroupCollectionActions.InvalidateGroupsCollection(),
              );
              actions.push(new GroupCollectionActions.LoadAllGroupsInfo());
            }
            return actions;
          }),
          catchError(error =>
            of(
              new GroupMemberActions.JoinGroupFailure({
                message: error,
                hash: group.hash,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Send request to backend to join a group. Update details and group locally
   * and DO NOT show success dialog if successful.
   */
  joinGroupNoConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.JoinGroupNoConfirm),
      map((action: GroupMemberActions.JoinGroupNoConfirm) => {
        action.payload.rank = getRankFromGroup(action.payload);
        return action.payload;
      }),
      switchMap(group =>
        this._groupsService.addGroupToMyGroups(group).pipe(
          switchMap((groupComposite: GroupComposite) => {
            const actions: Action[] = [
              new GroupDetailsActions.UpdateGroupDetailsLocally(
                groupComposite.groupDetails,
              ),
              new GroupCollectionActions.UpdateGroupLocally(
                groupComposite.group,
              ),
            ];
            // if they just joined a new school, lets update the whole groups
            // list.
            if (groupComposite.group.isParent) {
              actions.push(
                new GroupCollectionActions.InvalidateGroupsCollection(),
              );
              actions.push(new GroupCollectionActions.LoadAllGroupsInfo());
            }
            return actions;
          }),
          catchError(error =>
            of(
              new GroupMemberActions.JoinGroupFailure({
                message: error,
                hash: group.hash,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Send request to backend to cancel joining a group
   */
  cancelJoinGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.CancelJoinGroup),
      map((action: GroupMemberActions.CancelJoinGroup) => action.payload),
      switchMap(group =>
        this._groupsService.cancelJoinGroup(group).pipe(
          map(
            g =>
              new GroupMemberActions.CancelJoinGroupSuccess(
                this._groupsFacade.removeRankFromGroup(g),
              ),
          ),
          catchError(error =>
            of(
              new GroupMemberActions.CancelJoinGroupFailure({
                message: error,
                hash: group.hash,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Send an action to remove the group member if they
   * request to leave.
   */
  leaveGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.LeaveGroup),
      switchMap((action: GroupMemberActions.LeaveGroup) => {
        const person = this._authInfoService.authPerson;
        return [
          new GroupMemberActions.RemoveGroupMember(
            action.payload,
            person.hash,
            action.noSuccessInterstitial,
          ),
        ];
      }),
    ),
  );

  leaveGroupSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupMemberActions.TypeEnum.RemoveGroupMemberSuccess),
        map(async (action: GroupMemberActions.RemoveGroupMemberSuccess) => {
          const group$ = this._groupsFacadeService.getGroup(
            action.payload.hash,
          );
          const group = await group$.pipe(first()).toPromise();
          if (!action.noSuccessInterstitial) {
            this._dialog.open(SuccessDialog, {
              data: { text: `You're no longer a member of ' ${group.name}!` },
            });
          }
          // update local group rank
          this._groupsFacade.removeRankFromGroup(group);

          // reload groups if this is a parent group.
          if (group && group.isParent) {
            this._store.dispatch(
              new GroupCollectionActions.InvalidateGroupsCollection(),
            );
            this._store.dispatch(
              new GroupCollectionActions.LoadAllGroupsInfo(),
            );
          }
          // go to groups list after leaving
          await this._router.navigate([
            '/groups/list',
            { outlets: { o: null } },
          ]);
        }),
      ),
    { dispatch: false },
  );

  /**
   * Loads all groups only if needed.
   */
  adhocLoadCollection$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupDetailsActions.TypeEnum.LoadGroupDetails),
      withLatestFrom(this._groupsFacade.getAllGroups()),
      filter(groups => groups[1].length === 0),
      map(() => new GroupCollectionActions.LoadGroupsCollection()),
    ),
  );

  groupMemberAcceptUpdate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.AcceptGroupMember),
      switchMap((action: GroupMemberActions.AcceptGroupMember) =>
        this._groupsService
          .acceptGroupMember(action.payload, action.memberPersonHash)
          .pipe(
            map(
              groupDetails =>
                new GroupMemberActions.AcceptGroupMemberSuccess(groupDetails),
            ),
            catchError(error =>
              of(
                new GroupMemberActions.AcceptGroupMemberFailure({
                  message: error,
                  hash: action.payload.hash,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  groupMemberDeclineUpdate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.DeclineGroupMember),
      switchMap((action: GroupMemberActions.DeclineGroupMember) =>
        this._groupsService
          .declineGroupMember(action.payload, action.memberPersonHash)
          .pipe(
            map(
              groupDetails =>
                new GroupMemberActions.DeclineGroupMemberSuccess(groupDetails),
            ),
            catchError(error =>
              of(
                new GroupMemberActions.DeclineGroupMemberFailure({
                  message: error,
                  hash: action.payload.hash,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  groupMemberRemoveUpdate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.RemoveGroupMember),
      switchMap((action: GroupMemberActions.RemoveGroupMember) =>
        this._groupsService
          .removeGroupMember(action.group, action.memberPersonHash)
          .pipe(
            map(
              groupDetails =>
                new GroupMemberActions.RemoveGroupMemberSuccess(
                  groupDetails,
                  action.noSuccessInterstitial,
                ),
            ),
            catchError(error =>
              of(
                new GroupMemberActions.RemoveGroupMemberFailure({
                  message: error,
                  hash: action.group.hash,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  groupMembersUpdate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupMemberActions.TypeEnum.UpdateGroupMembers),
        switchMap((action: GroupMemberActions.UpdateGroupMembers) =>
          this._groupsService
            .updateMembers(action.groupHash, action.memberUpdates)
            .pipe(
              map(groupDetails => {
                return new GroupMemberActions.UpdateGroupMembersSuccess(
                  groupDetails,
                );
              }),
              catchError(error =>
                of(
                  new GroupMemberActions.UpdateGroupMembersFailure({
                    message: error,
                    hash: action.groupHash,
                  }),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  updateGroupMembersSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupMemberActions.TypeEnum.UpdateGroupMembersSuccess),
        map((action: GroupMemberActions.UpdateGroupMembersSuccess) => {
          const dialog = this._dialog.open(SuccessDialog, {
            data: {
              text: `Members updated successfully!<br>Notifications have been sent.`,
            },
          });

          dialog.afterClosed().subscribe(result => {
            // close outlet then...
            this._router
              .navigate(['', { outlets: { o: null } }])
              .then(async () => {
                // go to view group details page,
                await this._router.navigate([
                  '/groups/view/' + action.payload.hash,
                ]);
              });
          });
        }),
      ),
    { dispatch: false },
  );

  addGroupMembers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.AddGroupMembers),
      switchMap((action: GroupMemberActions.AddGroupMembers) =>
        this._groupsService
          .addMembers(action.groupHash, action.memberHashes)
          .pipe(
            map(groupDetails => {
              return new GroupMemberActions.AddGroupMembersSuccess(
                groupDetails,
              );
            }),
            catchError(error =>
              of(
                new GroupMemberActions.AddGroupMembersFailure({
                  message: error,
                  hash: action.groupHash,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  removeGroupMembers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.RemoveGroupMembers),
      switchMap((action: GroupMemberActions.RemoveGroupMembers) =>
        this._groupsService
          .removeMembers(action.groupHash, action.memberHashes)
          .pipe(
            map(groupDetails => {
              return new GroupMemberActions.RemoveGroupMemberSuccess(
                groupDetails,
                true,
              );
            }),
            catchError(error =>
              of(
                new GroupMemberActions.RemoveGroupMemberFailure({
                  message: error,
                  hash: action.groupHash,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  /**
   * Load all groups for the Minga from backend.
   * Updates the store based on an expiry time, so the data never gets too
   * stale.
   */
  loadCollection$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupCollectionActions.TypeEnum.LoadGroupsCollection),
      withLatestFrom(this._settingService.isCommunityModuleEnabled()),
      map(([action, communityEnabled]) => ({ action, communityEnabled })),
      filter(data => data.communityEnabled),
      switchMap(data =>
        this._groupsService.getAllGroups().pipe(
          switchMap((payload: GetGroupsResponse) => [
            new GroupCollectionActions.LoadGroupsSuccess(
              payload.groups,
              this._groupsFacade.getExpiryTime(),
            ),
          ]),
          // see
          // https://medium.com/city-pantry/handling-errors-in-ngrx-effects-a95d918490d9
          // for a good explanation of the pitfalls to avoid with error handling
          // if we don't want to handle it by outputing a new action, we could
          // instead throwError(error);
          catchError(error =>
            of(
              new GroupCollectionActions.LoadGroupsFailure({
                message: `There was an error loading groups. Please try again later.`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadCollectionSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupCollectionActions.TypeEnum.LoadGroupsSuccess),
        map(() => {}),
      ),
    { dispatch: false },
  );

  loadDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupDetailsActions.TypeEnum.LoadGroupDetails),
      switchMap((action: GroupDetailsActions.LoadGroupDetails) => {
        return this._groupsService.getGroupDetails(action.groupHash).pipe(
          map(
            groupDetails =>
              new GroupDetailsActions.LoadGroupDetailsSuccess(groupDetails),
          ),
          catchError(err =>
            of(
              new GroupDetailsActions.LoadGroupDetailsFailure({ message: err }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * Dispatch load actions for groups and my groups,
   * if we don't have the data loaded yet or if
   * the data is stale.
   */
  loadAllGroupsInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroupCollectionActions.TypeEnum.LoadAllGroupsInfo),
      withLatestFrom(this._groupsFacadeService.getGroupsLoaded()),
      withLatestFrom(this._groupsFacadeService.getGroupsExpires()),
      withLatestFrom(this._settingService.isCommunityModuleEnabled()),
      switchMap(([[[action, groupsLoaded], expires], communityEnabled]) => {
        const actions = [];
        const date = new Date();
        if (communityEnabled && (!groupsLoaded || date.getTime() > expires)) {
          actions.push(new GroupCollectionActions.LoadGroupsCollection());
        }
        return actions;
      }),
    ),
  );

  deleteGroup$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupCollectionActions.TypeEnum.DeleteGroup),
        switchMap((action: GroupCollectionActions.DeleteGroup) => {
          return this._groupsService.deleteGroup(action.payload).pipe(
            map(
              () =>
                new GroupCollectionActions.DeleteGroupSuccess(action.payload),
            ),
            catchError(err =>
              of(
                new GroupCollectionActions.DeleteGroupFailure(
                  action.payload,
                  err,
                ),
              ),
            ),
          );
        }),
      ),
    { dispatch: false },
  );

  deleteGroupSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupCollectionActions.TypeEnum.DeleteGroupSuccess),
        map((action: GroupCollectionActions.DeleteGroupSuccess) => {
          this._systemAlertSnackBar.success('Successfully deleted group.');
        }),
      ),
    { dispatch: false },
  );

  /**
   * When group details are updated, make sure we update the main Group store
   * for that group as well, in case the current user's rank has changed.
   */
  updateGroupOnGroupDetailsUpdate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupDetailsActions.TypeEnum.LoadGroupDetailsSuccess),
        withLatestFrom(this._groupsFacade.getAllGroupsMap()),
        map(
          ([action, groupsMap]: [
            GroupDetailsActions.LoadGroupDetailsSuccess,
            Dictionary<Group>,
          ]) => {
            this._groupsFacadeService.updateGroupStoreFromGroupDetails(
              action.payload,
              groupsMap,
            );
          },
        ),
      ),
    { dispatch: false },
  );

  /**
   * Handle an error .
   */
  displaySnackBaronError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          GroupCollectionActions.TypeEnum.LoadGroupsFailure,
          GroupMemberActions.TypeEnum.JoinGroupFailure,
          GroupMemberActions.TypeEnum.AcceptGroupMemberFailure,
          GroupCollectionActions.TypeEnum.DeleteGroupFailure,
          GroupMemberActions.TypeEnum.DeclineGroupMemberFailure,
          GroupMemberActions.TypeEnum.UpdateGroupMembersFailure,
          GroupMemberActions.TypeEnum.AddGroupMembersFailure,
          GroupMemberActions.TypeEnum.RemoveGroupMemberFailure,
        ),
        map((action: GroupCollectionActions.LoadGroupsFailure) => {
          this._systemAlertSnackBar.error(action.payload.message);
        }),
      ),
    { dispatch: false },
  );

  /**
   * Fire off some visual cues to the user
   */
  joinGroupSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GroupMemberActions.TypeEnum.JoinGroupSuccess),
        map((action: GroupMemberActions.JoinGroupSuccess) => {
          if (action.payload.rank === MingaGroupMemberRank.PENDING) {
            this._systemAlertSnackBar.success(
              'We let the group owner know that you want to join!',
            );
          } else if (action.payload.rank === MingaGroupMemberRank.MEMBER) {
            this._dialog.open(SuccessDialog, {
              data: { text: `You're now a member of ${action.payload.name}!` },
            });
            this._router
              .navigate(['', { outlets: { search: null } }])
              .then(() =>
                this._router.navigate(['/groups/view/' + action.payload.hash]),
              );
          }
        }),
      ),
    { dispatch: false },
  );
}
