import { formatTime } from 'libs/util';

import { ColumnInfo } from '../types';
import {
  assignedToAccessorFn,
  gradeFinder,
  nameFinder,
  roleFinder,
  studentIdFinder,
  getHallPassNote,
} from '../utils';
import { NameColumnKeys, TemplateColumnKeys } from './common.constants';

export const firstName: ColumnInfo = {
  header: 'First Name',
  key: NameColumnKeys.FIRST_NAME,
  tooltip: true,
  accessorFn: (item: any) => {
    if (item.person) return nameFinder(item.person, 'first');
    if (item.issuedToPerson) return nameFinder(item.issuedToPerson, 'first');
    if (item.issuedTo) return nameFinder(item.issuedTo, 'first');
    if (item.issuedBy) return nameFinder(item.issuedBy, 'first');
    return '';
  },
  type: 'text',
  width: 'flex-medium',
  sort: true,
};

/** Note: using lastName will add an email column to the exported report */
export const lastName: ColumnInfo = {
  header: 'Last Name',
  key: NameColumnKeys.LAST_NAME,
  tooltip: true,
  accessorFn: (item: any) => {
    if (item.person) return nameFinder(item.person, 'last');
    if (item.issuedToPerson) return nameFinder(item.issuedToPerson, 'last');
    if (item.issuedTo) return nameFinder(item.issuedTo, 'last');
    if (item.issuedBy) return nameFinder(item.issuedBy, 'last');
    return '';
  },
  type: 'text',
  width: 'flex-medium',
  sort: true,
};

export const studentId: ColumnInfo = {
  header: 'ID Number',
  key: 'studentId',
  tooltip: true,
  accessorFn: (item: any) => {
    if (item?.person) return studentIdFinder(item.person);
    if (item?.issuedToPerson) return studentIdFinder(item.issuedToPerson);
    if (item?.issuedTo) return studentIdFinder(item.issuedTo);
    if (item?.recipientPersonViewMinimal) {
      return studentIdFinder(item.recipientPersonViewMinimal);
    }
    return '';
  },
  type: 'text',
  width: 'large',
};

export const staffId: ColumnInfo = {
  header: 'Staff ID',
  key: 'staffId',
  tooltip: true,
  hidden: true,
  accessorFn: (item: any) => {
    if (item?.createdBy) return studentIdFinder(item.createdBy);
    return '';
  },
  type: 'text',
  width: 'large',
};

export const grade: ColumnInfo = {
  header: 'Grade',
  key: 'grade',
  accessorFn: (item: any) => {
    if (item.person) return gradeFinder(item.person);
    if (item.issuedToPerson) return gradeFinder(item.issuedToPerson);
    if (item.issuedTo) return gradeFinder(item.issuedTo);
    return '';
  },
  type: 'text',
  width: 'small',
};

export const role: ColumnInfo = {
  header: 'Role',
  key: TemplateColumnKeys.ROLE,
  tooltip: true,
  accessorFn: (item: any, value?: boolean) => {
    if (item.person) return roleFinder(item.person, value);
    if (item.issuedToPerson) return roleFinder(item.issuedToPerson, value);
    if (item.issuedTo) return roleFinder(item.issuedTo, value);
    return '';
  },
  type: 'custom',
};

export const note: ColumnInfo = {
  header: 'Note',
  key: 'note',
  tooltip: true,
  type: 'text',
  width: 'small',
  accessorFn: (item: any) => item.note || '',
};

export const currentClass: ColumnInfo = {
  header: 'Class',
  key: 'currentClass',
  tooltip: true,
  type: 'text',
  width: 'small',
  accessorFn: (item: any) => {
    const { currentClass } = item;
    if (!currentClass?.length) return '';

    let tooltip = '';

    for (const classItem of currentClass) {
      if (tooltip.length) tooltip += '\n';
      tooltip += `Class: ${classItem.name || ''}`;

      if (classItem?.startTime) {
        tooltip += `\nStart time: ${formatTime(classItem?.startTime)}`;
      }

      if (classItem?.endTime) {
        tooltip += `\nEnd time: ${formatTime(classItem?.endTime)}`;
      }
      tooltip += '\n';
    }

    return tooltip;
  },
};

export const periodId: ColumnInfo = {
  header: 'Period ID',
  key: 'periodId',
  tooltip: true,
  type: 'text',
  width: 'flex-small',
  hidden: true,
  accessorFn: (item: any) => {
    const { currentClass } = item;
    if (!currentClass?.length) return '';

    let tooltip = '';

    for (const classItem of currentClass) {
      if (tooltip.length) tooltip += '\n';
      tooltip += `${classItem.periodId || ''}`;
      tooltip += '\n';
    }
    return tooltip;
  },
};

export const sectionId: ColumnInfo = {
  header: 'Section ID',
  key: 'sectionId',
  tooltip: true,
  type: 'text',
  width: 'flex-small',
  hidden: true,
  accessorFn: (item: any) => {
    const { currentClass } = item;
    if (!currentClass?.length) return '';

    let tooltip = '';

    for (const classItem of currentClass) {
      if (tooltip.length) tooltip += '\n';
      tooltip += `${classItem.sectionId || ''}`;
      tooltip += '\n';
    }
    return tooltip;
  },
};

export const sectionNumber: ColumnInfo = {
  header: 'Section Number',
  key: 'sectionNumber',
  tooltip: true,
  type: 'text',
  width: 'flex-small',
  hidden: true,
  accessorFn: (item: any) => {
    const { currentClass } = item;
    if (!currentClass?.length) return '';

    let tooltip = '';

    for (const classItem of currentClass) {
      if (tooltip.length) tooltip += '\n';
      tooltip += `${classItem.sectionNumber || ''}`;
      tooltip += '\n';
    }
    return tooltip;
  },
};

export const termId: ColumnInfo = {
  header: 'Term ID',
  key: 'termId',
  tooltip: true,
  type: 'text',
  width: 'flex-small',
  hidden: true,
  accessorFn: (item: any) => {
    const { currentClass } = item;
    if (!currentClass?.length) return '';

    let tooltip = '';

    for (const classItem of currentClass) {
      if (tooltip.length) tooltip += '\n';
      tooltip += `${classItem.termId || ''}`;
      tooltip += '\n';
    }
    return tooltip;
  },
};

export const schoolId: ColumnInfo = {
  header: 'School ID',
  key: 'schoolId',
  tooltip: true,
  type: 'text',
  width: 'flex-small',
  hidden: true,
  accessorFn: (item: any) => {
    const { currentClass } = item;
    if (!currentClass?.length) return '';

    let tooltip = '';

    for (const classItem of currentClass) {
      if (tooltip.length) tooltip += '\n';
      tooltip += `${classItem.schoolId || ''}`;
      tooltip += '\n';
    }
    return tooltip;
  },
};

export const noteAndRequesterNote: ColumnInfo = {
  header: 'Note',
  key: 'note',
  tooltip: true,
  type: 'text',
  width: 'small',
  accessorFn: (item: any) => getHallPassNote(item.note, item.noteToRequester),
};

export const assignedTo: ColumnInfo = {
  header: 'Assigned to First Name',
  key: NameColumnKeys.FIRST_NAME,
  tooltip: true,
  accessorFn: (item: any) => assignedToAccessorFn(item, 'first'),
  type: 'text',
  width: 'flex-medium',
  sort: true,
};

export const assignedToLast: ColumnInfo = {
  header: 'Assigned to Last Name',
  key: NameColumnKeys.LAST_NAME,
  tooltip: true,
  accessorFn: (item: any) => assignedToAccessorFn(item, 'last'),
  type: 'text',
  width: 'flex-medium',
  sort: true,
};
