// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as pbis_pbis_pb from '../pbis/pbis_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as google_protobuf_empty_pb from '../google/protobuf/empty_pb';

export class PbisManager {
  static readonly serviceName = "minga.pbis.PbisManager";

  static readonly GetType = {
    methodName: "GetType",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.GetTypeRequest,
    responseType: pbis_pbis_pb.GetTypeResponse,
  };

  static readonly GetTypes = {
    methodName: "GetTypes",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.GetTypesRequest,
    responseType: pbis_pbis_pb.GetTypesResponse,
  };

  static readonly UpdateType = {
    methodName: "UpdateType",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.UpdateTypeRequest,
    responseType: pbis_pbis_pb.UpdateTypeReponse,
  };

  static readonly DeleteType = {
    methodName: "DeleteType",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.DeleteTypeRequest,
    responseType: pbis_pbis_pb.DeleteTypeReponse,
  };

  static readonly AddDefaultPbisTypes = {
    methodName: "AddDefaultPbisTypes",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.AddDefaultPbisTypesRequest,
    responseType: pbis_pbis_pb.AddDefaultPbisTypesResponse,
  };

  static readonly CreateBehavior = {
    methodName: "CreateBehavior",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.CreateBehaviorRequest,
    responseType: pbis_pbis_pb.CreateBehaviorResponse,
  };

  static readonly UpdateConsequenceType = {
    methodName: "UpdateConsequenceType",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.UpdateConsequenceTypeRequest,
    responseType: pbis_pbis_pb.UpdateConsequenceTypeResponse,
  };

  static readonly DeleteConsequenceType = {
    methodName: "DeleteConsequenceType",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.DeleteConsequenceTypeRequest,
    responseType: pbis_pbis_pb.DeleteConsequenceTypeResponse,
  };

  static readonly GetConsequenceTypes = {
    methodName: "GetConsequenceTypes",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.GetConsequenceTypesRequest,
    responseType: pbis_pbis_pb.GetConsequenceTypesResponse,
  };

  static readonly CreateConsequences = {
    methodName: "CreateConsequences",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.CreateConsequenceRequest,
    responseType: pbis_pbis_pb.CreateConsequenceResponse,
  };

  static readonly GetBehavior = {
    methodName: "GetBehavior",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.GetBehaviorRequest,
    responseType: pbis_pbis_pb.GetBehaviorResponse,
  };

  static readonly DeleteBehavior = {
    methodName: "DeleteBehavior",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.DeleteBehaviorRequest,
    responseType: pbis_pbis_pb.DeleteBehaviorResponse,
  };

  static readonly DeleteConsequence = {
    methodName: "DeleteConsequence",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.DeleteConsequenceRequest,
    responseType: pbis_pbis_pb.DeleteBehaviorResponse,
  };

  static readonly CompleteConsequence = {
    methodName: "CompleteConsequence",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.CompleteConsequenceRequest,
    responseType: pbis_pbis_pb.CompleteConsequenceResponse,
  };

  static readonly GetStudentToolsBehaviors = {
    methodName: "GetStudentToolsBehaviors",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.GetStudentToolsBehaviorsRequest,
    responseType: pbis_pbis_pb.GetStudentToolsBehaviorsResponse,
  };

  static readonly ImportBehaviors = {
    methodName: "ImportBehaviors",
    service: PbisManager,
    requestStream: false,
    responseStream: true,
    requestType: pbis_pbis_pb.ImportBehaviorsRequest,
    responseType: pbis_pbis_pb.ImportBehaviorsResponse,
  };

  static readonly UpdateBehavior = {
    methodName: "UpdateBehavior",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.UpdateBehaviorRequest,
    responseType: pbis_pbis_pb.UpdateBehaviorResponse,
  };

  static readonly UpdateConsequence = {
    methodName: "UpdateConsequence",
    service: PbisManager,
    requestStream: false,
    responseStream: false,
    requestType: pbis_pbis_pb.UpdateConsequenceRequest,
    responseType: pbis_pbis_pb.UpdateConsequenceResponse,
  };

}

export class PbisManagerClient {
  getType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addDefaultPbisTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createBehavior() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateConsequenceType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteConsequenceType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getConsequenceTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createConsequences() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getBehavior() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteBehavior() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteConsequence() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  completeConsequence() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getStudentToolsBehaviors() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  importBehaviors() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateBehavior() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateConsequence() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
