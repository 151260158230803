import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { loadFontFamily } from '@app/src/app/util/font';
import { createHeadlineStyle } from '@app/src/app/util/headline';

function defaultHeadlineOptions(): gateway.banner_pb.Banner.HeadlineOptions.AsObject {
  return {
    offsetX: 0,
    offsetY: 0,
    anchor: 0,
    align: 0,
    fontFamily: '',
    fontColor: '',
    fontWeight: '',
    italic: false,
    lineHeight: 0,
    minFontSize: 0,
    maxFontSize: 0,
  };
}

@Component({
  selector: 'mg-banner-headline',
  templateUrl: './BannerHeadline.component.html',
  styleUrls: ['./BannerHeadline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerHeadlineComponent implements OnChanges {
  @Input()
  headlineOptions?: gateway.banner_pb.Banner.HeadlineOptions.AsObject;

  @Input()
  headlineMaxLength: number = 46;

  @Input()
  headlineContainer?: HTMLElement;

  get headlineContainerWidth(): number {
    if (this.headlineContainer) {
      return this.headlineContainer.clientWidth;
    }

    return 0;
  }

  get headlineStyle() {
    const textContent = this._element.nativeElement.textContent;
    const headlineOptions = this.headlineOptions || defaultHeadlineOptions();

    return createHeadlineStyle(textContent, headlineOptions, {
      maxLength: this.headlineMaxLength,
      containerWidth: this.headlineContainerWidth,
    });
  }

  constructor(private _element: ElementRef, private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this._loadBannerFontFamily();
  }

  @HostListener('window:resize', [])
  _onWindowResize() {
    this._cdr.markForCheck();
  }

  private _loadBannerFontFamily() {
    if (!this.headlineOptions) return;

    loadFontFamily(this.headlineOptions.fontFamily);
  }
}
