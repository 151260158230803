// source: automation/automation_actions.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as google_protobuf_empty_pb from '../google/protobuf/empty_pb';
export namespace AutomationAction {
  export interface AsObject {
    id: number;
    automationId: number;
    completeByDays?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    threshold: number;
    description: string;
    additionalBehaviors?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    repeatStep: boolean;
    repeatEvery?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    consequenceId?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    hallpassTypeId?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    pbisTypeId?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    consequenceToCompleteId?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    note: string;
    name: string;
    consequenceIconType: string;
    consequenceIconColor: string;
    consequenceName: string;
    enableNotes: boolean;
    consequenceCategoryId: number;
  }
}
export class AutomationAction extends jspb.Message {
  static readonly displayName = "AutomationAction";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AutomationAction.AsObject {
    return AutomationAction.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AutomationAction} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AutomationAction) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      automationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      completeByDays: (f = msg.getCompleteByDays()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      threshold: jspb.Message.getFieldWithDefault(msg, 4, 0),
      description: jspb.Message.getFieldWithDefault(msg, 5, ""),
      additionalBehaviors: (f = msg.getAdditionalBehaviors()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      repeatStep: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      repeatEvery: (f = msg.getRepeatEvery()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      consequenceId: (f = msg.getConsequenceId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      hallpassTypeId: (f = msg.getHallpassTypeId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      pbisTypeId: (f = msg.getPbisTypeId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      consequenceToCompleteId: (f = msg.getConsequenceToCompleteId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      note: jspb.Message.getFieldWithDefault(msg, 13, ""),
      name: jspb.Message.getFieldWithDefault(msg, 14, ""),
      consequenceIconType: jspb.Message.getFieldWithDefault(msg, 15, ""),
      consequenceIconColor: jspb.Message.getFieldWithDefault(msg, 16, ""),
      consequenceName: jspb.Message.getFieldWithDefault(msg, 17, ""),
      enableNotes: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      consequenceCategoryId: jspb.Message.getFieldWithDefault(msg, 19, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AutomationAction}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AutomationAction;
    return AutomationAction.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AutomationAction} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AutomationAction}
   */
  static deserializeBinaryFromReader(msg: AutomationAction, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setAutomationId(value2);
        break;
      case 3:
        var value3 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value3,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setCompleteByDays(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setThreshold(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setDescription(value5);
        break;
      case 6:
        var value6 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value6,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setAdditionalBehaviors(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setRepeatStep(value7);
        break;
      case 8:
        var value8 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value8,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setRepeatEvery(value8);
        break;
      case 9:
        var value9 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value9,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setConsequenceId(value9);
        break;
      case 10:
        var value10 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value10,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setHallpassTypeId(value10);
        break;
      case 11:
        var value11 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value11,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setPbisTypeId(value11);
        break;
      case 12:
        var value12 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value12,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setConsequenceToCompleteId(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setNote(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setName(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setConsequenceIconType(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.setConsequenceIconColor(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setConsequenceName(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableNotes(value18);
        break;
      case 19:
        var value19 = /** @type {number} */ (reader.readInt32());
        msg.setConsequenceCategoryId(value19);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AutomationAction} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AutomationAction, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getAutomationId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getCompleteByDays();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getThreshold();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getAdditionalBehaviors();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getRepeatStep();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getRepeatEvery();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getConsequenceId();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getHallpassTypeId();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getPbisTypeId();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getConsequenceToCompleteId();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getConsequenceIconType();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getConsequenceIconColor();
    if (f.length > 0) {
      writer.writeString(
        16,
        f
      );
    }
    f = message.getConsequenceName();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getEnableNotes();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getConsequenceCategoryId();
    if (f !== 0) {
      writer.writeInt32(
        19,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AutomationAction.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 automation_id = 2;
   * @return {number}
   */
  getAutomationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setAutomationId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional google.protobuf.Int32Value complete_by_days = 3;
   * @return {?Int32Value}
   */
  getCompleteByDays(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
  }


  /** @param {?Int32Value|undefined} value */
  setCompleteByDays(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCompleteByDays() {
    this.setCompleteByDays(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCompleteByDays(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional int32 threshold = 4;
   * @return {number}
   */
  getThreshold(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setThreshold(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string description = 5;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional google.protobuf.Int32Value additional_behaviors = 6;
   * @return {?Int32Value}
   */
  getAdditionalBehaviors(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
  }


  /** @param {?Int32Value|undefined} value */
  setAdditionalBehaviors(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAdditionalBehaviors() {
    this.setAdditionalBehaviors(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAdditionalBehaviors(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional bool repeat_step = 7;
   * @return {boolean}
   */
  getRepeatStep(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setRepeatStep(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional google.protobuf.Int32Value repeat_every = 8;
   * @return {?Int32Value}
   */
  getRepeatEvery(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
  }


  /** @param {?Int32Value|undefined} value */
  setRepeatEvery(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRepeatEvery() {
    this.setRepeatEvery(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRepeatEvery(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional google.protobuf.Int32Value consequence_id = 9;
   * @return {?Int32Value}
   */
  getConsequenceId(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
  }


  /** @param {?Int32Value|undefined} value */
  setConsequenceId(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequenceId() {
    this.setConsequenceId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequenceId(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional google.protobuf.Int32Value hallpass_type_id = 10;
   * @return {?Int32Value}
   */
  getHallpassTypeId(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
  }


  /** @param {?Int32Value|undefined} value */
  setHallpassTypeId(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallpassTypeId() {
    this.setHallpassTypeId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallpassTypeId(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional google.protobuf.Int32Value pbis_type_id = 11;
   * @return {?Int32Value}
   */
  getPbisTypeId(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
  }


  /** @param {?Int32Value|undefined} value */
  setPbisTypeId(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPbisTypeId() {
    this.setPbisTypeId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPbisTypeId(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional google.protobuf.Int32Value consequence_to_complete_id = 12;
   * @return {?Int32Value}
   */
  getConsequenceToCompleteId(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
  }


  /** @param {?Int32Value|undefined} value */
  setConsequenceToCompleteId(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequenceToCompleteId() {
    this.setConsequenceToCompleteId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequenceToCompleteId(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional string note = 13;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string name = 14;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string consequence_icon_type = 15;
   * @return {string}
   */
  getConsequenceIconType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setConsequenceIconType(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional string consequence_icon_color = 16;
   * @return {string}
   */
  getConsequenceIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
  };


  /** @param {string} value */
  setConsequenceIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 16, value);
  }


  /**
   * optional string consequence_name = 17;
   * @return {string}
   */
  getConsequenceName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setConsequenceName(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional bool enable_notes = 18;
   * @return {boolean}
   */
  getEnableNotes(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setEnableNotes(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * optional int32 consequence_category_id = 19;
   * @return {number}
   */
  getConsequenceCategoryId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
  };


  /** @param {number} value */
  setConsequenceCategoryId(value: number) {
    jspb.Message.setProto3IntField(this, 19, value);
  }


}
export namespace ListAutomationActionsRequest {
  export interface AsObject {
    automationId: number;
  }
}
export class ListAutomationActionsRequest extends jspb.Message {
  static readonly displayName = "ListAutomationActionsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListAutomationActionsRequest.AsObject {
    return ListAutomationActionsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListAutomationActionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListAutomationActionsRequest) {
    var f: any;
    var obj: any = {
      automationId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListAutomationActionsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListAutomationActionsRequest;
    return ListAutomationActionsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListAutomationActionsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListAutomationActionsRequest}
   */
  static deserializeBinaryFromReader(msg: ListAutomationActionsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setAutomationId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListAutomationActionsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListAutomationActionsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListAutomationActionsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 automation_id = 1;
   * @return {number}
   */
  getAutomationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setAutomationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace ListAutomationActionsResponse {
  export interface AsObject {
    automationActionsList: AutomationAction.AsObject[];
  }
}
export class ListAutomationActionsResponse extends jspb.Message {
  static readonly displayName = "ListAutomationActionsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListAutomationActionsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListAutomationActionsResponse.AsObject {
    return ListAutomationActionsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListAutomationActionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListAutomationActionsResponse) {
    var f: any;
    var obj: any = {
      automationActionsList: jspb.Message.toObjectList(msg.getAutomationActionsList(),
      AutomationAction.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListAutomationActionsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListAutomationActionsResponse;
    return ListAutomationActionsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListAutomationActionsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListAutomationActionsResponse}
   */
  static deserializeBinaryFromReader(msg: ListAutomationActionsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new AutomationAction;
        reader.readMessage(value1,AutomationAction.deserializeBinaryFromReader);
        msg.addAutomationActions(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListAutomationActionsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListAutomationActionsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationActionsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        AutomationAction.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListAutomationActionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated AutomationAction automation_actions = 1;
   * @return {!Array<!AutomationAction>}
   */
  getAutomationActionsList(): AutomationAction[] {
    return /** @type{!Array<!AutomationAction>} */ (
      jspb.Message.getRepeatedWrapperField(this, AutomationAction, 1));
  }


  /** @param {!Array<!AutomationAction>} value */
  setAutomationActionsList(value?: AutomationAction[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!AutomationAction=} opt_value
   * @param {number=} opt_index
   * @return {!AutomationAction}
   */
  addAutomationActions(opt_value?: AutomationAction, opt_index?: number): AutomationAction {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, AutomationAction, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAutomationActionsList() {
    this.setAutomationActionsList([]);
  }


}
export namespace DeleteAutomationActionRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteAutomationActionRequest extends jspb.Message {
  static readonly displayName = "DeleteAutomationActionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteAutomationActionRequest.AsObject {
    return DeleteAutomationActionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteAutomationActionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteAutomationActionRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteAutomationActionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteAutomationActionRequest;
    return DeleteAutomationActionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteAutomationActionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteAutomationActionRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteAutomationActionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteAutomationActionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteAutomationActionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteAutomationActionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetAutomationActionRequest {
  export interface AsObject {
    automationId: number;
    id: number;
  }
}
export class GetAutomationActionRequest extends jspb.Message {
  static readonly displayName = "GetAutomationActionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetAutomationActionRequest.AsObject {
    return GetAutomationActionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetAutomationActionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetAutomationActionRequest) {
    var f: any;
    var obj: any = {
      automationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      id: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetAutomationActionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetAutomationActionRequest;
    return GetAutomationActionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetAutomationActionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetAutomationActionRequest}
   */
  static deserializeBinaryFromReader(msg: GetAutomationActionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setAutomationId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetAutomationActionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetAutomationActionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetAutomationActionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 automation_id = 1;
   * @return {number}
   */
  getAutomationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setAutomationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 id = 2;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace CreateAutomationActionRequest {
  export interface AsObject {
    automationAction?: AutomationAction.AsObject;
  }
}
export class CreateAutomationActionRequest extends jspb.Message {
  static readonly displayName = "CreateAutomationActionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateAutomationActionRequest.AsObject {
    return CreateAutomationActionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateAutomationActionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateAutomationActionRequest) {
    var f: any;
    var obj: any = {
      automationAction: (f = msg.getAutomationAction()) && AutomationAction.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateAutomationActionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateAutomationActionRequest;
    return CreateAutomationActionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateAutomationActionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateAutomationActionRequest}
   */
  static deserializeBinaryFromReader(msg: CreateAutomationActionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new AutomationAction;
        reader.readMessage(value1,AutomationAction.deserializeBinaryFromReader);
        msg.setAutomationAction(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateAutomationActionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateAutomationActionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationAction();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        AutomationAction.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateAutomationActionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional AutomationAction automation_action = 1;
   * @return {?AutomationAction}
   */
  getAutomationAction(): AutomationAction {
    return /** @type{?AutomationAction} */ (
      jspb.Message.getWrapperField(this, AutomationAction, 1));
  }


  /** @param {?AutomationAction|undefined} value */
  setAutomationAction(value?: AutomationAction) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAutomationAction() {
    this.setAutomationAction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAutomationAction(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateAutomationActionRequest {
  export interface AsObject {
    automationAction?: AutomationAction.AsObject;
  }
}
export class UpdateAutomationActionRequest extends jspb.Message {
  static readonly displayName = "UpdateAutomationActionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateAutomationActionRequest.AsObject {
    return UpdateAutomationActionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateAutomationActionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateAutomationActionRequest) {
    var f: any;
    var obj: any = {
      automationAction: (f = msg.getAutomationAction()) && AutomationAction.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateAutomationActionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateAutomationActionRequest;
    return UpdateAutomationActionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateAutomationActionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateAutomationActionRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateAutomationActionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new AutomationAction;
        reader.readMessage(value1,AutomationAction.deserializeBinaryFromReader);
        msg.setAutomationAction(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateAutomationActionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateAutomationActionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationAction();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        AutomationAction.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateAutomationActionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional AutomationAction automation_action = 1;
   * @return {?AutomationAction}
   */
  getAutomationAction(): AutomationAction {
    return /** @type{?AutomationAction} */ (
      jspb.Message.getWrapperField(this, AutomationAction, 1));
  }


  /** @param {?AutomationAction|undefined} value */
  setAutomationAction(value?: AutomationAction) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAutomationAction() {
    this.setAutomationAction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAutomationAction(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
