import { SectionType } from 'libs/domain';

import { PeopleViewProfileRoute } from '@modules/people/components/people-view-profile/constants';
import { PeopleRoute } from '@modules/people/types';
import { ToolsRoutes } from '@modules/tools/tools.types';

/**
 * Helper to get route to a students schedule within tools
 */
export const getToolsScheduleRoute = (scheduleType: SectionType): string[] => {
  const config = {
    [SectionType.FLEX]: ToolsRoutes.FLEX_TIME,
    [SectionType.BELL]: ToolsRoutes.SCHEDULE,
  };

  const type = config[scheduleType];

  if (!type) {
    throw new Error('Invalid schedule type');
  }

  return [ToolsRoutes.ROOT, type];
};

/**
 * Helper to get route to a students schedule within profile
 */
export const getProfileScheduleRoute = (
  scheduleType: SectionType,
  studentHash: string,
): string[] => {
  const config = {
    [SectionType.FLEX]: PeopleViewProfileRoute.FLEXTIME,
    [SectionType.BELL]: PeopleViewProfileRoute.SCHEDULE,
  };

  const type = config[scheduleType];

  if (!type) {
    throw new Error('Invalid schedule type');
  }

  return [PeopleRoute.ROOT, PeopleViewProfileRoute.PROFILE, studentHash, type];
};
