import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

/**
 * Simply displays the correct mini card based on the 'content' property.
 */
@Component({
  selector: 'mg-any-mini-card',
  templateUrl: './MgAnyMiniCard.element.html',
  styleUrls: ['./MgAnyMiniCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgAnyMiniCardElement {
  @Input()
  content?: gateway.feed_pb.ContentFeedItemView.AsObject;

  @Input()
  pinned: boolean = false;

  @Input()
  context: string = '';

  @Input()
  markKeywords: string = '';

  @HostBinding('attr.is-future-content')
  get isFutureContent(): boolean {
    if (this.content) {
      const now = Date.now();
      if (this.content.shortCard && this.content.shortCard.timestamp) {
        return this.content.shortCard.timestamp > now;
      } else if (
        this.content.shortPollCard &&
        this.content.shortPollCard.timestamp
      ) {
        return this.content.shortPollCard.timestamp > now;
      } else if (
        this.content.shortEventCard &&
        this.content.shortEventCard.startTimestamp
      ) {
        return this.content.shortEventCard.startTimestamp > now;
      } else if (
        this.content.shortInspirationCard &&
        this.content.shortInspirationCard.timestamp
      ) {
        return this.content.shortInspirationCard.timestamp > now;
      } else if (
        this.content.shortAnnoucementCard &&
        this.content.shortAnnoucementCard.timestamp
      ) {
        return this.content.shortAnnoucementCard.timestamp > now;
      } else if (
        this.content.shortFileCard &&
        this.content.shortFileCard.timestamp
      ) {
        return this.content.shortFileCard.timestamp > now;
      } else if (
        this.content.shortChallengeCard &&
        this.content.shortChallengeCard.timestamp
      ) {
        return this.content.shortChallengeCard.timestamp > now;
      } else if (
        this.content.shortSmsMessageCard &&
        this.content.shortSmsMessageCard.timestamp
      ) {
        return this.content.shortSmsMessageCard.timestamp > now;
      }
    }
    return false;
  }

  get basicContent() {
    if (!this.content) return null;
    return this.content.shortCard || null;
  }

  get pollContent() {
    if (!this.content) return null;
    return this.content.shortPollCard || null;
  }

  get eventContent() {
    if (!this.content) return null;
    return this.content.shortEventCard || null;
  }

  get fullGraphicContent() {
    if (!this.content) return null;
    return this.content.shortInspirationCard || null;
  }

  get announcementContent() {
    if (!this.content) return null;
    return this.content.shortAnnoucementCard || null;
  }

  get fileContent() {
    if (!this.content) return null;
    return this.content.shortFileCard || null;
  }

  get challengeContent() {
    if (!this.content) return null;
    return this.content.shortChallengeCard || null;
  }

  get smsMessageContent() {
    if (!this.content) return null;
    return this.content.shortSmsMessageCard || null;
  }
}
