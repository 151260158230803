import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserStorage } from '@app/src/app/services/UserStorage';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services';

import { OptionItem } from '@shared/components/form/components/form-grouped-select/form-grouped-select.types';

import {
  FORM_FIELDS,
  SavedPreferences,
} from '../constants/tt-my-class.constants';
import { ActionItem, CategoryType } from '../types/tt-my-class.types';

@Injectable()
export class MyClassPreferencesService implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _mingaHash = '';

  private _preferencesSubject = new ReplaySubject<SavedPreferences>(1);
  public preferences$ = this._preferencesSubject.asObservable();

  constructor(
    private _localStorage: UserStorage,
    private _mingaStore: MingaStoreFacadeService,
  ) {
    this._mingaStore
      .getMingaAsObservable()
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(minga => {
        if (minga) {
          this._mingaHash = minga.hash || '';
        }
      });

    // run this once to get the initial value in the replay subject
    this.get();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async get(): Promise<SavedPreferences> {
    const preferences = await this._localStorage.getItem<SavedPreferences>(
      this._getLocalStorageKey(),
    );

    const preferencesWithFallbacks =
      this._getPreferencesWithFallbacks(preferences);

    this._preferencesSubject.next(preferencesWithFallbacks);
    return preferencesWithFallbacks;
  }

  public async save(values: SavedPreferences): Promise<void> {
    if (!values) return;

    const preferences: SavedPreferences =
      this._getPreferencesWithFallbacks(values);

    this._preferencesSubject.next(preferences);

    await this._localStorage.setItem(this._getLocalStorageKey(), preferences);
  }

  public apply(opts: {
    actions: ActionItem[];
    preferences: SavedPreferences;
    form: UntypedFormGroup;
  }) {
    const { actions, preferences, form } = opts;
    if (!preferences) return;

    form.patchValue(preferences);

    const selectedAction = preferences[FORM_FIELDS.SELECTED_ACTION];
    if (selectedAction && actions.length > 0) {
      const selected = actions.find(
        a =>
          a.value === selectedAction.value &&
          a.assignmentType === selectedAction.assignmentType,
      );

      if (selected) {
        form.get(FORM_FIELDS.SELECTED_ACTION).setValue(selected);
      }
    }

    form.updateValueAndValidity();
  }

  private _getLocalStorageKey(): string {
    return `${this._mingaHash}:myclass:preferences`;
  }

  private _getPreferencesWithFallbacks(
    preferences: SavedPreferences,
  ): SavedPreferences {
    preferences = preferences || ({} as SavedPreferences);
    const selectedAction = preferences[FORM_FIELDS.SELECTED_ACTION]?.value
      ? {
          value: preferences[FORM_FIELDS.SELECTED_ACTION].value,
          assignmentType:
            preferences[FORM_FIELDS.SELECTED_ACTION].assignmentType,
        }
      : null;

    const listFilter = preferences[FORM_FIELDS.LIST_FILTER] || [];
    // for b/c reasons we need to check if the value is an array or not
    const lists: OptionItem[] = Array.isArray(listFilter)
      ? listFilter
      : [listFilter];

    return {
      [FORM_FIELDS.LIST_FILTER]: lists,
      [FORM_FIELDS.CATEGORY]:
        preferences[FORM_FIELDS.CATEGORY] || CategoryType.MOST_USED,
      [FORM_FIELDS.SELECTED_ACTION]: selectedAction,
      [FORM_FIELDS.HIDE_PICTURE_FILTER]:
        preferences[FORM_FIELDS.HIDE_PICTURE_FILTER] ?? false,
      [FORM_FIELDS.SORT_FIRST_NAME_FILTER]:
        preferences[FORM_FIELDS.SORT_FIRST_NAME_FILTER] ?? false,
      [FORM_FIELDS.PAGE_LAYOUT]: preferences[FORM_FIELDS.PAGE_LAYOUT] ?? 'grid',
      [FORM_FIELDS.ENABLED_SOUND]:
        preferences[FORM_FIELDS.ENABLED_SOUND] ?? false,
    };
  }
}
