import { MingaMinimalModel } from 'libs/domain';

export enum SwitchMingaMessages {
  MODAL_TITLE = 'Switch Minga',
}

export type SwitchMingaModalData = {
  modalTitle?: string;
  disableClose?: boolean;
};
export type SwitchMingaModalResponse = { minga?: MingaMinimalModel };
