import {
  BmReportsFilters,
  CheckinManagerReportFilters,
  FtmReportFilters,
  HpmReportsFilters,
  PmReportsFilters,
} from 'libs/domain';
import { GetReportFilterCase, ReportFilters, ReportTypes } from 'libs/domain';
import {
  ScheduledReport,
  ScheduledReportDeliveryType,
  ScheduledReportFilterMessage,
  ScheduledReportUnmapped,
} from 'libs/domain';
import { stats_report_pb as proto } from 'libs/generated-grpc-ts';
import { checkin_pb } from 'libs/generated-grpc-ts';
import { flex_time_stats_pb } from 'libs/generated-grpc-ts';
import { hall_pass_stats_pb } from 'libs/generated-grpc-ts';
import { pbis_stats_pb } from 'libs/generated-grpc-ts';
import { points_pb } from 'libs/generated-grpc-ts';
import { DayOfWeekEnum } from 'libs/util';
import { $enum } from 'ts-enum-util';

import { dateObjectToDateTimeMessage, dateTimeMessageToDateObject } from '../';
import {
  BehaviorFilterMapper,
  CheckInFilterMapper,
  FlexFilterMapper,
  HallPassFilterMapper,
  PointFilterMapper,
} from '../report_filters';

export const toProto = (report: ScheduledReport): proto.ScheduledReport => {
  const { msg, filterCase } = _toProtoShared(report);
  switch (filterCase) {
    case ReportFilters.HALL_PASS: {
      const filterMsg = HallPassFilterMapper.toProto(
        report.filters as HpmReportsFilters,
      );
      msg.setHallPassFilters(filterMsg);
      break;
    }
    case ReportFilters.POINTS: {
      const filterMsg = PointFilterMapper.toProto(
        report.filters as PmReportsFilters,
      );
      msg.setPointsFilters(filterMsg);
      break;
    }
    case ReportFilters.PBIS: {
      const filterMsg = BehaviorFilterMapper.toProto(
        report.filters as BmReportsFilters,
      );
      msg.setPbisFilters(filterMsg);
      break;
    }
    case ReportFilters.CHECK_IN: {
      const filterMsg = CheckInFilterMapper.toProto(
        report.filters as CheckinManagerReportFilters,
      );
      msg.setCheckInFilters(filterMsg);
      break;
    }
    case ReportFilters.FLEX: {
      const filterMsg = FlexFilterMapper.toProto(
        report.filters as FtmReportFilters,
      );
      msg.setFlexFilters(filterMsg);
      break;
    }
  }

  return msg;
};

export const toProtoUnmapped = (
  report: ScheduledReportUnmapped,
): proto.ScheduledReport => {
  const { msg, filterCase } = _toProtoShared(report);
  const isHP = (x: any): x is hall_pass_stats_pb.HallPassReportFilters =>
    filterCase === ReportFilters.HALL_PASS;
  const isP = (x: any): x is points_pb.PointsReportFilters =>
    filterCase === ReportFilters.POINTS;
  const isBM = (x: any): x is pbis_stats_pb.PbisReportFilters =>
    filterCase === ReportFilters.PBIS;
  const isCI = (x: any): x is checkin_pb.CheckInReportFilters =>
    filterCase === ReportFilters.CHECK_IN;
  const isFT = (x: any): x is flex_time_stats_pb.FlexReportFilters =>
    filterCase === ReportFilters.FLEX;
  const filterMsg = report.filters;
  if (isHP(filterMsg)) {
    msg.setHallPassFilters(filterMsg);
  } else if (isP(filterMsg)) {
    msg.setPointsFilters(filterMsg);
  } else if (isBM(filterMsg)) {
    msg.setPbisFilters(filterMsg);
  } else if (isCI(filterMsg)) {
    msg.setCheckInFilters(filterMsg);
  } else if (isFT(filterMsg)) {
    msg.setFlexFilters(filterMsg);
  } else {
    throw new Error('Invalid filter case');
  }
  return msg;
};

/**
 * Shared proto msg building for mapped and unmapped reports.
 *
 * @param report
 * @returns
 */
const _toProtoShared = (
  report: ScheduledReport | ScheduledReportUnmapped,
): { msg: proto.ScheduledReport; filterCase: ReportFilters } => {
  const msg = new proto.ScheduledReport();
  if (report.id) msg.setId(report.id);
  if (report.name) msg.setName(report.name);
  if (report.reportType) msg.setReportType(report.reportType);
  if (report.frequency) msg.setFrequency(report.frequency);
  if (report.time) msg.setTime(report.time);
  if (report.deliveryType?.length) msg.setDeliveryTypeList(report.deliveryType);
  if (report.email?.length) msg.setEmailList(report.email);
  if (report.sftpHost) msg.setSftpHost(report.sftpHost);
  if (report.sftpUserName) msg.setSftpUserName(report.sftpUserName);
  if (report.sftpPassword) msg.setSftpPassword(report.sftpPassword);
  if (report.day) msg.setDay(report.day);
  if (typeof report.active === 'boolean') msg.setActive(report.active);
  if (report.createdBy) msg.setCreatedBy(report.createdBy);
  if (report.createdById) msg.setCreatedById(report.createdById);
  if (report.createdAt) {
    msg.setCreatedAt(dateObjectToDateTimeMessage(report.createdAt));
  }
  const filterCase = GetReportFilterCase(report.reportType);
  return { msg, filterCase };
};

export const fromProto = (msg: proto.ScheduledReport): ScheduledReport => {
  const { report } = _fromProtoParts(msg);
  return report;
};

export const fromProtoUnmapped = (
  msg: proto.ScheduledReport,
): ScheduledReportUnmapped => {
  const { report, filterMessage } = _fromProtoParts(msg, false);
  if (!filterMessage) {
    throw new Error('Failed to get filters for scheduled report');
  }
  return { ...report, filters: filterMessage };
};

/**
 * Deconstruct proto msg into report and filter message. Assemble desired object
 * in one of the fromProto functions.
 *
 * @param msg
 * @param mapFilters
 * @returns
 */
const _fromProtoParts = (
  msg: proto.ScheduledReport,
  mapFilters = true,
): {
  report: ScheduledReport;
  filterMessage?: ScheduledReportFilterMessage;
} => {
  const reportType = $enum(ReportTypes).asValueOrThrow(msg.getReportType());
  if (reportType === ReportTypes.EVENT_PEOPLE) {
    throw new Error('Invalid report type for scheduled reports');
  }

  let filters: any = {};
  let filterMessage: ScheduledReportFilterMessage;
  const filterCase = msg.getFiltersCase();
  switch (filterCase) {
    case ReportFilters.HALL_PASS:
      filterMessage = msg.getHallPassFilters();
      if (mapFilters) filters = HallPassFilterMapper.fromProto(filterMessage);
      break;
    case ReportFilters.CHECK_IN:
      filterMessage = msg.getCheckInFilters();
      if (mapFilters) filters = CheckInFilterMapper.fromProto(filterMessage);
      break;
    case ReportFilters.PBIS:
      filterMessage = msg.getPbisFilters();
      if (mapFilters) filters = BehaviorFilterMapper.fromProto(filterMessage);
      break;
    case ReportFilters.POINTS:
      filterMessage = msg.getPointsFilters();
      if (mapFilters) filters = PointFilterMapper.fromProto(filterMessage);
      break;
    case ReportFilters.FLEX:
      filterMessage = msg.getFlexFilters();
      if (mapFilters) filters = FlexFilterMapper.fromProto(filterMessage);
      break;
    case ReportFilters.EVENT:
    default:
      throw new Error('Invalid filter case');
  }

  const day = msg.getDay();
  const report: ScheduledReport = {
    id: msg.getId(),
    name: msg.getName(),
    // @Todo ask for Jeff's help on this
    reportType: reportType as any,
    frequency: msg.getFrequency(),
    time: msg.getTime(),
    deliveryType: msg.getDeliveryTypeList() as ScheduledReportDeliveryType[],
    email: msg.getEmailList(),
    sftpHost: msg.getSftpHost(),
    sftpUserName: msg.getSftpUserName(),
    sftpPassword: msg.getSftpPassword(),
    day: day ? $enum(DayOfWeekEnum).asValueOrThrow(msg.getDay()) : undefined,
    filters,
    active: msg.getActive(),
    createdBy: msg.getCreatedBy(),
    createdById: msg.getCreatedById(),
    createdAt: msg.getCreatedAt()
      ? dateTimeMessageToDateObject(msg.getCreatedAt())
      : undefined,
  };
  return { report, filterMessage };
};
