import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import * as day from 'dayjs';

export const initializeRange = (opts?: {
  start?: {
    value?: day.Dayjs;
    validators?: ValidatorFn[];
  };
  end?: {
    value?: day.Dayjs;
    validators?: ValidatorFn[];
  };
}) => {
  const startValue =
    opts?.start?.value !== undefined
      ? opts?.start?.value
      : day().subtract(7, 'day');
  const endValue = opts?.end?.value !== undefined ? opts?.end?.value : day();

  const defaultValidators = [Validators.required];

  return new UntypedFormGroup({
    start: new UntypedFormControl(
      startValue,
      opts?.start?.validators || defaultValidators,
    ),
    end: new UntypedFormControl(endValue, opts?.end?.validators || defaultValidators),
  });
};
