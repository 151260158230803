// source: registration/registration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
import * as flex_time_flex_time_pb from '../flex_time/flex_time_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as people_collection_people_collection_pb from '../people_collection/people_collection_pb';
export namespace Registration {
  export interface AsObject {
    id: number;
    cannotUnregister: boolean;
    flex?: flex_time_flex_time_pb.FlexTimeActivityInstance.AsObject;
    personHash: string;
  }
}
export class Registration extends jspb.Message {
  static readonly displayName = "Registration";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[3]];


  /**
   * @return {Registration.RegistrationCase}
   */
  getRegistrationCase() {
    return /** @type {Registration.RegistrationCase} */(jspb.Message.computeOneofCase(this, Registration.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, Registration.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Registration.AsObject {
    return Registration.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Registration} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Registration) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      cannotUnregister: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      flex: (f = msg.getFlex()) && flex_time_flex_time_pb.FlexTimeActivityInstance.toObject(includeInstance, f),
      personHash: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Registration}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Registration;
    return Registration.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Registration} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Registration}
   */
  static deserializeBinaryFromReader(msg: Registration, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setCannotUnregister(value2);
        break;
      case 3:
        var value3 = new flex_time_flex_time_pb.FlexTimeActivityInstance;
        reader.readMessage(value3,flex_time_flex_time_pb.FlexTimeActivityInstance.deserializeBinaryFromReader);
        msg.setFlex(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Registration} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Registration, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getCannotUnregister();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getFlex();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        flex_time_flex_time_pb.FlexTimeActivityInstance.serializeBinaryToWriter
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Registration.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional bool cannot_unregister = 2;
   * @return {boolean}
   */
  getCannotUnregister(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setCannotUnregister(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional flex_time.FlexTimeActivityInstance flex = 3;
   * @return {?FlexTimeActivityInstance}
   */
  getFlex(): flex_time_flex_time_pb.FlexTimeActivityInstance {
    return /** @type{?FlexTimeActivityInstance} */ (
      jspb.Message.getWrapperField(this, flex_time_flex_time_pb.FlexTimeActivityInstance, 3));
  }


  /** @param {?FlexTimeActivityInstance|undefined} value */
  setFlex(value?: flex_time_flex_time_pb.FlexTimeActivityInstance) {
    jspb.Message.setOneofWrapperField(this, 3, Registration.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlex() {
    this.setFlex(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlex(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string person_hash = 4;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace Registration {
export enum RegistrationCase {
  REGISTRATION_NOT_SET = 0,
  FLEX = 3,
}
} // namespace Registration
export namespace RegisterRequest {
  export interface AsObject {
    personHash: string;
    activityInstanceId: number;
    type: string;
  }
}
export class RegisterRequest extends jspb.Message {
  static readonly displayName = "RegisterRequest";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[2]];


  /**
   * @return {RegisterRequest.IdCase}
   */
  getIdCase() {
    return /** @type {RegisterRequest.IdCase} */(jspb.Message.computeOneofCase(this, RegisterRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, RegisterRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RegisterRequest.AsObject {
    return RegisterRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RegisterRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RegisterRequest) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      activityInstanceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      type: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RegisterRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RegisterRequest;
    return RegisterRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RegisterRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RegisterRequest}
   */
  static deserializeBinaryFromReader(msg: RegisterRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setActivityInstanceId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setType(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RegisterRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RegisterRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RegisterRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 activity_instance_id = 2;
   * @return {number}
   */
  getActivityInstanceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setActivityInstanceId(value: number) {
    jspb.Message.setOneofField(this, 2, RegisterRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearActivityInstanceId() {
    jspb.Message.setOneofField(this, 2, RegisterRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActivityInstanceId(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string type = 3;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace RegisterRequest {
export enum IdCase {
  ID_NOT_SET = 0,
  ACTIVITY_INSTANCE_ID = 2,
}
} // namespace RegisterRequest
export namespace AssignRequest {
  export interface AsObject {
    personHashList: string[];
    activityInstanceId: number;
    type: string;
    canUnregister: boolean;
    bypassRestrictions: boolean;
  }
}
export class AssignRequest extends jspb.Message {
  static readonly displayName = "AssignRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[2]];


  /**
   * @return {AssignRequest.IdCase}
   */
  getIdCase() {
    return /** @type {AssignRequest.IdCase} */(jspb.Message.computeOneofCase(this, AssignRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AssignRequest.repeatedFields_, AssignRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AssignRequest.AsObject {
    return AssignRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AssignRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AssignRequest) {
    var f: any;
    var obj: any = {
      personHashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      activityInstanceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      type: jspb.Message.getFieldWithDefault(msg, 3, ""),
      canUnregister: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      bypassRestrictions: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AssignRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AssignRequest;
    return AssignRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AssignRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AssignRequest}
   */
  static deserializeBinaryFromReader(msg: AssignRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setActivityInstanceId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setType(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setCanUnregister(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setBypassRestrictions(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AssignRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AssignRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCanUnregister();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getBypassRestrictions();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AssignRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string person_hash = 1;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


  /**
   * optional int32 activity_instance_id = 2;
   * @return {number}
   */
  getActivityInstanceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setActivityInstanceId(value: number) {
    jspb.Message.setOneofField(this, 2, AssignRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearActivityInstanceId() {
    jspb.Message.setOneofField(this, 2, AssignRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActivityInstanceId(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string type = 3;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional bool can_unregister = 4;
   * @return {boolean}
   */
  getCanUnregister(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setCanUnregister(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional bool bypass_restrictions = 5;
   * @return {boolean}
   */
  getBypassRestrictions(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setBypassRestrictions(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


}
export namespace AssignRequest {
export enum IdCase {
  ID_NOT_SET = 0,
  ACTIVITY_INSTANCE_ID = 2,
}
} // namespace AssignRequest
export namespace RegisterResponse {
  export interface AsObject {
    registration?: Registration.AsObject;
    error: string;
  }
}
export class RegisterResponse extends jspb.Message {
  static readonly displayName = "RegisterResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RegisterResponse.AsObject {
    return RegisterResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RegisterResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RegisterResponse) {
    var f: any;
    var obj: any = {
      registration: (f = msg.getRegistration()) && Registration.toObject(includeInstance, f),
      error: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RegisterResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RegisterResponse;
    return RegisterResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RegisterResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RegisterResponse}
   */
  static deserializeBinaryFromReader(msg: RegisterResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Registration;
        reader.readMessage(value1,Registration.deserializeBinaryFromReader);
        msg.setRegistration(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setError(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RegisterResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RegisterResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRegistration();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Registration.serializeBinaryToWriter
      );
    }
    f = message.getError();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RegisterResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Registration registration = 1;
   * @return {?Registration}
   */
  getRegistration(): Registration {
    return /** @type{?Registration} */ (
      jspb.Message.getWrapperField(this, Registration, 1));
  }


  /** @param {?Registration|undefined} value */
  setRegistration(value?: Registration) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRegistration() {
    this.setRegistration(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRegistration(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string error = 2;
   * @return {string}
   */
  getError(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setError(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace AssignResponse {
  export interface AsObject {
    registrationList: Registration.AsObject[];
    errorList: restriction_restriction_pb.RestrictionError.AsObject[];
  }
}
export class AssignResponse extends jspb.Message {
  static readonly displayName = "AssignResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AssignResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AssignResponse.AsObject {
    return AssignResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AssignResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AssignResponse) {
    var f: any;
    var obj: any = {
      registrationList: jspb.Message.toObjectList(msg.getRegistrationList(),
      Registration.toObject, includeInstance),
      errorList: jspb.Message.toObjectList(msg.getErrorList(),
      restriction_restriction_pb.RestrictionError.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AssignResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AssignResponse;
    return AssignResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AssignResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AssignResponse}
   */
  static deserializeBinaryFromReader(msg: AssignResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Registration;
        reader.readMessage(value1,Registration.deserializeBinaryFromReader);
        msg.addRegistration(value1);
        break;
      case 2:
        var value2 = new restriction_restriction_pb.RestrictionError;
        reader.readMessage(value2,restriction_restriction_pb.RestrictionError.deserializeBinaryFromReader);
        msg.addError(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AssignResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AssignResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRegistrationList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Registration.serializeBinaryToWriter
      );
    }
    f = message.getErrorList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        restriction_restriction_pb.RestrictionError.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AssignResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Registration registration = 1;
   * @return {!Array<!Registration>}
   */
  getRegistrationList(): Registration[] {
    return /** @type{!Array<!Registration>} */ (
      jspb.Message.getRepeatedWrapperField(this, Registration, 1));
  }


  /** @param {!Array<!Registration>} value */
  setRegistrationList(value?: Registration[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Registration=} opt_value
   * @param {number=} opt_index
   * @return {!Registration}
   */
  addRegistration(opt_value?: Registration, opt_index?: number): Registration {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Registration, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRegistrationList() {
    this.setRegistrationList([]);
  }


  /**
   * repeated restriction.RestrictionError error = 2;
   * @return {!Array<!RestrictionError>}
   */
  getErrorList(): restriction_restriction_pb.RestrictionError[] {
    return /** @type{!Array<!RestrictionError>} */ (
      jspb.Message.getRepeatedWrapperField(this, restriction_restriction_pb.RestrictionError, 2));
  }


  /** @param {!Array<!RestrictionError>} value */
  setErrorList(value?: restriction_restriction_pb.RestrictionError[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!RestrictionError=} opt_value
   * @param {number=} opt_index
   * @return {!RestrictionError}
   */
  addError(opt_value?: restriction_restriction_pb.RestrictionError, opt_index?: number): restriction_restriction_pb.RestrictionError {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, restriction_restriction_pb.RestrictionError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorList() {
    this.setErrorList([]);
  }


}
export namespace RegisterToHomeroomRequest {
  export interface AsObject {
    personHashList: string[];
    periodId: number;
    type: string;
  }
}
export class RegisterToHomeroomRequest extends jspb.Message {
  static readonly displayName = "RegisterToHomeroomRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[2]];


  /**
   * @return {RegisterToHomeroomRequest.IdCase}
   */
  getIdCase() {
    return /** @type {RegisterToHomeroomRequest.IdCase} */(jspb.Message.computeOneofCase(this, RegisterToHomeroomRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RegisterToHomeroomRequest.repeatedFields_, RegisterToHomeroomRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RegisterToHomeroomRequest.AsObject {
    return RegisterToHomeroomRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RegisterToHomeroomRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RegisterToHomeroomRequest) {
    var f: any;
    var obj: any = {
      personHashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      periodId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      type: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RegisterToHomeroomRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RegisterToHomeroomRequest;
    return RegisterToHomeroomRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RegisterToHomeroomRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RegisterToHomeroomRequest}
   */
  static deserializeBinaryFromReader(msg: RegisterToHomeroomRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setPeriodId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setType(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RegisterToHomeroomRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RegisterToHomeroomRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RegisterToHomeroomRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string person_hash = 1;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


  /**
   * optional int32 period_id = 2;
   * @return {number}
   */
  getPeriodId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setPeriodId(value: number) {
    jspb.Message.setOneofField(this, 2, RegisterToHomeroomRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearPeriodId() {
    jspb.Message.setOneofField(this, 2, RegisterToHomeroomRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPeriodId(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string type = 3;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace RegisterToHomeroomRequest {
export enum IdCase {
  ID_NOT_SET = 0,
  PERIOD_ID = 2,
}
} // namespace RegisterToHomeroomRequest
export namespace RegisterToHomeroomResponse {
  export interface AsObject {
    registeredCount: number;
    errorList: restriction_restriction_pb.RestrictionError.AsObject[];
  }
}
export class RegisterToHomeroomResponse extends jspb.Message {
  static readonly displayName = "RegisterToHomeroomResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RegisterToHomeroomResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RegisterToHomeroomResponse.AsObject {
    return RegisterToHomeroomResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RegisterToHomeroomResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RegisterToHomeroomResponse) {
    var f: any;
    var obj: any = {
      registeredCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      errorList: jspb.Message.toObjectList(msg.getErrorList(),
      restriction_restriction_pb.RestrictionError.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RegisterToHomeroomResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RegisterToHomeroomResponse;
    return RegisterToHomeroomResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RegisterToHomeroomResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RegisterToHomeroomResponse}
   */
  static deserializeBinaryFromReader(msg: RegisterToHomeroomResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setRegisteredCount(value1);
        break;
      case 2:
        var value2 = new restriction_restriction_pb.RestrictionError;
        reader.readMessage(value2,restriction_restriction_pb.RestrictionError.deserializeBinaryFromReader);
        msg.addError(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RegisterToHomeroomResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RegisterToHomeroomResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRegisteredCount();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getErrorList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        restriction_restriction_pb.RestrictionError.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RegisterToHomeroomResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 registered_count = 1;
   * @return {number}
   */
  getRegisteredCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setRegisteredCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated restriction.RestrictionError error = 2;
   * @return {!Array<!RestrictionError>}
   */
  getErrorList(): restriction_restriction_pb.RestrictionError[] {
    return /** @type{!Array<!RestrictionError>} */ (
      jspb.Message.getRepeatedWrapperField(this, restriction_restriction_pb.RestrictionError, 2));
  }


  /** @param {!Array<!RestrictionError>} value */
  setErrorList(value?: restriction_restriction_pb.RestrictionError[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!RestrictionError=} opt_value
   * @param {number=} opt_index
   * @return {!RestrictionError}
   */
  addError(opt_value?: restriction_restriction_pb.RestrictionError, opt_index?: number): restriction_restriction_pb.RestrictionError {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, restriction_restriction_pb.RestrictionError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorList() {
    this.setErrorList([]);
  }


}
export namespace GetMyRegistrationsRequest {
  export interface AsObject {
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    type: string;
    personHash: string;
  }
}
export class GetMyRegistrationsRequest extends jspb.Message {
  static readonly displayName = "GetMyRegistrationsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMyRegistrationsRequest.AsObject {
    return GetMyRegistrationsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMyRegistrationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMyRegistrationsRequest) {
    var f: any;
    var obj: any = {
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      type: jspb.Message.getFieldWithDefault(msg, 3, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMyRegistrationsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMyRegistrationsRequest;
    return GetMyRegistrationsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMyRegistrationsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMyRegistrationsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMyRegistrationsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_date_pb.DateTime;
        reader.readMessage(value1,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setType(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMyRegistrationsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMyRegistrationsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMyRegistrationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.DateTime start_date = 1;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 1));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 2;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string type = 3;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string person_hash = 4;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace GetMyRegistrationsResponse {
  export interface AsObject {
    registrationList: Registration.AsObject[];
  }
}
export class GetMyRegistrationsResponse extends jspb.Message {
  static readonly displayName = "GetMyRegistrationsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMyRegistrationsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMyRegistrationsResponse.AsObject {
    return GetMyRegistrationsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMyRegistrationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMyRegistrationsResponse) {
    var f: any;
    var obj: any = {
      registrationList: jspb.Message.toObjectList(msg.getRegistrationList(),
      Registration.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMyRegistrationsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMyRegistrationsResponse;
    return GetMyRegistrationsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMyRegistrationsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMyRegistrationsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMyRegistrationsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Registration;
        reader.readMessage(value1,Registration.deserializeBinaryFromReader);
        msg.addRegistration(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMyRegistrationsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMyRegistrationsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRegistrationList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Registration.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMyRegistrationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Registration registration = 1;
   * @return {!Array<!Registration>}
   */
  getRegistrationList(): Registration[] {
    return /** @type{!Array<!Registration>} */ (
      jspb.Message.getRepeatedWrapperField(this, Registration, 1));
  }


  /** @param {!Array<!Registration>} value */
  setRegistrationList(value?: Registration[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Registration=} opt_value
   * @param {number=} opt_index
   * @return {!Registration}
   */
  addRegistration(opt_value?: Registration, opt_index?: number): Registration {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Registration, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRegistrationList() {
    this.setRegistrationList([]);
  }


}
export namespace GetRegisteredPeopleRequest {
  export interface AsObject {
    id: number;
    type: string;
    reason: number;
  }
}
export class GetRegisteredPeopleRequest extends jspb.Message {
  static readonly displayName = "GetRegisteredPeopleRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetRegisteredPeopleRequest.AsObject {
    return GetRegisteredPeopleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetRegisteredPeopleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetRegisteredPeopleRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      type: jspb.Message.getFieldWithDefault(msg, 2, ""),
      reason: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetRegisteredPeopleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetRegisteredPeopleRequest;
    return GetRegisteredPeopleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetRegisteredPeopleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetRegisteredPeopleRequest}
   */
  static deserializeBinaryFromReader(msg: GetRegisteredPeopleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setType(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setReason(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetRegisteredPeopleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetRegisteredPeopleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getReason();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetRegisteredPeopleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string type = 2;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 reason = 3;
   * @return {number}
   */
  getReason(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setReason(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace RegisteredPerson {
  export interface AsObject {
    person?: people_collection_people_collection_pb.PeopleCollectionPersonDetails.AsObject;
    cannotUnregister: boolean;
  }
}
export class RegisteredPerson extends jspb.Message {
  static readonly displayName = "RegisteredPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RegisteredPerson.AsObject {
    return RegisteredPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RegisteredPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RegisteredPerson) {
    var f: any;
    var obj: any = {
      person: (f = msg.getPerson()) && people_collection_people_collection_pb.PeopleCollectionPersonDetails.toObject(includeInstance, f),
      cannotUnregister: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RegisteredPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RegisteredPerson;
    return RegisteredPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RegisteredPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RegisteredPerson}
   */
  static deserializeBinaryFromReader(msg: RegisteredPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new people_collection_people_collection_pb.PeopleCollectionPersonDetails;
        reader.readMessage(value1,people_collection_people_collection_pb.PeopleCollectionPersonDetails.deserializeBinaryFromReader);
        msg.setPerson(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setCannotUnregister(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RegisteredPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RegisteredPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPerson();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        people_collection_people_collection_pb.PeopleCollectionPersonDetails.serializeBinaryToWriter
      );
    }
    f = message.getCannotUnregister();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RegisteredPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PeopleCollectionPersonDetails person = 1;
   * @return {?PeopleCollectionPersonDetails}
   */
  getPerson(): people_collection_people_collection_pb.PeopleCollectionPersonDetails {
    return /** @type{?PeopleCollectionPersonDetails} */ (
      jspb.Message.getWrapperField(this, people_collection_people_collection_pb.PeopleCollectionPersonDetails, 1));
  }


  /** @param {?PeopleCollectionPersonDetails|undefined} value */
  setPerson(value?: people_collection_people_collection_pb.PeopleCollectionPersonDetails) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPerson() {
    this.setPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPerson(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool cannot_unregister = 2;
   * @return {boolean}
   */
  getCannotUnregister(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setCannotUnregister(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace GetRegisteredPeopleResponse {
  export interface AsObject {
    personsList: RegisteredPerson.AsObject[];
    checkedInHashesList: string[];
  }
}
export class GetRegisteredPeopleResponse extends jspb.Message {
  static readonly displayName = "GetRegisteredPeopleResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetRegisteredPeopleResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetRegisteredPeopleResponse.AsObject {
    return GetRegisteredPeopleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetRegisteredPeopleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetRegisteredPeopleResponse) {
    var f: any;
    var obj: any = {
      personsList: jspb.Message.toObjectList(msg.getPersonsList(),
      RegisteredPerson.toObject, includeInstance),
      checkedInHashesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetRegisteredPeopleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetRegisteredPeopleResponse;
    return GetRegisteredPeopleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetRegisteredPeopleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetRegisteredPeopleResponse}
   */
  static deserializeBinaryFromReader(msg: GetRegisteredPeopleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new RegisteredPerson;
        reader.readMessage(value1,RegisteredPerson.deserializeBinaryFromReader);
        msg.addPersons(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addCheckedInHashes(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetRegisteredPeopleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetRegisteredPeopleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        RegisteredPerson.serializeBinaryToWriter
      );
    }
    f = message.getCheckedInHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetRegisteredPeopleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated RegisteredPerson persons = 1;
   * @return {!Array<!RegisteredPerson>}
   */
  getPersonsList(): RegisteredPerson[] {
    return /** @type{!Array<!RegisteredPerson>} */ (
      jspb.Message.getRepeatedWrapperField(this, RegisteredPerson, 1));
  }


  /** @param {!Array<!RegisteredPerson>} value */
  setPersonsList(value?: RegisteredPerson[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!RegisteredPerson=} opt_value
   * @param {number=} opt_index
   * @return {!RegisteredPerson}
   */
  addPersons(opt_value?: RegisteredPerson, opt_index?: number): RegisteredPerson {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, RegisteredPerson, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonsList() {
    this.setPersonsList([]);
  }


  /**
   * repeated string checked_in_hashes = 2;
   * @return {!Array<string>}
   */
  getCheckedInHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setCheckedInHashesList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addCheckedInHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCheckedInHashesList() {
    this.setCheckedInHashesList([]);
  }


}
export namespace DeleteRegistrationRequest {
  export interface AsObject {
    personHashList: string[];
    id: number;
    type: string;
  }
}
export class DeleteRegistrationRequest extends jspb.Message {
  static readonly displayName = "DeleteRegistrationRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DeleteRegistrationRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteRegistrationRequest.AsObject {
    return DeleteRegistrationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteRegistrationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteRegistrationRequest) {
    var f: any;
    var obj: any = {
      personHashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      id: jspb.Message.getFieldWithDefault(msg, 2, 0),
      type: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteRegistrationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteRegistrationRequest;
    return DeleteRegistrationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteRegistrationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteRegistrationRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteRegistrationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setType(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteRegistrationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteRegistrationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteRegistrationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string person_hash = 1;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


  /**
   * optional int32 id = 2;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string type = 3;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace DeleteRegistrationResponse {
  export interface AsObject {
  }
}
export class DeleteRegistrationResponse extends jspb.Message {
  static readonly displayName = "DeleteRegistrationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteRegistrationResponse.AsObject {
    return DeleteRegistrationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteRegistrationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteRegistrationResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteRegistrationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteRegistrationResponse;
    return DeleteRegistrationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteRegistrationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteRegistrationResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteRegistrationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteRegistrationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteRegistrationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteRegistrationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
