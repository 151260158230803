// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_cleanup_pb from '../content/cleanup_pb';
import * as content_common_pb from '../content/common_pb';

export class ContentCleanup {
  static readonly serviceName = "minga.content.ContentCleanup";

  static readonly Delete = {
    methodName: "Delete",
    service: ContentCleanup,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_cleanup_pb.UndoableAction,
  };

  static readonly UndoDelete = {
    methodName: "UndoDelete",
    service: ContentCleanup,
    requestStream: false,
    responseStream: false,
    requestType: content_cleanup_pb.UndoableAction,
    responseType: content_cleanup_pb.UndoableAction,
  };

  static readonly ResetMingaContent = {
    methodName: "ResetMingaContent",
    service: ContentCleanup,
    requestStream: false,
    responseStream: false,
    requestType: content_cleanup_pb.ResetMingaContentRequest,
    responseType: content_cleanup_pb.ResetMingaContentResponse,
  };

}

export class ContentCleanupClient {
  delete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  undoDelete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetMingaContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
